export default function getAutoTapBot(auto_tap_bot) {
  if (auto_tap_bot === 0) {
    return 0;
  } else if (auto_tap_bot === 1) {
    return 30;
  } else if (auto_tap_bot === 2) {
    return 60;
  } else if (auto_tap_bot === 3) {
    return 90;
  } else if (auto_tap_bot === 4) {
    return 120;
  } else if (auto_tap_bot === 5) {
    return 150;
  } else if (auto_tap_bot === 6) {
    return 180;
  } else if (auto_tap_bot === 7) {
    return 210;
  } else if (auto_tap_bot === 8) {
    return 240;
  } else if (auto_tap_bot === 9) {
    return 270;
  } else if (auto_tap_bot === 10) {
    return 300;
  } else if (auto_tap_bot === 11) {
    return 330;
  } else if (auto_tap_bot === 12) {
    return 360;
  } else if (auto_tap_bot === 13) {
    return 390;
  } else if (auto_tap_bot === 14) {
    return 420;
  } else if (auto_tap_bot === 15) {
    return 450;
  } else if (auto_tap_bot === 16) {
    return 480;
  } else if (auto_tap_bot === 17) {
    return 510;
  } else if (auto_tap_bot === 18) {
    return 540;
  } else if (auto_tap_bot === 19) {
    return 570;
  } else if (auto_tap_bot === 20) {
    return 600;
  } else {
    return 630;
  }
}
