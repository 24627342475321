import React, { useState, useEffect } from "react";
import axios from "axios"; // Для отправки запросов на сервер
import WebApp from "@twa-dev/sdk";
import getClick from "../../utils/getClick";
import changeClick from "../../utils/changeClick";
import getRocket from "../../utils/getRocket";
import changeClickSession from "../../utils/changeClickSession";

let constrain = 12;

const transforms = (x, y, el) => {
  let box = el.getBoundingClientRect();
  const h = box.height;
  const w = box.width;
  if (
    x - box.x > 135 &&
    x - box.x < 165 &&
    y - box.y > 135 &&
    y - box.y < 165
  ) {
    return "perspective(400px) scale(0.99)";
  } else {
    let calcX = -(y - box.y - h / 2) / constrain;
    let calcY = (x - box.x - w / 2) / constrain;
    return (
      "perspective(400px) " +
      "   rotateX(" +
      calcX +
      "deg) " +
      "   rotateY(" +
      calcY +
      "deg) "
    );
  }
};

const returnZero = (x, y) => {
  return "none";
};

const transformElement = (el, xyEl) => {
  el.style.transform = transforms.apply(null, xyEl);
};

const Clicker = ({
  score,
  updateScore,
  oldScore,
  updateOldScore,
  session,
  updateSession,
  squadScore,
  updateSquadScore,
  rocket,
  rocketNum,
  mainSkin,
  skins,
  multitap,
}) => {
  const [lastClickTime, setLastClickTime] = useState(null);
  let isClicked;

  // // Функция для обновления баланса пользователя
  // const updatedScore = async (newScore) => {
  //   try {
  //     const response = await axios.put(
  //       `https://pixelcoin.site:433/users/5668476910/score`,
  //       {
  //         score: newScore,
  //       }
  //     );
  //     //   updateScore(response.data.score);
  //   } catch (error) {
  //     console.error("Error updating score:", error);
  //   }
  // };

  // useEffect(() => {
  //   let timer;
  //   // Установка таймера после каждого клика
  //   if (lastClickTime) {
  //     timer = setTimeout(async () => {
  //       updatedScore(score);
  //       // console.log("delay > 5 sec");
  //     }, 5000);
  //   }

  //   // Очистка таймера при размонтировании компонента или изменении lastClickTime
  //   return () => clearTimeout(timer);
  // }, [lastClickTime, score, updateScore]);

  // const handleTouch = (e) => {
  //   if (isClicked) return;
  //   const target = e.target.closest(".js-big-coin");
  //   const rect = e.target.getBoundingClientRect();
  //   let xy = [e.clientX, e.clientY];
  //   const spanX = xy[0] - rect.left;
  //   const spanY = xy[1] - rect.top;
  //   const h = rect.height;
  //   const w = rect.width;
  //   const span = document.createElement("span");
  //   span.classList.add("clicker-amount");
  //   span.style.setProperty("--count-x", spanX + "px");
  //   span.style.setProperty("--count-y", spanY + "px");
  //   span.style.setProperty("pointer-events", "none");
  //   span.style.setProperty("user-select", "none");
  //   span.textContent = getClick(score);
  //   target.appendChild(span);
  //   span.addEventListener("animationend", () => {
  //     span.remove();
  //   });

  //   const currentTime = new Date().getTime();
  //   setLastClickTime(currentTime);
  //   updateScore(changeClick(score));
  //   WebApp.HapticFeedback.impactOccurred(`medium`);

  //   let position = xy.concat([target]);
  //   transformElement(target, position);
  //   setTimeout(() => {
  //     target.style.transform = returnZero();
  //     isClicked = false;
  //   }, 100);
  // };
  const handleTouch = (e) => {
    if (isClicked) return;

    const target = e.target.closest(".js-big-coin");
    const img = e.target.closest(".js-coin-img");
    const rect = target.getBoundingClientRect();
    const xy = [e.touches[0].clientX, e.touches[0].clientY];
    const spanX = xy[0] - rect.left;
    const spanY = xy[1] - rect.top;

    const span = document.createElement("span");
    span.classList.add("clicker-amount");
    span.style.setProperty("--count-x", spanX + "px");
    span.style.setProperty("--count-y", spanY + "px");
    span.style.setProperty("pointer-events", "none");
    span.style.setProperty("user-select", "none");
    span.textContent = getClick(score, rocket, rocketNum, skins, multitap);
    target.appendChild(span);
    span.addEventListener("animationend", () => {
      span.remove();
    });

    const currentTime = new Date().getTime();
    setLastClickTime(currentTime);
    WebApp.HapticFeedback.impactOccurred(`light`);
    updateOldScore(score)
    updateScore(changeClick(score, rocket, rocketNum, skins, multitap));
    updateSession(changeClickSession(score, session, rocket, rocketNum, skins, multitap));
    updateSquadScore(
      changeClickSession(score, squadScore, rocket, rocketNum, skins, multitap)
    );

    let position = xy.concat([target]);
    img && transformElement(img, position);
  };

  const endTouch = (e) => {
    const img = e.target.closest(".js-coin-img");
    if (img) {
      img.style.transform = returnZero();
    }
  };

  return (
    <div>
      <div className="root-container Oj3d scale-animation">
        <div
          onTouchStart={handleTouch}
          onTouchEnd={endTouch}
          className="clicker js-big-coin"
        >
          <img
            className="clicker-position js-coin-img"
            src={
              mainSkin === "basic"
                ? require(`../../assets/images/moneta-small.png`)
                : require(`../../assets/images/skin-${mainSkin}.png`)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Clicker;
