import React from "react";
import Lottie from "lottie-react";
import Disco from "../../assets/animation/Disco.json";
import Duck_think_out from "../../assets/animation/Duck_think_out.json";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import UsersSkeleton from "../../components/UsersSkeleton";
import { BackButton, MainButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import axios from "axios";
import JoinSquadPhoto from "../../components/JoinSquadPhoto";

function Search() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const asset = params.get("asset");
  return { asset };
}

export default function JoinSquad({ userId }) {
  const navigate = useNavigate();

  const result = Search();
  const [isVisible, setIsVisible] = useState(false);
  setTimeout(() => {
    setIsVisible(true);
  }, 1000);

  const [squad, setSquad] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://pixelcoin.site:433/squads/${result.asset}`
        );
        setSquad(response.data);
      } catch (error) {
        console.error("Error fetching squad:", error);
      }
    };

    fetchData();
  }, []);

  const [firstName, setFirstName] = useState("");

  // Функция для получения баланса пользователя
  const fetchFirstName = async () => {
    try {
      const response = await axios.get(
        `https://pixelcoin.site:433/users/${userId}/first_name`
      );
      setFirstName(response.data.first_name);
    } catch (error) {
      console.error("Error fetching first_name:", error);
    }
  };

  useEffect(() => {
    fetchFirstName();
  }, []);

  const [urlPhoto, setUrlPhoto] = useState("");

  // Функция для получения баланса пользователя
  const fetchUrlPhoto = async () => {
    try {
      const response = await axios.get(
        `https://pixelcoin.site:433/users/${userId}/url_photo`
      );
      setUrlPhoto(response.data.url_photo);
    } catch (error) {
      console.error("Error fetching url_photo:", error);
    }
  };

  useEffect(() => {
    fetchUrlPhoto();
  }, []);

  const [newSquad, setNewSquad] = useState("");

  // Функция для получения баланса пользователя
  const fetchNewSquad = async () => {
    try {
      axios.get(
        `https://pixelcoin.site:433/users/${userId}/squad`
      ).then((response) => {
        setNewSquad(response.data.squad);
      })
      
    } catch (error) {
      console.error("Error fetching new_squad:", error);
    }
  };

  // Функция для обновления баланса пользователя
  const updateNewSquad = async (newSquad) => {
    try {
      axios
        .put(`https://pixelcoin.site:433/users/${userId}/squad`, {
          squad: newSquad,
        })
        .then((response) => {
          setNewSquad(response.data.squad);
          navigate(`/squad?asset=${newSquad}`);
        });
    } catch (error) {
      console.error("Error updating squad:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchNewSquad();
  }, []);

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [loader, setLoader] = useState(false);

  const join_squad = () => {
    setLoader(true);
    if (newSquad !== "") {
      axios
        .get(`https://pixelcoin.site:433/squads/${newSquad}/players`)
        .then((response1) => {
          const players = response1.data.players;
          const index = players.indexOf(userId);
          if (index > -1) {
            players.splice(index, 1);
          }
          return players;
        })
        .then((players) => {
          axios.put(`https://pixelcoin.site:433/squads/${newSquad}/players`, {
            players: players,
          });
        })
        .then(() => {
          axios.put(`https://pixelcoin.site:433/users/${userId}/squad_score`, {
            squad_score: 0,
          });
        })
        .then(() => {
          axios
            .get(`https://pixelcoin.site:433/squads/${result.asset}/players`)
            .then((response1) => {
              const players = response1.data.players;
              axios
                .put(`https://pixelcoin.site:433/squads/${result.asset}/players`, {
                  players: [...players, userId],
                })
                .then(() => {
                  updateNewSquad(`${result.asset}`);
                });
            })
            .catch((error) => {
              console.error("Ошибка при обновлении баланса:", error);
            });
        });
    } else {
      axios
        .get(`https://pixelcoin.site:433/squads/${result.asset}/players`)
        .then((response1) => {
          const players = response1.data.players;
          axios
            .put(`https://pixelcoin.site:433/squads/${result.asset}/players`, {
              players: [...players, userId],
            })
            .then(() => {
              updateNewSquad(`${result.asset}`);
            });
        })
        .catch((error) => {
          console.error("Ошибка при обновлении баланса:", error);
        });
    }
  };
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const documentHeight = 600;
      if (documentHeight > windowHeight) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <BackButton onClick={() => navigate(window.history.back())} />
      <MainButton
        text={parsedData.join_squad.button}
        progress={loader ? true : false}
        color="rgba(0, 122, 255, 1)"
        textColor="#fff"
        onClick={join_squad}
      />

{/* <div
              onClick={!loader ? join_squad : <></>}
              className="join-squad-btn"
              style={{
                zIndex: "7",
                pointerEvents: loader ? "none" : "",
              }}
            >
              <div className="Jh2w">
                <div className="Li2p">
                  <div className="Iy4o">{parsedData.join_squad.button}</div>
                </div>
              </div>
              {loader && (
                <div
                  className="join-squad-loader"
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    marginRight: "16px",
                    marginTop: "16px",
                  }}
                ></div>
              )}
            </div> */}

      <div
        className="page"
        style={{
          overflow: "hidden",
        }}
      >
        <div className="background-yellow theme-yellow"></div>
        <div
          className={`container position-relative ${
            shouldScroll ? `scrolling` : ``
          }`}
        >
          <div className="page__content">
            <div
              className="Jh2w join-squad-slide-left"
              style={{
                display: "flex",
                marginTop: "176px",
                marginRight: "90px",
              }}
            >
              <img
                src={`${squad.url_photo}`}
                style={{
                  width: "112px",
                  height: "112px",
                  borderRadius: "32px",
                }}
              />
            </div>
            <div
              className="Jh2w join-squad-slide-right"
              style={{
                display: "flex",
                marginTop: "-112px",
                marginRight: "-90px",
              }}
            >
              <JoinSquadPhoto urlPhoto={urlPhoto} firstName={firstName} />
              {/* {urlPhoto !== "" ? (
                <img
                  src={`${urlPhoto}`}
                  style={{
                    width: "112px",
                    height: "112px",
                    borderRadius: "50%",
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "112px",
                    height: "112px",
                    borderRadius: "50%",
                    background: "#000",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "28px",
                      fontWeight: "600",
                      color: "#fff",
                      marginTop: "40px",
                    }}
                  >
                    {firstName.substring(0, 2)}
                  </div>
                </div>
              )} */}
            </div>
            <CSSTransition
              in={isVisible}
              timeout={100}
              className={`join-squad-down-enter`}
              unmountOnExit
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    {parsedData.join_squad.join} {squad.name}{" "}
                    {parsedData.join_squad.squad}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "4px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#d4d4d4",
                      textAlign: "center",
                    }}
                  >
                    {parsedData.join_squad.header_text}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "400",
                      color: "#d4d4d4",
                      textAlign: "center",
                    }}
                  >
                    {parsedData.join_squad.footer_text}
                  </div>
                </div>
              </div>
            </CSSTransition>
            
          </div>
        </div>
      </div>
    </>
  );
}
