import React, { useState, useEffect } from "react";
import axios from "axios"; // Для отправки запросов на сервер
import WebApp from "@twa-dev/sdk";
// import getClick from "../../utils/getClick";
// import changeClick from "../../utils/changeClick";
import { getRandomKey } from "../../utils/getRandomKey";
import { shuffle } from "../../utils/shuffle";
import changeScore from "../../utils/changeScore";
import FieldItem from "../../components/FieldItem";
import getLeague from "../../utils/getLeague";
import changeScoreSession from "../../utils/changeScoreSession";
import getRechargingSpeed from "../../utils/getRechargingSpeed";

const defaultItems = [
  {
    id: getRandomKey(),
    type: 1,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 1,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 1,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 2,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 2,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 2,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 3,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 3,
    isOpened: false,
  },
  {
    id: getRandomKey(),
    type: 3,
    isOpened: false,
  },
];

let shuffledItems = shuffle([...defaultItems]);

const Field = ({
  score,
  updateScore,
  oldScore,
  updateOldScore,
  session,
  updateSession,
  squadScore,
  updateSquadScore,
  clickStart,
  rocket,
  rocketNum,
  touch,
  updateTouch,
  mainSkin,
  skins,
  multitap,
  recharging_speed,
  ghost_bounty,
}) => {
  const [lastClickTime, setLastClickTime] = useState(null);

  // Функция для обновления баланса пользователя

  // useEffect(() => {
  //   let timer;
  //   // Установка таймера после каждого клика
  //   if (lastClickTime) {
  //     timer = setTimeout(async () => {
  //       updatedScore(score);
  //       // console.log("delay > 5 sec");
  //     }, 5000);
  //   }

  //   // Очистка таймера при размонтировании компонента или изменении lastClickTime
  //   return () => clearTimeout(timer);
  // }, [lastClickTime, score, updateScore]);

  // const [touch, setTouch] = useState(0);

  // // Функция для получения баланса пользователя
  // const fetchTouch = async () => {
  //   try {
  //     const response = await axios.get(
  //       `https://pixelcoin.site:433/users/5668476910/touch`
  //     );
  //     setTouch(response.data.touch);
  //   } catch (error) {
  //     console.error("Error fetching touch:", error);
  //   }
  // };

  // // Функция для обновления баланса пользователя
  // const updateTouch = async (newTouch) => {
  //   try {
  //     const response = await axios.put(
  //       `https://pixelcoin.site:433/users/5668476910/touch`,
  //       {
  //         touch: newTouch,
  //       }
  //     );
  //     setTouch(response.data.touch);
  //   } catch (error) {
  //     console.error("Error updating touch:", error);
  //   }
  // };

  // // Вызываем функцию получения баланса при монтировании компонента
  // useEffect(() => {
  //   fetchTouch();
  // }, []);

  const [items, setItems] = useState(shuffledItems);

  const [isClicked, setIsClicked] = useState(false);
  let timeId;
  const open = (item) => {
    if (isClicked) return;
    item.isOpened = true;
    if (!rocket) {
      updateTouch(touch + 1);
    }

    const ghostLenght = items.filter(
      (item) => (item.type === 3) & item.isOpened
    ).length;
    const currentTime = new Date().getTime();
    setLastClickTime(currentTime);
    updateOldScore(score);
    updateScore(changeScore(item, score, rocket, rocketNum, skins, multitap, ghost_bounty));
    if (item.type === 2) {
      updateSession(
        changeScoreSession(score, session, rocket, rocketNum, skins, multitap)
      );
      updateSquadScore(
        changeScoreSession(score, squadScore, rocket, rocketNum, skins, multitap)
      );
    }
    setItems([...items]);
    if (
      item.type === 1 ||
      item.type === 2 ||
      (item.type === 3) & (ghostLenght === 3)
    ) {
      if (ghostLenght === 3) {
        setTimeout(() => {
          clickStart();
        }, 250);
      }
      setIsClicked(true);
      timeId = setTimeout(() => {
        items.forEach((item) => (item.isOpened = true));
        setItems([...items]);
      }, 500);
      const timeoutDuration = getRechargingSpeed(recharging_speed) * 1000;
      setTimeout(() => {
        restart();
      }, timeoutDuration);
    }
  };

  const [secondsClick, setSecondsClick] = useState(
    skins.includes("dragon") ? 60 : 30
  );
  const [isActiveClick, setIsActiveClick] = useState(false);

  useEffect(() => {
    let intervalId;

    if (isActiveClick) {
      intervalId = setInterval(() => {
        setSecondsClick((prevSecondsClick) => prevSecondsClick - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActiveClick]);

  useEffect(() => {
    if (secondsClick === 0) {
      setIsActiveClick(false);
      setSecondsClick(skins.includes("dragon") ? 60 : 30);
    }
  }, [secondsClick]);

  // const [click, setClick] = useState(false);

  // const clickStart = () => {
  //   setRocket(false);
  //   // setClick(true);
  //   updateClick(true);
  //   setIsActiveClick(true);
  //   setTimeout(() => {
  //     // setClick(false);
  //     updateClick(false);
  //     setIsActiveClick(false);
  //   }, 30000);
  // };

  const restart = () => {
    clearTimeout(timeId);

    shuffledItems = shuffle([...defaultItems]);

    items.forEach((item) => (item.isOpened = false));
    setItems([...items]);

    setTimeout(() => {
      setItems(shuffledItems);
      setIsClicked(false);
    }, 400);
  };

  return (
    <div>
      <div className="root-container Oj3d">
        <div className="field-container">
          <div>
            <ul className="field grid-gap scale-animation">
              {items.map((item) => {
                return (
                  <FieldItem
                    key={item.id}
                    items={items}
                    item={item}
                    score={score}
                    rocket={rocket}
                    rocketNum={rocketNum}
                    mainSkin={mainSkin}
                    skins={skins}
                    multitap={multitap}
                    onClick={() => {
                      open(item);
                      {
                        WebApp.platform === "ios"
                          ? WebApp.HapticFeedback.impactOccurred(`medium`)
                          : WebApp.HapticFeedback.notificationOccurred(
                              "success"
                            );
                      }
                    }}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Field;
