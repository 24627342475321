import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";
import Lottie from "lottie-react";
import WebApp from "@twa-dev/sdk";
import Crystal from "../../assets/animation/Crystal.json";
// import Fire from "../../assets/animation/Fire.json";
import Collision from "../../assets/animation/Collision.json";
import Ghost from "../../assets/animation/Ghost.json";
import getScore from "../../utils/getScore";

let constrain = 10;

const transforms = (x, y, el) => {
  let box = el.getBoundingClientRect();
  const h = box.height;
  const w = box.width;
  if (x - box.x > 35 && x - box.x < 65 && y - box.y > 35 && y - box.y < 65) {
    return "perspective(100px) scale(0.95)";
  } else {
    let calcX = -(y - box.y - h / 2) / constrain;
    let calcY = (x - box.x - w / 2) / constrain;
    return (
      "perspective(100px) " +
      "   rotateX(" +
      calcX +
      "deg) " +
      "   rotateY(" +
      calcY +
      "deg) "
    );
  }
};

const returnZero = (x, y) => {
  return "none";
};

const transformElement = (el, xyEl) => {
  el.style.transform = transforms.apply(null, xyEl);
};

const FieldItem = ({
  items,
  item,
  onClick,
  score,
  rocket,
  rocketNum,
  mainSkin,
  skins,
  multitap,
}) => {
  let isClicked;
  const [animationCrystal] = useState(Crystal);
  const [animationGhost] = useState(Ghost);

  // const [score, setScore] = useState(0);

  // // Функция для получения баланса пользователя
  // const fetchScore = async () => {
  //   try {
  //     const response = await axios.get(`https://pixelcoin.site:433/users/5668476910/score`);
  //     setScore(response.data.score);
  //   } catch (error) {
  //     console.error("Error fetching score:", error);
  //   }
  // };

  // // Функция для обновления баланса пользователя
  // const updateScore = async (newScore) => {
  //   try {
  //     const response = await axios.put(`https://pixelcoin.site:433/users/5668476910/score`, {
  //       score: newScore,
  //     });
  //     setScore(response.data.score);
  //   } catch (error) {
  //     console.error("Error updating score:", error);
  //   }
  // };

  // // Вызываем функцию получения баланса при монтировании компонента
  // useEffect(() => {
  //   fetchScore();
  // }, []);

  const className = `field__item js-field-item ${
    item.isOpened ? "active" : ""
  }`;

  const animatedOptions = useMemo(
    () => ({
      className: "item-size-ios",
      loop: true,
      autoPlay: true,
      animationData: item.type === 2 ? animationCrystal : animationGhost,
      // rendererSettings: {
      //   preserveAspectRatio: "xMidYMid slice",
      //   // progressiveLoad: true,
      //   // cacheImages: true,
      //   // clearCanvas: false,
      //   // initialFrame: 30,
      //   hardwareAcceleration: true,
      // },
    }),
    []
  );

  const castomOnClick = (e) => {
    onClick(e);
    if (isClicked) return;
    const target = e.target.closest(".js-field-item");

    const rect = target.getBoundingClientRect();
    const xy = [e.touches[0].clientX, e.touches[0].clientY];
    const spanX = xy[0] - rect.left;
    const spanY = xy[1] - rect.top;

    target.style.setProperty("--count-x", spanX + "px");
    target.style.setProperty("--count-y", spanY + "px");

    let position = xy.concat([target]);
    transformElement(target, position);
  };

  const endTouch = (e) => {
    const target = e.target.closest(".js-field-item");
    target.style.transform = returnZero();
  };

  return (
    <div>
      <li
        className={className}
        data-opened={item.isOpened}
        onTouchStart={castomOnClick}
        onTouchEnd={endTouch}
      >
        <div className={`card-front card-${mainSkin}`}></div>
        <div className={`card-back back-${mainSkin}`}>
          {item.isOpened &&
            (WebApp.platform === "ios" && !rocket ? (
              item.type === 1 ? (
                <img
                  src={require("../../assets/images/fire.png")}
                  className="item-size-material"
                />
              ) : (
                <Lottie
                  className={animatedOptions.className}
                  loop={animatedOptions.loop}
                  autoPlay={animatedOptions.autoPlay}
                  rendererSettings={animatedOptions.rendererSettings}
                  animationData={animatedOptions.animationData}
                />
              )
            ) : item.type === 1 ? (
              <img
                src={require("../../assets/images/fire.png")}
                className="item-size-material"
              />
            ) : item.type === 2 ? (
              <img
                src={require("../../assets/images/gem.png")}
                className="item-size-material"
              />
            ) : (
              <img
                src={require("../../assets/images/ghost.png")}
                className="item-size-material"
              />
            ))}
        </div>
        {item.type === 2 &&
          item.isOpened &&
          items.filter((item) => (item.type === 2) & item.isOpened).length ===
            1 && (
            <span className="click-amount">
              {getScore(score, rocket, rocketNum, skins, multitap)}
            </span>
          )}
      </li>
    </div>
  );
};

export default FieldItem;
