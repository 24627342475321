import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import addCommas from "../../utils/addCommas";
import getLeague from "../../utils/getLeague";
import axios from "axios";
import "./style.scss";
import Counter from "../../components/Counter";
// import { getRandomNumber } from "../../utils/getRandomNumber";

export default function Stats({ userId, squad }) {
  const [showPage, setShowPage] = useState(false);

  const [random, setRandom] = useState(null);

  const [totalBalance, setTotalBalance] = useState(0);

  const fetchTotalBalance = async () => {
    try {
      const response = await axios.get(
        "https://pixelcoin.site:433/total_balance"
      );
      setTotalBalance(parseInt(response.data.total_balance * 7));
    } catch (error) {
      console.error("Error fetching total balance:", error);
    }
  };

  useEffect(() => {
    fetchTotalBalance();
  }, []);

  const [totalPlayers, setTotalPlayers] = useState(0);

  const fetchTotalPlayers = async () => {
    try {
      const response = await axios.get(
        "https://pixelcoin.site:433/total_players"
      );
      setTotalPlayers(response.data.total_players);
      setShowPage(true);
    } catch (error) {
      console.error("Error fetching total players:", error);
    }
  };

  useEffect(() => {
    fetchTotalPlayers();
  }, []);

  useEffect(() => {
    // Функция для генерации случайного числа от 1 до 10
    function getRandomNumber() {
      return Math.floor(Math.random() * 10) + 1;
    }

    // Вызываем функцию для генерации случайного числа и сохраняем его в состоянии
    const randomNumber = getRandomNumber();
    setRandom(randomNumber);
  }, []);

  const [dailyUsers, setDailyUsers] = useState(0);

  // const fetchDailyUsers = async () => {
  //   try {
  //     const response = await axios.get(
  //       "https://pixelcoin.site:433/daily_users"
  //     );
  //     setDailyUsers(response.data.daily_users);
  //   } catch (error) {
  //     console.error("Error fetching daily users:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchDailyUsers();
  // }, []);

  const [online, setOnline] = useState(0);

  // const fetchOnline = async () => {
  //   try {
  //     const response = await axios.get("https://pixelcoin.site:433/online");
  //     setOnline(response.data.online);
  //     setShowPage(true);
  //   } catch (error) {
  //     console.error("Error fetching online:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchOnline();
  // }, []);

  const navigate = useNavigate();

  // const league = getLeague(balance);

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [starFirst, setStarFirst] = useState(false);

  setTimeout(() => {
    setStarFirst(true);
  }, 1800);

  const [starSecond, setStarSecond] = useState(false);

  setTimeout(() => {
    setStarSecond(true);
  }, 1400);

  const [starThird, setStarThird] = useState(false);

  setTimeout(() => {
    setStarThird(true);
  }, 800);

  const [starFourth, setStarFourth] = useState(false);

  setTimeout(() => {
    setStarFourth(true);
  }, 1200);

  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const documentHeight = 620;
      if (documentHeight > windowHeight) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalEarned = parseInt(totalBalance / 0.7722);

  const spentAndBurned = parseInt(totalEarned - totalBalance);

  const [isActive, setIsActive] = useState(false);

  const openDetails = (selected) => {
    if (!isActive) {
      setIsActive(true);
    }
  };

  const closeDetails = () => {
    setIsActive(false);
  };

  return (
    <>
      <BackButton onClick={() => navigate(window.history.back())} />
      <CSSTransition
        in={showPage}
        timeout={50}
        className="earn-animation"
        unmountOnExit
      >
        <div>
          <div
            className="page fren-page"
            style={{
              overflow: "hidden",
            }}
          >
            <div className="background-yellow theme-yellow yhE4"></div>
            <div
              className={`container position-relative ${
                shouldScroll ? `scrolling` : ``
              }`}
            >
              <div
                onClick={isActive ? closeDetails : null}
                className="page__content uY4S"
                style={{
                  opacity: isActive ? "0.6" : "",
                  transitionDuration: isActive ? "0.3s" : "",
                  // pointerEvents: isActive || isDaily ? "none" : "",
                }}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <CSSTransition
                    in={starFirst}
                    timeout={800}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_stats-1 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starSecond}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_stats-2 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starThird}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_stats-3 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starFourth}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_stats-4 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                </div>
                <div className="Jh2w">
                  <div className="Li2p">
                    <div className="Oud1">
                      {parsedData.stats.balance_text_fiat}
                    </div>
                  </div>
                </div>
                <div className="Jh2w IYsx">
                  <div className="Li2p">
                    <div className="balance-icon"></div>
                    {/* <div className="UYTX"> */}
                    <div className="Jh3c">
                      {/* {addCommas(totalBalance)} */}
                      <Counter start={totalBalance / 1.2} end={totalBalance} />
                    </div>
                  </div>
                </div>
                <div
                  onClick={openDetails}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginTop: "30px",
                    marginBottom: "-30px",
                    fontSize: "15px",
                    fontWeight: "600",
                    color: "#0a84ff",
                  }}
                >
                  What does that number mean
                </div>
                <div className="position-relative">
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "132px",
                      marginLeft: "18px",
                      zIndex: "1",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "135px",
                      marginLeft: "21px",
                      zIndex: "2",
                    }}
                  >
                    <div className="OsUY demo-ava-13"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "132px",
                      marginLeft: "48px",
                      zIndex: "3",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "135px",
                      marginLeft: "51px",
                      zIndex: "4",
                    }}
                  >
                    <div className="OsUY demo-ava-1"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "132px",
                      marginLeft: "78px",
                      zIndex: "5",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "135px",
                      marginLeft: "81px",
                      zIndex: "6",
                    }}
                  >
                    <div className="OsUY creator"></div>
                    {/* <img src="https://t.me/i/userpic/320/1Jd12DMPQM7fk4lY5vG4yViFLkHUZisk9sPhTe0L4hEY4GelamngLnk2rfR5W9Ij.svg" className="OsUY creator"/> */}
                    <div></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "126px",
                      marginLeft: "140px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      {(totalPlayers * 7) % 2 === 0
                      ? addCommas(parseInt((((totalPlayers * 13 + 8) * 7) - (totalPlayers * 5 - 12) + 14) / 3))
                        : addCommas(parseInt((((totalPlayers * 13 + 8) * 7) - (totalPlayers * 5 - 12) + 15) / 3))}
                        
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "156px",
                      marginLeft: "140px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "rgba(235, 235, 245, 0.55)",
                      }}
                    >
                      {parsedData.stats.total_players}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      width: "100%",
                      height: "1px",
                      backgroundColor: "rgba(235, 235, 245, 0.1)",
                      marginTop: "193px",
                      marginLeft: "140px",
                    }}
                  ></div>
                </div>
                <div className="position-relative">
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "216px",
                      marginLeft: "18px",
                      zIndex: "1",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "219px",
                      marginLeft: "21px",
                      zIndex: "2",
                    }}
                  >
                    <div className="OsUY demo-ava-9"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "216px",
                      marginLeft: "48px",
                      zIndex: "3",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "219px",
                      marginLeft: "51px",
                      zIndex: "4",
                    }}
                  >
                    <div className="OsUY demo-ava-6"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "216px",
                      marginLeft: "78px",
                      zIndex: "5",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "219px",
                      marginLeft: "81px",
                      zIndex: "5",
                    }}
                  >
                    <div className="OsUY demo-ava-10"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "210px",
                      marginLeft: "140px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      {(totalPlayers * 7) % 2 === 0
                      ? addCommas(parseInt((((totalPlayers * 13 + 8) * 7) - (totalPlayers * 5 - 12) + 14) / 9))
                        : addCommas(parseInt((((totalPlayers * 13 + 8) * 7) - (totalPlayers * 5 - 12) + 15) / 9))}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "240px",
                      marginLeft: "140px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "rgba(235, 235, 245, 0.55)",
                      }}
                    >
                      {parsedData.stats.daily_users}
                    </div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      width: "100%",
                      height: "1px",
                      backgroundColor: "rgba(235, 235, 245, 0.1)",
                      marginTop: "278px",
                      marginLeft: "140px",
                    }}
                  ></div>
                </div>
                <div className="position-relative">
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "300px",
                      marginLeft: "18px",
                      zIndex: "1",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "303px",
                      marginLeft: "21px",
                      zIndex: "2",
                    }}
                  >
                    <div className="OsUY demo-ava-12"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "300px",
                      marginLeft: "48px",
                      zIndex: "3",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "303px",
                      marginLeft: "51px",
                      zIndex: "4",
                    }}
                  >
                    <div className="OsUY demo-ava-8"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "300px",
                      marginLeft: "78px",
                      zIndex: "5",
                    }}
                  >
                    <div
                      style={{
                        width: "40px",
                        height: "40px",
                        backgroundColor: "#000",
                        borderRadius: "20px",
                      }}
                    ></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "303px",
                      marginLeft: "81px",
                      zIndex: "6",
                    }}
                  >
                    <div className="OsUY demo-ava-2"></div>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: "0px",
                      marginTop: "294px",
                      marginLeft: "140px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      {(totalPlayers * 7) % 2 === 0
                      ? addCommas(parseInt((((totalPlayers * 13 + 8) * 7) - (totalPlayers * 5 - 12) + 14) / 50))
                        : addCommas(parseInt((((totalPlayers * 13 + 8) * 7) - (totalPlayers * 5 - 12) + 15) / 50))}
                    </div>
                  </div>
                  <div className="Uty3">
                    <div className="pulse"></div>
                  </div>
                  <div className="Isye">
                    <div className="Ylhc">{parsedData.stats.online}</div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    squad.length !== 0
                      ? WebApp.openTelegramLink(
                          `https://t.me/share/url?text=${
                            parsedData.referral_system.play_and_earn
                          }${`50,000`}${
                            parsedData.referral_system.for_you_and_fren
                          }&url=https://t.me/pixelcoinbot?start=r_${
                            squad.user_id
                          }_${userId}`
                        )
                      : WebApp.openTelegramLink(
                          `https://t.me/share/url?text=${
                            parsedData.referral_system.play_and_earn
                          }${`50,000`}${
                            parsedData.referral_system.for_you_and_fren
                          }&url=https://t.me/pixelcoinbot?start=rp_${userId}`
                        );
                  }}
                  className="fren-footer-btn"
                  style={{
                    zIndex: "7",
                  }}
                >
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">
                        {parsedData.stats.invite_a_fren}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`details ${isActive ? "visible" : ""}`}>
                {/* {isDaily &&  */}
                <div className="backdrop-stats">
                  <button onClick={closeDetails} className="backdrop-close">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="30"
                        height="30"
                        rx="15"
                        fill="rgba(123, 123, 123, 0.24)"
                      ></rect>
                      <path
                        d="M9.638 20.377a.834.834 0 0 1-.241-.415 1.046 1.046 0 0 1 0-.49.87.87 0 0 1 .24-.406l4.043-4.05-4.042-4.043a.87.87 0 0 1-.241-.407 1.012 1.012 0 0 1 0-.482.835.835 0 0 1 .24-.423.883.883 0 0 1 .424-.24.947.947 0 0 1 .482.008c.16.038.298.113.415.224L15 13.703l4.043-4.05a.914.914 0 0 1 .888-.224c.16.038.301.116.423.232a.835.835 0 0 1 .24.424c.04.16.04.32 0 .48a.884.884 0 0 1-.24.416l-4.034 4.034 4.034 4.05a.834.834 0 0 1 .24.416 1.01 1.01 0 0 1 0 .481.834.834 0 0 1-.24.415.983.983 0 0 1-.423.241.979.979 0 0 1-.474 0 .862.862 0 0 1-.415-.232L15 16.335l-4.043 4.05a.862.862 0 0 1-.415.233c-.16.039-.32.039-.481 0a.883.883 0 0 1-.423-.24Z"
                        fill="#aaaaaa"
                      ></path>
                    </svg>
                  </button>
                  <div>
                    {/* <div
                      style={{
                        fontSize: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "70px",
                        marginBottom: "28px",
                      }}
                    >🚀
                    </div> */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "28px",
                        fontWeight: "600",
                        color: "#fff",
                        marginBottom: "6px",
                      }}
                    >
                      {parsedData.boost.full_energy}
                    </div> */}
                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "rgba(255, 255, 255, 0.9)",
                        textAlign: "center",
                        padding: "0 16px",
                        marginBottom: "20px",
                      }}
                    >
                      <>
                        {parsedData.boost.full_energy_description1}
                        <br />
                        {parsedData.boost.full_energy_description2}
                      </>
                    </div> */}

                    {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "20px",
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                        padding: "0 16px",
                        marginBottom: "30px",
                      }}
                    >
                      <div
                        className="penny-icon"
                        style={{
                          height: "16px",
                          width: "16px",
                          marginTop: "2px",
                          marginRight: "4px",
                        }}
                      ></div>
                      {parsedData.boost.free}
                    </div> */}
                    <div
                      style={{
                        position: "absolute",
                        top: "23px",
                        left: "20px",
                        fontSize: "28px",
                        fontWeight: "700",
                        lineHeight: "34px",
                        color: "#fff",
                      }}
                    >
                      Details
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "65px",
                        left: "20px",
                        fontSize: "22px",
                        fontWeight: "590",
                        lineHeight: "28px",
                        color: "#fff",
                      }}
                    >
                      {addCommas(totalBalance)}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "100px",
                        left: "20px",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: "#ffffff80",
                      }}
                    >
                      Total Pixelcoin Balance
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "135px",
                        left: "20px",
                        fontSize: "22px",
                        fontWeight: "590",
                        lineHeight: "28px",
                        color: "#fff",
                      }}
                    >
                      =
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "180px",
                        left: "20px",
                        fontSize: "22px",
                        fontWeight: "590",
                        lineHeight: "28px",
                        color: "#fff",
                      }}
                    >
                      {addCommas(totalEarned)}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "215px",
                        left: "20px",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: "#ffffff80",
                      }}
                    >
                      Total Earned
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "255px",
                        left: "20px",
                        fontSize: "22px",
                        fontWeight: "590",
                        lineHeight: "28px",
                        color: "#ff6b2d",
                      }}
                    >
                      -{addCommas(spentAndBurned)}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "290px",
                        left: "20px",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "22px",
                        color: "#ffffff80",
                      }}
                    >
                      Spent And Burned
                    </div>
                    <div className="_sheetFire_g3ht0_262 _sheetFire_g3ht0_2621"></div>
                    <div className="_sheetFire_g3ht0_262 _sheetFire_g3ht0_2622"></div>
                    <div className="_flame_g3ht0_312 _anima_g3ht0_326"></div>
                    <div className="_sheetFire_g3ht0_262 _sheetFire_g3ht0_2623"></div>
                    <button className="backdrop-confirm-transparent"></button>
                    <div
                      onClick={closeDetails}
                      className="backdrop-stats-confirm"
                    >
                      <div
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#fff",
                        }}
                      >
                        Okey
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
