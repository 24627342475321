import "./style.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import BlackTransparent from "../../components/BlackTransparent";
import { CSSTransition } from "react-transition-group";
import addCommas from "../../utils/addCommas";
import { BackButton, MainButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import coinImage from "../../assets/images/coin-min.svg";
import getLeague from "../../utils/getLeague";
import abbreNumbers from "../../utils/abbreNumbers";
import getColor from "../../utils/getColor";
import Lottie from "lottie-react";
import Firework from "../../assets/animation/Firework.json";
import FireworkEffect from "../../assets/animation/FireworkEffect.json";

function Search() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const asset = params.get("asset");
  return asset;
}

export default function Complete({ userId }) {
  const navigate = useNavigate();

  const result = Search();

  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  const handleButtonClick = () => {
    setPlayAnimation(true);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [showPage, setShowPage] = useState(true);

  const [hasError, setHasError] = useState(false);

  const [receiver, setReceiver] = useState(0);

  const [shouldScroll, setShouldScroll] = useState(true);

  // useEffect(() => {
  //   function handleResize() {
  //     const windowHeight = window.innerHeight;
  //     const documentHeight = 640;
  //     if (documentHeight > windowHeight) {
  //       setShouldScroll(true);
  //     } else {
  //       setShouldScroll(false);
  //     }
  //   }

  //   handleResize();

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <>
      <BackButton onClick={() => navigate("/trade")} />
      <MainButton
        text="Done"
        color="rgba(0, 122, 255, 1)"
        textColor="#fff"
        onClick={() => navigate("/")}
      />
      <div
        className="page"
        style={{
          overflow: "hidden",
        }}
      >
        <div className={`container`}>
          <div className="page__content">
            <CSSTransition
              in={showPage}
              timeout={50}
              className="trade-animation"
              unmountOnExit
            >
              <div>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "32vh",
                  }}
                >
                  <Lottie
                    onClick={handleButtonClick}
                    style={{
                      height: "100px",
                      width: "100px",
                    }}
                    animationData={Firework}
                    key={animationKey}
                    loop={false}
                    autoPlay={playAnimation}
                  ></Lottie>
                  <div
                    style={{
                      position: "absolute",
                      top: "0",
                      transform: "scaleX(-1)",
                      marginLeft: "90px"
                    }}
                  >
                    <Lottie
                      onClick={handleButtonClick}
                      style={{
                        height: "100px",
                        width: "100px",
                      }}
                      animationData={FireworkEffect}
                      key={animationKey}
                      loop={false}
                      autoPlay={playAnimation}
                    />
                  </div>
                  {/*  */}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "#fff",
                    marginTop: "18px",
                  }}
                >
                  Coins Sent
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "400",
                    color: "#fff",
                    marginTop: "10px",
                  }}
                >
                  Your transaction has been sent to the
                  <br />
                  network and will be processed in a few
                  <br />
                  seconds.
                </div>

                {/* <div
                  style={{
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "68px",
                      width: "68px",
                      borderRadius: "50%",
                      background: "green",
                      marginBottom: "68px",
                    }}
                  ></div>
                </div>
                <div
                  style={{
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    right: "0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#fff",
                      marginBottom: "40px",
                    }}
                  >
                    Mask
                  </div>
                </div> */}
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </>
  );
}
