import getRocket from "./getRocket";
import getMultitap from "./getMultitap";

export default function changeClick(score, rocket, rocketNum, skins, multitap) {
  if (rocket) {
    score =  parseInt(score + 2 * getMultitap(multitap) * (getRocket(rocketNum) - 1));
  } else {
    score =  parseInt(score + getMultitap(multitap));
  }
  return score;
}
