export default function getGhostBounty(ghost_bounty) {
  if (ghost_bounty === 0) {
    return -25;
  } else if (ghost_bounty === 1) {
    return -20;
  } else if (ghost_bounty === 2) {
    return -15;
  } else if (ghost_bounty === 3) {
    return -10;
  } else if (ghost_bounty === 4) {
    return -5;
  } else if (ghost_bounty === 5) {
    return 0;
  } else if (ghost_bounty === 6) {
    return 5;
  } else if (ghost_bounty === 7) {
    return 10;
  } else if (ghost_bounty === 8) {
    return 15;
  } else if (ghost_bounty === 9) {
    return 20;
  } else if (ghost_bounty === 10) {
    return 25;
  } else if (ghost_bounty === 11) {
    return 30;
  } else if (ghost_bounty === 12) {
    return 35;
  } else if (ghost_bounty === 13) {
    return 40;
  } else if (ghost_bounty === 14) {
    return 45;
  } else if (ghost_bounty === 15) {
    return 50;
  } else if (ghost_bounty === 16) {
    return 55;
  } else if (ghost_bounty === 17) {
    return 60;
  } else if (ghost_bounty === 18) {
    return 65;
  } else if (ghost_bounty === 19) {
    return 70;
  } else if (ghost_bounty === 20) {
    return 75;
  } else {
    return 80;
  }
}
