import getRocket from "./getRocket";
import getMultitap from "./getMultitap";
import getGhostBounty from "./getGhostBounty";

export default function changeScore(
  item,
  balance,
  rocket,
  rocketNum,
  skins,
  multitap,
  ghost_bounty
) {
  let score;
  if (item.type === 1) {
    if (balance >= 100) {
      score = balance - 100;
    } else {
      score = 0;
    }
  } else if (item.type === 2) {
    if (rocket) {
      score = parseInt(
        balance + 100 * getRocket(rocketNum) * getMultitap(multitap)
      );
    } else {
      score = parseInt(balance + 100 * getMultitap(multitap));
    }
  } else {
    if (balance >= 25) {
      score = balance + getGhostBounty(ghost_bounty);
    } else {
      score = 0;
    }
  }
  return score;
}
