const light_pink_list = ["a", "q", "u", ]
const light_burgundy_list = [ "b", "r", "б", ]
const light_violet = ["c", "s", "в", "п", ]
const pink_list = ["d", "t", "г", "у", "р", ]
const grey_list = ["e", "д", "л", "ч", ]
const vanilla_list = ["~", "2", "е", "т", ]
const light_green_list = ["f", "#", "ь", "а"]
const green_list = ["g", "v", "щ", "ц", "ъ", ]
const light_blue_list = ["h", "w", "ю", "."]
const purple_list = ["i", "x", "х", "о", "ы", ]
const red_list = ["k", "y", "9", "7", "с", ]
const blue_list = ["@", "z", "&", "8", "й", ]
const orange_list = ["l", "3", "ж", "6", ]
const dark_blue_list = ["m", "%", "!", ")"]
const dark_red_list = ["n", "4", "ш", "ф", ]
const dark_green_list = ["o", "я", "5", "з", "н", ]
const dark_purple_list = ["p", "э", "ё", "и", ]
const dark_pink_list = ["1", "_", "м", ".", "(", ]
const dark_orange_list = ["-", "?", "к", "*", ]


export default function getColor(word) {
  word = word.toLowerCase();
  if (light_pink_list.some((letter) => word.includes(letter))) {
    // return "#e090d0";
    return "#FF0000";
  }
  else if (light_burgundy_list.some((letter) => word.includes(letter))) {
    // return "#663300";
    return "#00FF00";
  }
  else if (light_violet.some((letter) => word.includes(letter))) {
    // return "#bf5bfc";
    return "#0000FF";
  }
  else if (pink_list.some((letter) => word.includes(letter))) {
    // return "#fa37e6";
    return "#BFBF00";
  }
  else if (grey_list.some((letter) => word.includes(letter))) {
    // return "#6e6a6c";
    return "#FF00FF";
  }
  else if (vanilla_list.some((letter) => word.includes(letter))) {
    // return "#b508ff";
    return "#00FFFF";
  }
  else if (light_green_list.some((letter) => word.includes(letter))) {
    // return "#acfcac";
    return "#FF8000";
  }
  else if (green_list.some((letter) => word.includes(letter))) {
    // return "#2bff84";
    return "#8000FF";
  }
  else if (light_blue_list.some((letter) => word.includes(letter))) {
    // return "#6de4fc";
    return "#FF0080";
  }
  else if (purple_list.some((letter) => word.includes(letter))) {
    // return "#b300fa";
    return "#0080FF";
  } 
  else if (red_list.some((letter) => word.includes(letter))) {
    // return "#fc031c";
    return "#80FF00";
  }
  else if (blue_list.some((letter) => word.includes(letter))) {
    // return "#2d34fa";
    return "#00FF80";
  }
  else if (orange_list.some((letter) => word.includes(letter))) {
    // return "#f76911";
    return "#800000";
  }
  else if (dark_blue_list.some((letter) => word.includes(letter))) {
    // return "#021352";
    return "#008000";
  }
  else if (dark_red_list.some((letter) => word.includes(letter))) {
    // return "#470107";
    return "#000080";
  }
  else if (dark_green_list.some((letter) => word.includes(letter))) {
    // return "#0e3801";
    return "#808080";
  }
  else if (dark_purple_list.some((letter) => word.includes(letter))) {
    // return "#30024f";
    return "#800080";
  }
  else if (dark_pink_list.some((letter) => word.includes(letter))) {
    // return "#400133";
    return "#808000";
  }
  else if (dark_orange_list.some((letter) => word.includes(letter))) {
    // return "#3b2301";
    return "#008080";
  }
  else {
    return "#000";
  }
  // } else if (green_en_list.some((letter) => word.includes(letter))) {
  //   return "#008000";
  // } else if (blue_en_list.some((letter) => word.includes(letter))) {
  //   return "#0000FF";
  // } else if (purple_en_list.some((letter) => word.includes(letter))) {
  //   return "#800080";
  // } else if (red_en_list.some((letter) => word.includes(letter))) {
  //   return "#FF0000";
  // } else if (light_green_ru_list.some((letter) => word.includes(letter))) {
  //   return "#90EE90";
  // } else if (light_pink_ru_list.some((letter) => word.includes(letter))) {
  //   return "#FFB6C1";
  // } else if (yellow_ru_list.some((letter) => word.includes(letter))) {
  //   return "#e5de00";
  // } else if (purple_ru_list.some((letter) => word.includes(letter))) {
  //   return "#800080";
  // } else if (red_ru_list.some((letter) => word.includes(letter))) {
  //   return "#FF0000";
  // } else if (light_blue_ru_list.some((letter) => word.includes(letter))) {
  //   return "#6FC0D8";
  // } else if (orange_ru_list.some((letter) => word.includes(letter))) {
  //   return "#FFA500";
  // } else {
  //   return "#000";
  // }
}
