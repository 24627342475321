export default function getRocketLvl(rocketNum) {
  if (rocketNum < 6) {
    return 1;
  } else if (5 < rocketNum && rocketNum < 12) {
    return 2;
  } else if (11 < rocketNum && rocketNum < 19) {
    return 3;
  } else if (18 < rocketNum && rocketNum < 27) {
    return 4;
  } else if (26 < rocketNum && rocketNum < 36) {
    return 5;
  } else if (35 < rocketNum && rocketNum < 46) {
    return 6;
  } else if (45 < rocketNum && rocketNum < 57) {
    return 7;
  } else if (56 < rocketNum && rocketNum < 69) {
    return 8;
  } else if (68 < rocketNum && rocketNum < 101) {
    return 9;
  } else if (100 < rocketNum) {
    return 10;
  } else {
    return 1;
  }
}
