import "./style.scss";
import getLeague from "../../utils/getLeague";
import { useState } from "react";
import getSquadLeague from "../../utils/getSquadLeague";
import getEnergyLimit from "../../utils/getEnergyLimit";

export default function ProgressBar(props) {
  let league;
  if (props.type === "squads") {
    league = getSquadLeague(props.score);
  } else {
    league = getLeague(props.score);
  }
  

  return (
    <div className="progress-bar">
      {props.type ? (
        props.type === "squads" ? (
          <div
            className={`progress-bar-line progress-bar-color_type-${league}`}
            style={{
              width: `${
                league === "bronze"
                  ? (props.score / 500000) * 100
                  : league === "silver"
                  ? (props.score / 100000000) * 100
                  : league === "gold"
                  ? (props.score / 900000000) * 100
                  : league === "platinum"
                  ? (props.score / 5000000000) * 100
                  : 100
              }%`,
            }}
          ></div>
        ) : (
          <div
            className={`progress-bar-line progress-bar-color_type-${league}`}
            style={{
              width: `${
                league === "bronze"
                  ? (props.score / 10000) * 100
                  : league === "silver"
                  ? (props.score / 200000) * 100
                  : league === "gold"
                  ? (props.score / 2000000) * 100
                  : league === "platinum"
                  ? (props.score / 10000000) * 100
                  : 100
              }%`,
            }}
          ></div>
        )
      ) : (
        <div
          className={
            props.mainSkin === "basic" || props.mainSkin === "pizza"
              ? `progress-bar-line progress-bar-color_type-${league}`
              : `progress-bar-line progress-bar-color_type-${props.mainSkin}`
          }
          style={{
            width: `${
              props.rocket
                ? (props.secondsRocket / (20 + getEnergyLimit(props.energy_limit))) * 100
                : props.click && props.isActiveClick
                ? (props.secondsClick / (15 + getEnergyLimit(props.energy_limit))) * 100
                : league === "bronze"
                ? (props.score / 10000) * 100
                : league === "silver"
                ? ((props.score - 10000) / 190000) * 100
                : league === "gold"
                ? ((props.score - 200000) / 1800000) * 100
                : league === "platinum"
                ? ((props.score - 2000000) / 8000000) * 100
                : 100
            }%`,
          }}
        ></div>
      )}
    </div>
  );
}
