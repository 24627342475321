import React, { useEffect, useState } from "react";
import axios from "axios";

const LastAction = ({ userId }) => {
  const [lastAction, setLastAction] = useState("");

  // Функция для обновления баланса пользователя
  const updateLastAction = async () => {
    try {
      
      // const currentTimeUTC = new Date().toUTCString();
      // console.log(new Date(), currentTimeUTC)
      // const currentTimeUTC = new Date();
      // var utc = new Date(currentTimeUTC.getTime() + currentTimeUTC.getTimezoneOffset() * 60000);
      // console.log(currentTimeUTC, utc)

      const currentTimeUTC = new Date();

      const formattedDateTime = new Intl.DateTimeFormat("en-US", {
        timeZone: "UTC",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }).format(currentTimeUTC);
      axios
        .put(`https://pixelcoin.site:433/users/${userId}/last_action`, {
          last_action: formattedDateTime,
        })
        .then((response) => {
          setLastAction(response.data.last_action);
        });
    } catch (error) {
      console.error("Error updating last_action:", error);
    }
  };

  useEffect(() => {
    // Вызываем функцию обновления статуса пользователя при монтировании компонента
    updateLastAction();

    // Устанавливаем интервал для периодического обновления статуса пользователя
    const intervalId = setInterval(updateLastAction, 300000); // Обновление статуса каждые 15 секунд

    // Убираем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
  }, []); // Пустой массив зависимостей указывает, что эффект должен запускаться только один раз при монтировании

  return <></>;
};

export default LastAction;
