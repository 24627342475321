export default function getBoosters(item, num) {
  if (
    item === "multitap" ||
    item === "energy_limit" ||
    item === "recharging_speed" ||
    item === "ghost_bounty"
  ) {
    if (num === 0) {
      return 100;
    } else if (num === 1) {
      return 2000;
    } else if (num === 2) {
      return 4000;
    } else if (num === 3) {
      return 8000;
    } else if (num === 4) {
      return 16000;
    } else if (num === 5) {
      return 32000;
    } else if (num === 6) {
      return 64000;
    } else if (num === 7) {
      return 128000;
    } else if (num === 8) {
      return 256000;
    } else if (num === 9) {
      return 512000;
    } else if (num === 10) {
      return 1024000;
    } else if (num === 11) {
      return 2048000;
    } else if (num === 12) {
      return 4096000;
    } else if (num === 13) {
      return 8192000;
    } else if (num === 14) {
      return 16384000;
    } else if (num === 15) {
      return 32768000;
    } else if (num === 16) {
      return 65536000;
    } else if (num === 17) {
      return 131072000;
    } else if (num === 18) {
      return 262144000;
    } else if (num === 19) {
      return 524288000;
    } else {
      return 1048576000;
    }
  } else {
    if (num === 0) {
      return 10000;
    } else if (num === 1) {
      return 2000;
    } else if (num === 2) {
      return 4000;
    } else if (num === 3) {
      return 8000;
    } else if (num === 4) {
      return 16000;
    } else if (num === 5) {
      return 32000;
    } else if (num === 6) {
      return 64000;
    } else if (num === 7) {
      return 128000;
    } else if (num === 8) {
      return 256000;
    } else if (num === 9) {
      return 512000;
    } else if (num === 10) {
      return 1024000;
    } else if (num === 11) {
      return 2048000;
    } else if (num === 12) {
      return 4096000;
    } else if (num === 13) {
      return 8192000;
    } else if (num === 14) {
      return 16384000;
    } else if (num === 15) {
      return 32768000;
    } else if (num === 16) {
      return 65536000;
    } else if (num === 17) {
      return 131072000;
    } else if (num === 18) {
      return 262144000;
    } else if (num === 19) {
      return 524288000;
    } else {
      return 1048576000;
    }
  }
}
