export default function getRocket(rocketNum) {
    let x;
    if (rocketNum < 6) {
        x = 2;
    } else if (5 < rocketNum && rocketNum < 12) {
        x = 3;
    } else if (11 < rocketNum && rocketNum < 19) {
        x = 4;
    } else if (18 < rocketNum && rocketNum < 27) {
        x = 5;
    } else if (26 < rocketNum && rocketNum < 36) {
        x = 6;
    } else if (35 < rocketNum && rocketNum < 46) {
        x = 7;
    } else if (45 < rocketNum && rocketNum < 57) {
        x = 8;
    } else if (56 < rocketNum && rocketNum < 69) {
        x = 9;
    } else if (68 < rocketNum && rocketNum < 101) {
        x = 10;
    } else if (100 < rocketNum) {
        x = 11;
    } else {
        x = 1;
    }
    return x;
}