import getRocket from "./getRocket";
import getMultitap from "./getMultitap";

export default function changeScoreSession(
  balance,
  session,
  rocket,
  rocketNum,
  skins,
  multitap,
) {
  if (rocket) {
    session = parseInt(session + 100 * getRocket(rocketNum) * getMultitap(multitap));
  } else {
    session = parseInt(session + 100 * getMultitap(multitap));
  }
  return session;
}
