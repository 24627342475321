import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import axios from "axios";

export default function Referral({ userId, squad }) {
  const navigate = useNavigate();

  const [showPage, setShowPage] = useState(false);

  setTimeout(() => {
    setShowPage(true);
  }, 50); // Задержка перед появлением страницы

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const documentHeight = 630;
      if (documentHeight > windowHeight) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <BackButton onClick={() => navigate("/fren")} />
      <div className="page">
        <div className="background-yellow theme-yellow"></div>
        <div
          className={`container position-relative ${
            shouldScroll ? `scrolling` : ``
          }`}
        >
          <div className="page__content uY4S">
            <CSSTransition
              in={showPage}
              timeout={50}
              className="referral-animation"
              unmountOnExit
            >
              <div>
                <div className="iosW">{parsedData.referral.title_invite}</div>
                <div>
                  <div className="B0WY">
                    <div className="LIcp">
                      <div className="referral-icon referral-icon__pixelcoin-icon"></div>
                    </div>
                    <div className="LIcp">
                      <div className="nnot">
                        {parsedData.referral.invite_fren}
                        <br />
                        <div className="Li2p">
                          <div className="penny-icon vBwQ bv0r"></div>
                          <div className="R35h JG3Z bv0r">50,000</div>{" "}
                          {parsedData.referral.for_you_and_fren}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="B0WY">
                    <div className="IY3i">
                      <div className="referral-icon referral-icon__prem-icon"></div>
                    </div>
                    <div className="IY3i">
                      <div className="nnot">
                        {parsedData.referral.fren_with}{" "}
                        <a
                          onClick={() => {
                            WebApp.openTelegramLink("https://t.me/premium");
                          }}
                          className="prem-link"
                        >
                          {parsedData.referral.telegram_premium}
                        </a>
                        <br />
                        <div className="lDtF nnot">
                          <div className="Li2p">
                            <div className="penny-icon vBwQ bv0r"></div>
                            <div className="R35h JG3Z bv0r">40,000</div>{" "}
                            {parsedData.referral.for_you_and_fren}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="Gc12">{parsedData.referral.title_level}</div>
                <div className="position-relative">
                  <div>
                    <div className="sJs1 IYwx">
                      {parsedData.referral.level_up}
                    </div>
                    <div className="IUec Khw3">
                      {parsedData.referral.regular_fren}
                    </div>
                    {/* <div className="IUec Khw3">
                      {parsedData.referral.premium}
                    </div> */}
                  </div>
                  <div>
                    <div className="JG3Z">
                      <div className="referral-league-icon referral-league-icon__silver ebYk"></div>
                      <div className="Ibf1">{parsedData.referral.silver}</div>
                    </div>
                    <div>
                      <div className="IYw2">
                        <div className="YRj2 R35h">+100,000</div>
                      </div>
                      {/* <div className="IYw2">
                        <div className="YRj2 R35h">+60,000</div>
                      </div> */}
                    </div>
                  </div>
                  <div>
                    <div className="JG3Z">
                      <div className="referral-league-icon referral-league-icon__gold IUxe"></div>
                      <div className="Ld9i">{parsedData.referral.gold}</div>
                    </div>
                    <div>
                      <div className="UKn3">
                        <div className="YRj2 R35h">+200,000</div>
                      </div>
                      {/* <div className="UKn3">
                        <div className="YRj2 R35h">+80,000</div>
                      </div> */}
                    </div>
                  </div>
                  <div>
                    <div className="JG3Z">
                      <div className="referral-league-icon referral-league-icon__platinum Jwn3"></div>
                      <div className="Khe8">{parsedData.referral.platinum}</div>
                    </div>
                    <div>
                      <div className="Ieo1">
                        <div className="YRj2 R35h">+300,000</div>
                      </div>
                      {/* <div className="Ieo1">
                        <div className="YRj2 R35h">+100,000</div>
                      </div> */}
                    </div>
                  </div>
                  <div>
                    <div className="JG3Z">
                      <div className="referral-league-icon referral-league-icon__diamond JUYe"></div>
                      <div className="Khd1">{parsedData.referral.diamonds}</div>
                    </div>
                    <div>
                      <div className="Usyw">
                        <div className="YRj2 R35h">+500,000</div>
                      </div>
                      {/* <div className="Usyw">
                        <div className="YRj2 R35h">+120,000</div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    squad.length !== 0
                      ? WebApp.openTelegramLink(
                          `https://t.me/share/url?text=${
                            parsedData.referral_system.play_and_earn
                          }${`50,000`}${
                            parsedData.referral_system.for_you_and_fren
                          }&url=https://t.me/pixelcoinbot?start=r_${
                            squad.user_id
                          }_${userId}`
                        )
                      : WebApp.openTelegramLink(
                          `https://t.me/share/url?text=${
                            parsedData.referral_system.play_and_earn
                          }${`50,000`}${
                            parsedData.referral_system.for_you_and_fren
                          }&url=https://t.me/pixelcoinbot?start=rp_${userId}`
                        );
                  }}
                  className="fren-footer-btn"
                >
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">
                        {parsedData.referral.invite_a_fren}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </>
  );
}
