export default function newTurbo(rocketNum) {
  let turbo;
  if (rocketNum === 5) {
    turbo = 1;
  } else if (rocketNum === 11) {
    turbo = 2;
  } else if (rocketNum === 18) {
    turbo = 3;
  } else if (rocketNum === 26) {
    turbo = 4;
  } else if (rocketNum === 35) {
    turbo = 5;
  } else if (rocketNum === 45) {
    turbo = 6;
  } else if (rocketNum === 56) {
    turbo = 7;
  } else if (rocketNum === 68) {
    turbo = 8;
  } else if (rocketNum === 100) {
    turbo = 9;
  } 
  else {
    turbo = "";
  }
  return turbo;
}
