export default function getLeagueBonus(leagueBonus, league) {
    let bonus;
    let newLeagueBonus;
    if (leagueBonus === 1) {
        if (league === "silver") {
            bonus = 100000;
            newLeagueBonus = 2;
        } else if (league === "gold") {
            bonus = 300000;
            newLeagueBonus = 3;
        } else if (league === "platinum") {
            bonus = 600000;
            newLeagueBonus = 4;
        } else {
            bonus = 1100000;
            newLeagueBonus = 5;
        }
    } else if (leagueBonus === 2) {
        if (league === "gold") {
            bonus = 200000;
            newLeagueBonus = 3;
        } else if (league === "platinum") {
            bonus = 500000;
            newLeagueBonus = 4;
        } else {
            bonus = 1000000;
            newLeagueBonus = 5;
        }
    } else if (leagueBonus === 3) {
        if (league === "platinum") {
            bonus = 300000;
            newLeagueBonus = 4;
        } else {
            bonus = 800000;
            newLeagueBonus = 5;
        }
    } else {
        bonus = 500000;
        newLeagueBonus = 5;
    }
    return {bonus, newLeagueBonus}
}