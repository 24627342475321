import { useState } from "react";
import getColor from "../../utils/getColor";

export default function UserPhoto({ url_photo, first_name }) {
  const [hasError, setHasError] = useState(false);
  return (
    <>
      {url_photo !== "" && !hasError ? (
        <img
          src={url_photo}
          className="party_skeleton__circle"
          onError={() => {
            setHasError(true);
          }}
        />
      ) : (
        <div
          className="party_skeleton__circle"
          style={{
            background: getColor(first_name.substring(0, 1)),
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "16px",
              fontWeight: "600",
              color: "#fff",
            }}
          >
            {first_name.substring(0, 2).toUpperCase()}
          </div>
        </div>
      )}
    </>
  );
}
