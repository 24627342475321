export default function getRechargingSpeed(recharging_speed) {
    if (recharging_speed === 0) {
        return 1.2
    } else if (recharging_speed === 1) {
        return 1.18
    } else if (recharging_speed === 2) {
        return 1.16
    } else if (recharging_speed === 3) {
        return 1.14
    } else if (recharging_speed === 4) {
        return 1.12
    } else if (recharging_speed === 5) {
        return 1.1
    } else if (recharging_speed === 6) {
        return 1.08
    } else if (recharging_speed === 7) {
        return 1.06
    } else if (recharging_speed === 8) {
        return 1.04
    } else if (recharging_speed === 9) {
        return 1.02
    } else if (recharging_speed === 10) {
        return 1
    } else if (recharging_speed === 11) {
        return 0.98
    } else if (recharging_speed === 12) {
        return 0.96
    } else if (recharging_speed === 13) {
        return 0.94
    } else if (recharging_speed === 14) {
        return 0.92
    } else if (recharging_speed === 15) {
        return 0.90
    } else if (recharging_speed === 16) {
        return 0.88
    } else if (recharging_speed === 17) {
        return 0.86
    } else if (recharging_speed === 18) {
        return 0.84
    } else if (recharging_speed === 19) {
        return 0.82
    } else if (recharging_speed === 20) {
        return 0.8
    } else {
        return 0.78
    }
}