export default function getMultitap(multitap) {
    if (multitap === 0) {
        return 1
    } else if (multitap === 1) {
        return 2
    } else if (multitap === 2) {
        return 3
    } else if (multitap === 3) {
        return 4
    } else if (multitap === 4) {
        return 5
    } else if (multitap === 5) {
        return 6
    } else if (multitap === 6) {
        return 7
    } else if (multitap === 7) {
        return 8
    } else if (multitap === 8) {
        return 9
    } else if (multitap === 9) {
        return 10
    } else if (multitap === 10) {
        return 11
    } else if (multitap === 11) {
        return 12
    } else if (multitap === 12) {
        return 13
    } else if (multitap === 13) {
        return 14
    } else if (multitap === 14) {
        return 15
    } else if (multitap === 15) {
        return 16
    } else if (multitap === 16) {
        return 17
    } else if (multitap === 17) {
        return 18
    } else if (multitap === 18) {
        return 19
    } else if (multitap === 19) {
        return 20
    } else if (multitap === 20) {
        return 21
    } else {
        return 22
    }
}