export default function getRocketClicks(rocketNum) {
    let clicks;
    if (rocketNum < 6) {
        clicks = 30;
    } else if (5 < rocketNum && rocketNum < 12) {
        clicks = 40;
    } else if (11 < rocketNum && rocketNum < 19) {
        clicks = 50;
    } else if (18 < rocketNum && rocketNum < 27) {
        clicks = 60;
    } else if (26 < rocketNum && rocketNum < 36) {
        clicks = 70;
    } else if (35 < rocketNum && rocketNum < 46) {
        clicks = 80;
    } else if (45 < rocketNum && rocketNum < 57) {
        clicks = 90;
    } else if (56 < rocketNum && rocketNum < 69) {
        clicks = 100;
    } else if (68 < rocketNum && rocketNum < 101) {
        clicks = 110;
    }
    else {
        clicks = 120;
    }
    return clicks
}