export default function randomRocket(rocketNum) {
  let randomNumber;
  if (rocketNum % 9 === 0) {
    randomNumber = 3;
  } else if (rocketNum % 8 === 0) {
    randomNumber = 6;
  } else if (rocketNum % 7 === 0) {
    randomNumber = 1;
  } else if (rocketNum % 6 === 0) {
    randomNumber = 5;
  } else if (rocketNum % 5 === 0) {
    randomNumber = 5;
  } else if (rocketNum % 4 === 0) {
    randomNumber = 1;
  } else if (rocketNum % 3 === 0) {
    randomNumber = 4;
  } else if (rocketNum % 2 === 0) {
    randomNumber = 2;
  } else if (rocketNum % 1 === 0) {
    randomNumber = 1;
  } else {
    randomNumber = 6;
  }
  // var randomNumber = Math.floor(Math.random() * 6) + 1;

  return randomNumber;
}
