export function langpack(lang) {
  let langpack;
  if (lang === "ru") {
    langpack = {
      main: {
        join_squad: "Присоединяйся к отряду",
        bronze: "Бронза",
        silver: "Серебро",
        gold: "Золото",
        platinum: "Платина",
        diamond: "Бриллиант",
        potato_server: "Картофельный сервер 🥔",
        frens: "Друзья",
        earn: "Заработай",
        boosts: "Бусты",
        trade: "Перевести",
      },
      squad: {
        bronze: "Бронза",
        silver: "Серебро",
        gold: "Золото",
        platinum: "Платина",
        diamond: "Бриллиант",
        how_it_works: "Как работают отряды",
        mined_in_squad: "всего очков",
        players: "Игроков",
        join_squad: "Присоединиться к отряду",
        invite_a_fren: "Пригласить друга",
        leave_squad: "Выйти из отряда",
        want_to_leave: "Ты уверен(-а), что хочешь покинуть",
        squad: "отряд",
      },
      squads: {
        title: "Залетай в отряд",
        these_squads_receruiting_now: "Эти отряды активно набирают игроков",
        do_you_wanna_join: "Выбирай любой",
        empty_squad: "Здесь ничего нет",
        under_maintenance: "Временно недоступно",
        create_your_squad: "Создай свой отряд",
        bronze: "Бронза",
        silver: "Серебро",
        gold: "Золото",
        platinum: "Платина",
        diamond: "Бриллиант",
      },
      join_squad: {
        join: "Присоединиться к",
        squad: "отряду",
        header_text:
          "Каждое нажатие засчитывается в счет твоего отряда. Таблица лидеров отряда покажет твой вклад.",
        footer_text: "Вместе веселее играть ✨",
        button: "Присоединиться",
      },
      about: {
        title_default: "Пикселькоин",
        title_squads: "Отряды",
        join: "Канал",
        follow: "Твиттер",
        card_title_play: "Жми на монеты, майни алмазы",
        card_text_play: "И зарабатывай Pixelcoin",
        card_title_earn: "Прокачивай бусты",
        card_text_earn: "И зарабатывай выполняя задания",
        card_title_league: "Соревнуйся с другими",
        card_text_league: "Участвуй в лигах и турнирах",
        card_title_rocket: "Лови ракету",
        card_text_rocket: "Чтобы получить больше монет",
        // card_title_squad: "Вступай в отряд",
        // card_text_squad: "Вместе веселее",
        card_title_squad: "Отправляй пикселькоины",
        card_text_squad: "Да, это легко!",
        card_title_squads: "Социальные сети это Отряды",
        card_text_squads: "Присоединяйся и играй вместе",
        card_title_bag: "Пригласи весь чат",
        card_text_bag: "И получи больше бонусов",
        card_title_medal: "Подними свой отряд в топ",
        card_text_medal: "Обучай новичков как играть",
        full_guide: "Полное руководство",
        play: "Играть",
        good: "Хорошо",
        telegraph: "https://telegra.ph/Pixelcoin-polnoe-rukovodstvo-02-22",
      },
      earn: {
        your_balance: "Баланс",
        send: "Отправить",
        receive: "Получить",
        onboarding: "Выполняй задания",
        send_coins: "Отправить 10,000 Pixelcoin",
        join_squad: "Присоединись к отряду",
        invite_ten_frens: "Пригласи 10 друзей",
        completed: "Выполнено",
        competitive: "Соревновательный режим",
        compete: "Состязания",
        earn_more_coins: "Зарабатывай больше монет",
        full_guide: "Полный гайд",
        invite_bonus: "Бонус за приглашение",
        up_to: "До",
        for_fren: "за друга",
        specials: "Cпецпредложения",
        follow_pixelcoin: "Подпишись на Pixelcoin",
        pixelcoin_on_x: "Pixelcoin в X",
        web_world: "Мир Web3",
        coming_soon: "Ожидайте скоро...",
        just_wait: "Просто подождите",
        built_with: "Построен с",
        by: "от",
        community: "Сообщества",
      },
      boost: {
        your_balance: "Баланс",
        how_boost_works: "Как работают бусты",
        free_daily_boosters: "Ежедневные бусты",
        turbo: "Турбо",
        turbo_description1:
          "Умные автоматические ежедневные усилители для активных майнеров.",
        turbo_description2: "Будь готов поймать ракету!",
        full_energy: "Энергия",
        full_energy_description1: "Энергия ракеты на исходе.",
        full_energy_description2: "Достигните высшего уровня Турбо.",
        level: "уровень",
        unlimited: "Не ограничен",
        hours_left: "часов осталось",
        free: "Бесплатный",
        title_nft: "Бусты",
        // description_nft:
        //   "Уникальные скины NFT с встроенными усилителями, чтобы улучшить ваш опыт игры. Достигайте новых высот с нашей коллекцией скинов Boosted NFT.",
        description_nft:
        "Уникальные NFT скины с встроенными усилителями.",
        you_own_it: "Куплено",
        enabled: "включено",
        turn_on: "включить",
        apply_skin: "Применить скин",
        get: "Получить",
        basic: "Основной",
        basic_description: "Не всякий сокровище — серебро и золото, приятель.",
        pizza: "Пицца",
        pizza_description:
          "Я заплачу 10,000 пиксельных монет за пиццу... например, одну большую, чтобы у меня осталось что-то на следующий день.",
        cookie: "Печенье",
        cookie_description1: "Увеличьте предел вашей энергии,",
        cookie_description2: "чтобы вы могли добывать больше за сеанс.",
        cookie_description3: "+15 секунд для кликера.",
        galleon: "Догкоин",
        galleon_description1: "Получайте монеты за призраков.",
        galleon_description2: "Теперь призраки станут дружелюбными.",
        galleon_description3: "Зарабатывайте монеты на призраках.",
        fallout: "Монета Нука-Кола",
        fallout_description1: "Увеличьте свой предел энергии,",
        fallout_description2: "чтобы вы могли добывать больше за сессию.",
        fallout_description3: "Скорость игры в 1 секунду.",
        dragon: "Скин дракона",
        dragon_description1: "Увеличьте свой предел энергии,",
        dragon_description2: "чтобы вы могли добывать больше за сессию.",
        dragon_description3: "+20 секунд для турбо.",
        pixelcoin: "Pixelcoin NFT",
        pixelcoin_description1: "Увеличить количество пиксельных монет",
        pixelcoin_description2: "вы можете заработать за одно нажатие.",
        pixelcoin_description3:
          "Удвоить количество за каждое нажатие для всех режимов.",
        not_enough_coins: "Недостаточно монет",
        loading: "Загрузка...",
        built_with: "Построен с",
        by: "от",
        community: "Сообщества",
      },
      stats: {
        balance_text_fiat: "Общий баланс игроков",
        total_players: "Всего игроков",
        daily_users: "Ежедневные игроки",
        online: "В сети",
        invite_a_fren: "Пригласить друга",
      },
      party_kings: {
        title: "Короли вечеринки",
        description: "Пригласи больше друзей, чтобы попасть сюда",
        invite_frens: "Пригласить друзей",
        frens: "друзей",
      },
      qr_code: {
        desktop_is_boring: "Компьютер скучный.",
        play_on_your_mobile: "Играй на своем телефоне.",
      },
      referral: {
        title_invite: "Бонусы за друзей",
        invite_fren: "Пригласить друга",
        for_you_and_fren: "тебе и другу",
        fren_with: "Друг с",
        telegram_premium: "Телеграм премиум",
        title_level: "Бонус за переход друга в новую лигу",
        level_up: "Новая лига",
        regular_fren: "За друга",
        premium: "Премиум",
        silver: "Серебро",
        gold: "Золото",
        platinum: "Платина",
        diamonds: "Бриллиант",
        invite_a_fren: "Пригласить друга",
      },
      fren: {
        title: "Френдзона",
        title_short: "Френс",
        how_to_make: "Как заработать 1M?",
        top: "Топ 300",
        leaders: "лидеры",
        title_invite: "Пригласи друзей, получи бонус",
        invite_fren: "Пригласить друга",
        for_you_and_fren: "для тебя и друга",
        fren_with: "Друг с",
        telegram_premium: "Телеграм премиум",
        open_details: "Открыть детали",
        title_frens_list: "Список друзей",
        empty_frens_list: "Друзей пока нет",
        under_maintenance: "Временно недоступно",
        invite_a_fren: "Пригласить друга",
      },
      league: {
        pixelcoiners: "Пикселькоинеры",
        stats: "Статы",
        bronze_league: "Бронзовая лига",
        silver_league: "Серебряная лига",
        gold_league: "Золотая лига",
        platinum_league: "Платиновая лига",
        diamond_league: "Бриллиантовая лига",
        miners: "Майнеры",
        squads: "Отряды",
        empty_list: "Здесь ничего нет",
        under_maintenance: "Временно недоступно",
        you_will_join_leaderboard: "Вы присоединитесь к таблице лидеров",
        in_the_silver_league: "в Серебряной лиге",
        leaderboard_is_under_construction:
          "Таблица лидеров находится в разработке",
        bronze_miners: "от 0 Pixelcoin",
        bronze_squads: "от 0 Pixelcoin",
        silver_miners: "от 10K Pixelcoin",
        silver_squads: "от 500K Pixelcoin",
        gold_miners: "от 200K Pixelcoin",
        gold_squads: "от 100M Pixelcoin",
        platinum_miners: "от 2M Pixelcoin",
        platinum_squads: "от 900M Pixelcoin",
        diamond_miners: "от 10M Pixelcoin",
        diamond_squads: "от 5B Pixelcoin",
        you: "Ты",
        your: "Твой",
      },
      compete: {
        title: "Состязания",
        play_tournaments_and_earn_coins:
          "Участвуй в турнирах и зарабатывай монеты.",
        do_you_wanna_join: "Хочешь присоединиться?",
        empty_tournaments: "Здесь ничего нет",
        under_maintenance: "Временно недоступно",
      },
      referral_system: {
        play_and_earn:
          "%D0%98%D0%B3%D1%80%D0%B0%D0%B9%20%D0%B8%20%D0%B7%D0%B0%D1%80%D0%B0%D0%B1%D0%B0%D1%82%D1%8B%D0%B2%D0%B0%D0%B9%0A%F0%9F%8E%81%20%2B",
        for_you_and_fren:
          "%20Pixelcoin%20%D0%B4%D0%BB%D1%8F%20%D1%82%D0%B5%D0%B1%D1%8F%20%D0%B8%20%D0%B4%D1%80%D1%83%D0%B3%D0%B0",
      },
      create_squad: {
        create_your_squad: "Создай свой отряд",
        send_link: "Отправьте ссылку на социальную сеть, которую вы хотите создать.",
        examples: "Примеры:",
        and_etc: "и другие.",
      },
    };
  } else {
    langpack = {
      main: {
        join_squad: "Join squad",
        bronze: "Bronze",
        silver: "Silver",
        gold: "Gold",
        platinum: "Platinum",
        diamond: "Diamond",
        potato_server: "Potato server 🥔",
        frens: "Frens",
        earn: "Earn",
        boosts: "Boosts",
        trade: "Send",
      },
      squad: {
        bronze: "Bronze",
        silver: "Silver",
        gold: "Gold",
        platinum: "Platinum",
        diamond: "Diamond",
        how_it_works: "How it works",
        mined_in_squad: "mined in squad",
        players: "players",
        join_squad: "Join squad",
        invite_a_fren: "Invite a fren",
        leave_squad: "Leave squad",
        want_to_leave: "Are you sure you want to leave",
        squad: "Squad",
      },
      squads: {
        title: "Join Squad!",
        these_squads_receruiting_now: "These squads receruitiing now.",
        do_you_wanna_join: "Do you wanna join?",
        empty_squad: "There is nothing here",
        under_maintenance: "Under maintenance",
        create_your_squad: "Create your squad",
        bronze: "Bronze",
        silver: "Silver",
        gold: "Gold",
        platinum: "Platinum",
        diamond: "Diamond",
      },
      join_squad: {
        join: "Join",
        squad: "Squad",
        header_text:
          "Every tap counts towards your squad score. Squad leaderboard will show your contribution.",
        footer_text: "More fun playing together ✨",
        button: "Join squad",
      },
      about: {
        title_default: "Pixelcoin",
        title_squads: "Squads",
        join: "Join",
        follow: "Follow",
        card_title_play: "Tap on coins to mine Diamonds",
        card_text_play: "And earn Pixelcoin",
        card_title_earn: "Upgrade your Boosts",
        card_text_earn: "And earn coins by completing tasks",
        card_title_league: "Climb to the top",
        card_text_league: "Participate in leagues and tournaments",
        card_title_rocket: "Catch the rocket",
        card_text_rocket: "To get more coins",
        // card_title_squad: "Join Squad!",
        // card_text_squad: "More fun together",
        card_title_squad: "Send Pixelcoins",
        card_text_squad: "Yes, it's easy!",
        card_title_squads: "Socials are Squads",
        card_text_squads: "Play for your favourite one",
        card_title_bag: "Invite the whole chat",
        card_text_bag: "And get tons of bonuses",
        card_title_medal: "Get your squad to the top",
        card_text_medal: "Guide newcomers how to play",
        full_guide: "Full guide",
        play: "Play",
        good: "Good",
        telegraph: "https://telegra.ph/Pixelcoin-a-full-guide-02-22",
      },
      earn: {
        your_balance: "Your balance",
        send: "Send",
        receive: "Receive",
        onboarding: "Onboarding",
        send_coins: "Send 10,000 Pixelcoin",
        join_squad: "Join squad",
        invite_ten_frens: "Invite 10 frens",
        completed: "Completed",
        competitive: "Competitive",
        compete: "Compete",
        earn_more_coins: "Earn more coins",
        full_guide: "Full guide",
        invite_bonus: "Invite bonus",
        up_to: "up to",
        for_fren: "for fren",
        specials: "Specials",
        follow_pixelcoin: "Follow Pixelcoin",
        pixelcoin_on_x: "Pixelcoin on X",
        web_world: "Web3 world",
        coming_soon: "Coming soon...",
        just_wait: "Just wait",
        built_with: "Built with",
        by: "by",
        community: "Community",
      },
      boost: {
        your_balance: "Your balance",
        how_boost_works: "How boost works",
        free_daily_boosters: "Free daily boosters",
        turbo: "Turbo",
        turbo_description1: "Smart automatic daily boosts for active miners.",
        turbo_description2: "Be ready to catch the rocket!",
        full_energy: "Energy",
        full_energy_description1: "The rocket's energy is depleting.",
        full_energy_description2: "Reach the highest Turbo level.",
        level: "level",
        unlimited: "Unlimited",
        hours_left: "hours left",
        free: "Free",
        title_nft: "Boosters",
        // description_nft:
        //   "Unique NFT skins with built-in boosts to enhance your gaming experience. Reach new heights with our collection of Boosted NFT Skins.",
        description_nft:
          "Unique NFT skins with built-in boosts.",
        you_own_it: "You own it",
        enabled: "enabled",
        turn_on: "turn on",
        apply_skin: "Apply Skin",
        get: "Get",
        basic: "Basic",
        basic_description: "Not all treasure is silver and gold, mate.",
        pizza: "Pizza",
        pizza_description:
          "I'll pay 10,000 pixelcoins for a pizza... like maybe one large so I have some left over for the next day.",
        cookie: "Cookie",
        cookie_description1: "Increase your energy limit,",
        cookie_description2: "so you can mine more per session.",
        cookie_description3: "+15 seconds for clicker.",
        galleon: "Doge Coin",
        galleon_description1: "Get coins for ghosts.",
        galleon_description2: "Now the ghosts will become friendly.",
        galleon_description3: "Earn coins for ghosts.",
        fallout: "Nuka-Cola Coin",
        fallout_description1: "Increase your energy limit,",
        fallout_description2: "so you can mine more per session.",
        fallout_description3: "1 second game speed.",
        dragon: "Dragon skin",
        dragon_description1: "Increase your energy limit,",
        dragon_description2: "so you can mine more per session.",
        dragon_description3: "+20 seconds for turbo.",
        pixelcoin: "Pixelcoin NFT",
        pixelcoin_description1: "Increase amount of Pixelcoin",
        pixelcoin_description2: "you can earn per one tap.",
        pixelcoin_description3: "x2 per each tap for all modes.",
        not_enough_coins: "Not enough coins",
        loading: "Loading...",
        built_with: "Built with",
        by: "by",
        community: "Community",
      },
      stats: {
        balance_text_fiat: "Total Pixelcoin Balance",
        total_players: "Total Players",
        daily_users: "Daily Users",
        online: "Online",
        invite_a_fren: "Invite a fren",
      },
      party_kings: {
        title: "Party Kings",
        description: "Invite more frens to get here",
        invite_frens: "Invite frens",
        frens: "frens",
      },
      qr_code: {
        desktop_is_boring: "Desktop is boring.",
        play_on_your_mobile: "Play on your mobile.",
      },
      referral: {
        title_invite: "Invite fren, get bonus",
        invite_fren: "Invite fren",
        for_you_and_fren: "for you and fren",
        fren_with: "Fren with",
        telegram_premium: "Telegram Premium",
        title_level: "Fren level up bonus",
        level_up: "Level up",
        regular_fren: "Regular fren",
        premium: "Premium",
        silver: "Silver",
        gold: "Gold",
        platinum: "Platinum",
        diamonds: "Diamonds",
        invite_a_fren: "Invite a fren",
      },
      fren: {
        title: "Fren zone",
        title_short: "Frens",
        how_to_make: "How to make 1M?",
        top: "Top 300",
        leaders: "leaders",
        title_invite: "Invite frens to get bonuses",
        invite_fren: "Invite fren",
        for_you_and_fren: "for you and fren",
        fren_with: "Fren with",
        telegram_premium: "Telegram Premium",
        open_details: "Open details",
        title_frens_list: "Frens List",
        empty_frens_list: "No frens yet",
        under_maintenance: "Under maintenance",
        invite_a_fren: "Invite a fren",
      },
      league: {
        pixelcoiners: "Pixelcoiners",
        stats: "Stats",
        bronze_league: "Bronze league",
        silver_league: "Silver league",
        gold_league: "Gold league",
        platinum_league: "Platinum league",
        diamond_league: "Diamond league",
        miners: "Miners",
        squads: "Squads",
        empty_list: "There is nothing here",
        under_maintenance: "Under maintenance",
        you_will_join_leaderboard: "You'll join leaderboard",
        in_the_silver_league: "in the Silver League",
        leaderboard_is_under_construction: "Leaderboard is under construction",
        bronze_miners: "from 0 Pixelcoin",
        bronze_squads: "from 0 Pixelcoin",
        silver_miners: "from 10K Pixelcoin",
        silver_squads: "from 500K Pixelcoin",
        gold_miners: "from 200K Pixelcoin",
        gold_squads: "from 100M Pixelcoin",
        platinum_miners: "from 2M Pixelcoin",
        platinum_squads: "from 900M Pixelcoin",
        diamond_miners: "from 10M Pixelcoin",
        diamond_squads: "from 5B Pixelcoin",
        you: "You",
        your: "Your",
      },
      compete: {
        title: "Compete",
        play_tournaments_and_earn_coins: "Play tournaments and earn coins.",
        do_you_wanna_join: "Do you wanna join?",
        empty_tournaments: "There is nothing here",
        under_maintenance: "Under maintenance",
      },
      referral_system: {
        play_and_earn: "Play-to-Earn%0A%F0%9F%8E%81%20%2B",
        for_you_and_fren: "%20Pixelcoin%20for%20you%20and%20fren",
      },
      create_squad: {
        create_your_squad: "Create your squad",
        send_link: "Send a link to the social you want to create",
        examples: "examples:",
        and_etc: "and etc.",
      },
    };
  }

  return langpack;
}
