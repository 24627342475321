export default function convertLeagueBonus(league) {
    let leagueBonus;
    if (league === "bronze") {
        leagueBonus = 1
    } else if (league === "silver") {
        leagueBonus = 2
    } else if (league === "gold") {
        leagueBonus = 3
    } else if (league === "platinum") {
        leagueBonus = 4
    } else {
        leagueBonus = 5
    }
    return leagueBonus;
}