import getRocket from "./getRocket";
import getMultitap from "./getMultitap";

export default function getScore(score, rocket, rocketNum, skins, multitap) {
  if (rocket) {
    score = parseInt(100 * getRocket(rocketNum) * getMultitap(multitap));
  } else {
    score = parseInt(100 * getMultitap(multitap));
  }
  return score;
}
