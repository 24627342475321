import "./style.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import BlackTransparent from "../../components/BlackTransparent";
import { CSSTransition } from "react-transition-group";
import addCommas from "../../utils/addCommas";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import getLeague from "../../utils/getLeague";
import Pigeon from "../../assets/animation/Pigeon.json";
import Chick_out from "../../assets/animation/Chick_out.json";
import Chick_stnd from "../../assets/animation/Chick_stnd.json";
import Chick from "../../assets/animation/Chick.json";
import getRocket from "../../utils/getRocket";
import ProgressBar from "../../components/ProgressBar";
import ProgressBoosts from "../../components/ProgressBoosts";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import getBoosters from "../../utils/getBoosters";
import getRocketLvl from "../../utils/getRocketLvl";
import Counter from "../../components/Counter";

export default function Boost({
  userId,
  score,
  updateScore,
  balance,
  updateBalance,
  mainSkin,
  updateMainSkin,
  skins,
  updateSkins,
  updateAllSkins,
  rocketNum,
  multitap,
  energy_limit,
  recharging_speed,
  ghost_bounty,
  auto_tap_bot,
  updateMultitap,
  updateEnergyLimit,
  updateRechargingSpeed,
  updateGhostBounty,
  updateAutoTapBot,
}) {
  const navigate = useNavigate();

  const [showPage, setShowPage] = useState(true);

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [starFirst, setStarFirst] = useState(false);

  setTimeout(() => {
    setStarFirst(true);
  }, 1800);

  const [starSecond, setStarSecond] = useState(false);

  setTimeout(() => {
    setStarSecond(true);
  }, 800);

  const [starThird, setStarThird] = useState(false);

  setTimeout(() => {
    setStarThird(true);
  }, 1200);

  const [starFourth, setStarFourth] = useState(false);

  setTimeout(() => {
    setStarFourth(true);
  }, 1200);

  const [isVisible, setIsVisible] = useState(false);

  const [loader, setLoader] = useState(false);

  const [isActive, setIsActive] = useState(false);

  const [isClosing, setIsClosing] = useState(false);

  const [selectSkin, setSelectSkin] = useState("");

  const openDetails = (selected) => {
    if (!isActive) {
      setIsClosing(false);
      setSelectSkin(selected);
      setIsActive(true);
    }
  };

  const closeDetails = () => {
    setIsClosing(true);
    setIsActive(false);
  };

  const [selectBoost, setSelectBoost] = useState("");
  const [isBoosters, setIsBoosters] = useState(false);

  const openBoosters = (selected) => {
    if (!isBoosters) {
      setIsClosing(false);
      setSelectBoost(selected);
      setIsBoosters(true);
    }
  };

  const closeBoosters = () => {
    setIsClosing(true);
    setIsBoosters(false);
  };

  const navigateGame = () => {
    setLoader(true);
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  const [hoursLeft, setHoursLeft] = useState(calculateHoursLeft());

  function calculateHoursLeft() {
    const now = new Date();
    // const utcMidnight = new Date(
    //   now.toLocaleString("en-US", { timeZone: "UTC" })
    // );
    const utcMidnight = new Date(now);
    utcMidnight.setUTCHours(24, 0, 0, 0); // Устанавливаем время на полночь UTC

    const difference = utcMidnight.getTime() - now.getTime();
    const hoursLeft = Math.floor(difference / (1000 * 60 * 60)); // Вычисляем количество оставшихся часов

    return hoursLeft;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setHoursLeft(calculateHoursLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const [index, setIndex] = useState(0);
  const handleSlideChange = (swiper) => {
    setIndex(swiper.activeIndex);
  };

  const [frens, setFrens] = useState([]);

  useEffect(() => {
    const fetchDataFrens = async () => {
      try {
        const response = await axios.get(
          `https://pixelcoin.site:433/users/${userId}/frens_list`
        );
        setFrens(response.data);
        setShowPage(true);
      } catch (error) {
        console.error("Error fetching frens:", error);
      }
    };

    fetchDataFrens();
  }, []);

  const updateBoost = (boost) => {
    if (boost === "multitap") {
      if (
        getBoosters("multitap", multitap) <= balance &&
        score + balance >= getBoosters("multitap", multitap)
      ) {
        updateBalance(balance - getBoosters("multitap", multitap));
        if (multitap === 0) {
          updateSkins("multitap1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `multitap${multitap}`) {
                return `multitap${multitap + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
      } else if (
        getBoosters("multitap", multitap) > balance &&
        getBoosters("multitap", multitap) <= score + balance &&
        score + balance >= getBoosters("multitap", multitap)
      ) {
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/score`)
          .then((response) => {
            const newScore =
              response.data.score -
              (getBoosters("multitap", multitap) - balance);
            updateScore(newScore);
            axios.put(`https://pixelcoin.site:433/users/${userId}/score`, {
              score: newScore,
            });
          });
        updateBalance(0);
        if (multitap === 0) {
          updateSkins("multitap1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `multitap${multitap}`) {
                return `multitap${multitap + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 2000);
      }
    } else if (boost === "energy_limit") {
      if (
        getBoosters("energy_limit", energy_limit) <= balance &&
        score + balance >= getBoosters("energy_limit", energy_limit)
      ) {
        updateBalance(balance - getBoosters("energy_limit", energy_limit));
        if (energy_limit === 0) {
          updateSkins("energy_limit1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `energy_limit${energy_limit}`) {
                return `energy_limit${energy_limit + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
        // updateEnergyLimit(energy_limit + 1);
      } else if (
        getBoosters("energy_limit", energy_limit) > balance &&
        getBoosters("energy_limit", energy_limit) <= score + balance &&
        score + balance >= getBoosters("energy_limit", energy_limit)
      ) {
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/score`)
          .then((response) => {
            const newScore =
              response.data.score -
              (getBoosters("energy_limit", energy_limit) - balance);
            updateScore(newScore);
            axios.put(`https://pixelcoin.site:433/users/${userId}/score`, {
              score: newScore,
            });
          });
        updateBalance(0);
        if (energy_limit === 0) {
          updateSkins("energy_limit1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `energy_limit${energy_limit}`) {
                return `energy_limit${energy_limit + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
        // updateEnergyLimit(energy_limit + 1);
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 2000);
      }
    } else if (boost === "recharging_speed") {
      if (
        getBoosters("recharging_speed", recharging_speed) <= balance &&
        score + balance >= getBoosters("recharging_speed", recharging_speed)
      ) {
        updateBalance(
          balance - getBoosters("recharging_speed", recharging_speed)
        );
        if (recharging_speed === 0) {
          updateSkins("recharging_speed1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `recharging_speed${recharging_speed}`) {
                return `recharging_speed${recharging_speed + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
        // updateRechargingSpeed(recharging_speed + 1);
      } else if (
        getBoosters("recharging_speed", recharging_speed) > balance &&
        getBoosters("recharging_speed", recharging_speed) <= score + balance &&
        score + balance >= getBoosters("recharging_speed", recharging_speed)
      ) {
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/score`)
          .then((response) => {
            const newScore =
              response.data.score -
              (getBoosters("recharging_speed", recharging_speed) - balance);
            updateScore(newScore);
            axios.put(`https://pixelcoin.site:433/users/${userId}/score`, {
              score: newScore,
            });
          });
        updateBalance(0);
        if (recharging_speed === 0) {
          updateSkins("recharging_speed1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `recharging_speed${recharging_speed}`) {
                return `recharging_speed${recharging_speed + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
        // updateRechargingSpeed(recharging_speed + 1);
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 2000);
      }
    } else if (boost === "ghost_bounty") {
      if (
        getBoosters("ghost_bounty", ghost_bounty) <= balance &&
        score + balance >= getBoosters("ghost_bounty", ghost_bounty)
      ) {
        updateBalance(balance - getBoosters("ghost_bounty", ghost_bounty));
        // updateGhostBounty(ghost_bounty + 1);
        if (ghost_bounty === 0) {
          updateSkins("ghost_bounty1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `ghost_bounty${ghost_bounty}`) {
                return `ghost_bounty${ghost_bounty + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
      } else if (
        getBoosters("ghost_bounty", ghost_bounty) > balance &&
        getBoosters("ghost_bounty", ghost_bounty) <= score + balance &&
        score + balance >= getBoosters("ghost_bounty", ghost_bounty)
      ) {
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/score`)
          .then((response) => {
            const newScore =
              response.data.score -
              (getBoosters("ghost_bounty", ghost_bounty) - balance);
            updateScore(newScore);
            axios.put(`https://pixelcoin.site:433/users/${userId}/score`, {
              score: newScore,
            });
          });
        updateBalance(0);
        if (ghost_bounty === 0) {
          updateSkins("ghost_bounty1");
        } else {
          updateAllSkins(
            skins.map((item) => {
              if (item === `ghost_bounty${ghost_bounty}`) {
                return `ghost_bounty${ghost_bounty + 1}`;
              }
              return item;
            })
          ).then(() => {
            closeBoosters();
          });
        }
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 2000);
      }
    } else {
      if (boost === "auto_tap_bot") {
        if (
          getBoosters("auto_tap_bot", auto_tap_bot) <= balance &&
          score + balance >= getBoosters("auto_tap_bot", auto_tap_bot)
        ) {
          updateBalance(balance - getBoosters("auto_tap_bot", auto_tap_bot));
          if (auto_tap_bot === 0) {
            updateSkins("auto_tap_bot1");
          } else {
            updateAllSkins(
              skins.map((item) => {
                if (item === `auto_tap_bot${auto_tap_bot}`) {
                  return `auto_tap_bot${auto_tap_bot + 1}`;
                }
                return item;
              })
            ).then(() => {
              closeBoosters();
            });
          }
        } else if (
          getBoosters("auto_tap_bot", auto_tap_bot) > balance &&
          getBoosters("auto_tap_bot", auto_tap_bot) <= score + balance &&
          score + balance >= getBoosters("auto_tap_bot", auto_tap_bot)
        ) {
          axios
            .get(`https://pixelcoin.site:433/users/${userId}/score`)
            .then((response) => {
              const newScore =
                response.data.score -
                (getBoosters("auto_tap_bot", auto_tap_bot) - balance);
              updateScore(newScore);
              axios.put(`https://pixelcoin.site:433/users/${userId}/score`, {
                score: newScore,
              });
            });
          updateBalance(0);
          if (auto_tap_bot === 0) {
            updateSkins("auto_tap_bot1");
          } else {
            updateAllSkins(
              skins.map((item) => {
                if (item === `auto_tap_bot${auto_tap_bot}`) {
                  return `auto_tap_bot${auto_tap_bot + 1}`;
                }
                return item;
              })
            )
            .then(() => {
              closeBoosters();
            });
          }
        } else {
          setIsVisible(true);
          setTimeout(() => {
            setIsVisible(false);
          }, 2000);
        }
      }
    }
  };

  const changeSkin = (skin) => {
    setLoader(true);
    if (skin === "pizza") {
      if (skins.includes(skin)) {
        updateMainSkin(skin);
      } else if (10000 <= balance && score + balance >= 10000) {
        updateBalance(balance - 10000);
        updateSkins(skin);
        updateMainSkin(skin);
      } else if (
        10000 > balance &&
        10000 <= score + balance &&
        score + balance >= 10000
      ) {
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/score`)
          .then((response) => {
            const newScore = response.data.score - (10000 - balance);
            updateScore(newScore);
            axios.put(`https://pixelcoin.site:433/users/${userId}/score`, {
              score: newScore,
            });
          });
        updateBalance(0);
        updateSkins(skin);
        updateMainSkin(skin);
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setLoader(false);
          setTimeout(() => {
            setIsVisible(false);
          }, 2000);
        }, 500);
      }
    } else if (skin === "telegram") {
      if (skins.includes(skin)) {
        updateMainSkin(skin);
      } else if (frens.length >= 3) {
        updateSkins(skin);
        updateMainSkin(skin);
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setLoader(false);
          setTimeout(() => {
            setIsVisible(false);
          }, 2000);
        }, 500);
      }
    } else if (
      skin === "basic" ||
      skin === "cookie" ||
      skin === "galleon" ||
      skin === "fallout" ||
      skin === "dragon" ||
      skin === "pixelcoin"
    ) {
      if (skins.includes(skin)) {
        updateMainSkin(skin);
      } else if (skin === "cookie") {
        if (energy_limit >= 7) {
          updateSkins(skin);
          updateMainSkin(skin);
        } else {
          setIsVisible(true);
          setTimeout(() => {
            setLoader(false);
            setTimeout(() => {
              setIsVisible(false);
            }, 2000);
          }, 500);
        }
      } else if (skin === "galleon") {
        if (ghost_bounty >= 9) {
          updateSkins(skin);
          updateMainSkin(skin);
        } else {
          setIsVisible(true);
          setTimeout(() => {
            setLoader(false);
            setTimeout(() => {
              setIsVisible(false);
            }, 2000);
          }, 500);
        }
      } else if (skin === "fallout") {
        if (recharging_speed >= 10) {
          updateSkins(skin);
          updateMainSkin(skin);
        } else {
          setIsVisible(true);
          setTimeout(() => {
            setLoader(false);
            setTimeout(() => {
              setIsVisible(false);
            }, 2000);
          }, 500);
        }
      } else if (skin === "dragon") {
        if (energy_limit >= 12) {
          updateSkins(skin);
          updateMainSkin(skin);
        } else {
          setIsVisible(true);
          setTimeout(() => {
            setLoader(false);
            setTimeout(() => {
              setIsVisible(false);
            }, 2000);
          }, 500);
        }
      } else if (skin === "pixelcoin") {
        if (multitap >= 13) {
          updateSkins(skin);
          updateMainSkin(skin);
        } else {
          setIsVisible(true);
          setTimeout(() => {
            setLoader(false);
            setTimeout(() => {
              setIsVisible(false);
            }, 2000);
          }, 500);
        }
      } else {
        setIsVisible(true);
        setTimeout(() => {
          setLoader(false);
          setTimeout(() => {
            setIsVisible(false);
          }, 2000);
        }, 500);
      }
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate("/")} />
      <div className="page">
        <div className="background-yellow theme-yellow"></div>
        <div
          style={{
            position: "relative",
          }}
        >
          <CSSTransition
            in={starFirst}
            timeout={800}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_boost-1 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starSecond}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_boost-2 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starThird}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_boost-3 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starFourth}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_boost-4 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
        </div>
        <div
          className={`container position-relative scrolling`}
          style={{
            overflow: isActive || isBoosters ? "hidden" : "",
          }}
        >
          <div
            onClick={
              isActive ? closeDetails : isBoosters ? closeBoosters : null
            }
            className="page__content"
            style={{
              opacity: isActive || isBoosters ? "0.6" : "",
              transitionDuration: isActive || isBoosters ? "0.3s" : "",
              // pointerEvents: isActive || isBoosters ? "none" : "",
            }}
          >
            <CSSTransition
              in={showPage}
              timeout={50}
              className="earn-animation"
              unmountOnExit
            >
              <div
                style={{
                  pointerEvents: isActive || isBoosters ? "none" : "",
                }}
              >
                <div className="Jh2w">
                  <div className="Li2p">
                    <div className="Uye4">{parsedData.boost.your_balance}</div>
                  </div>
                </div>
                <div className="Jh2w IueR">
                  <div className="Li2p">
                    <div className="balance-icon"></div>
                    <div className="UIn3">
                      <Counter start={balance + score} end={balance + score} />
                    </div>
                  </div>
                </div>
                <div className="Jh2w JGV3">
                  <div
                    onClick={() => {
                      navigate("/about?asset=default");
                    }}
                    className="Li2p Z5dC"
                  >
                    <div className="KH5k">
                      {parsedData.boost.how_boost_works}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "42px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    {parsedData.boost.free_daily_boosters}
                  </div>
                  {/* <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "500",
                      color: "rgba(255, 255, 255, 0.5)",
                      marginTop: "4px",
                      marginLeft: "10px",
                    }}
                  >
                    {hoursLeft} hours left
                  </div> */}
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    marginTop: "10px",
                  }}
                >
                  <button
                    onClick={() => openBoosters("turbo")}
                    className="boost-left-btn"
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#fff",
                        marginTop: "10px",
                        marginLeft: "16px",
                      }}
                    >
                      {parsedData.boost.turbo}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(235, 235, 245, 0.4)",
                        marginTop: "36px",
                        marginLeft: "16px",
                      }}
                    >
                      {`${getRocketLvl(rocketNum)}/10 lvl`}
                    </div>
                    <img
                      src={require("../../assets/images/rocket.png")}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        height: "30px",
                        width: "30px",
                        marginTop: "20px",
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                    {/* <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        // height: "30px",
                        // width: "30px",
                        fontSize: "30px",
                        marginTop: "14px",
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      🚀
                    </div> */}
                    <div
                      style={{
                        position: "absolute",
                        bottom: "12px",
                        left: "14px",
                        right: "14px",
                      }}
                    >
                      <ProgressBoosts mode={"turbo"} rocketNum={rocketNum} />
                    </div>
                  </button>
                  <button
                    onClick={() => openBoosters("energy")}
                    className="boost-right-btn"
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "#fff",
                        marginTop: "10px",
                        marginLeft: "16px",
                      }}
                    >
                      {parsedData.boost.full_energy}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        fontSize: "14px",
                        fontWeight: "400",
                        color: "rgba(235, 235, 245, 0.4)",
                        marginTop: "36px",
                        marginLeft: "16px",
                      }}
                    >
                      {/* {parsedData.boost.unlimited} */}
                      {hoursLeft} {parsedData.boost.hours_left}
                    </div>
                    <img
                      src={require("../../assets/images/lightning.png")}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        height: "30px",
                        width: "30px",
                        marginTop: "20px",
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                    {/* <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        // height: "30px",
                        // width: "30px",
                        fontSize: "30px",
                        marginTop: "14px",
                        marginRight: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      ⚡️
                    </div> */}
                    <div
                      style={{
                        position: "absolute",
                        bottom: "12px",
                        left: "14px",
                        right: "14px",
                      }}
                    >
                      <ProgressBoosts mode={"energy"} hours={hoursLeft} />
                    </div>
                  </button>
                </div>
                <div className="IUG3">{parsedData.boost.title_nft}</div>
                <div
                  style={{
                    position: "relative",
                    borderRadius: "14px",
                    background: "rgba(255, 255, 255, 0.12)",
                    padding: "232px 0",
                    marginTop: "14px",
                  }}
                >
                  <button
                    onClick={() => multitap < 21 && openBoosters("multitap")}
                    className={
                      multitap >= 21
                        ? `earn-transparent-btn_block`
                        : `earn-transparent-btn`
                    }
                    style={{
                      // marginTop: "96px",
                      marginTop: "6px",
                      borderRadius: "4px 4px 0px 0px",
                      opacity: multitap >= 21 ? "0.6" : "",
                    }}
                  >
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          paddingTop: "10px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            height: "70px",
                            backgroundColor:
                              "var(--palette-transparent-white-05)",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require("../../assets/images/index-pointing-up.png")}
                            className="UY49"
                          />
                        </div>
                        <div
                          style={{
                            paddingTop: "12px",
                            paddingLeft: "86px",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              fontSize: "17px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                            }}
                          >
                            Multitap
                          </div>
                          {multitap < 21 ? (
                            score + balance >= getBoosters("multitap", multitap) ? (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="penny-icon"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(getBoosters("multitap", multitap))}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    fontWeight: "400",
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {multitap} lvl
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "var(--pixelcoin-orange)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    ></path>
                                  </svg>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(getBoosters("multitap", multitap))}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    fontWeight: "400",
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {multitap} lvl
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                textAlign: "left",
                                paddingTop: "4px",
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "rgba(235, 235, 245, 0.4)",
                              }}
                            >
                              Max level reached
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      >
                        {multitap > 20 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            style={{
                              paddingTop: "32px",
                              paddingRight: "14px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                              stroke="#fff"
                              strokeOpacity="0.3"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : score + balance >= getBoosters("multitap", multitap) ? (
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              paddingTop: "38px",
                              paddingRight: "20px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="m1 1 5 5-5 5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  </button>
                  <button
                    onClick={() =>
                      energy_limit < 21 && openBoosters("energy_limit")
                    }
                    className={
                      energy_limit >= 21
                        ? `earn-transparent-btn_block`
                        : `earn-transparent-btn`
                    }
                    style={{
                      // marginTop: "186px",
                      marginTop: "96px",
                      opacity: energy_limit >= 21 ? "0.6" : "",
                    }}
                  >
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          paddingTop: "10px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            height: "70px",
                            backgroundColor:
                              "var(--palette-transparent-white-05)",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require("../../assets/images/battery.png")}
                            className="UY49"
                          />
                        </div>

                        <div
                          style={{
                            paddingTop: "12px",
                            paddingLeft: "86px",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              fontSize: "17px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                            }}
                          >
                            Energy Limit
                          </div>
                          {energy_limit < 21 ? (
                            score + balance >=
                            getBoosters("energy_limit", energy_limit) ? (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="penny-icon"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters("energy_limit", energy_limit)
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {energy_limit} lvl
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "var(--pixelcoin-orange)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    ></path>
                                  </svg>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters("energy_limit", energy_limit)
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {energy_limit} lvl
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                textAlign: "left",
                                paddingTop: "4px",
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "rgba(235, 235, 245, 0.4)",
                              }}
                            >
                              Max level reached
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      >
                        {energy_limit > 20 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            style={{
                              paddingTop: "32px",
                              paddingRight: "14px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                              stroke="#fff"
                              strokeOpacity="0.3"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : score + balance >=
                          getBoosters("energy_limit", energy_limit) ? (
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              paddingTop: "38px",
                              paddingRight: "20px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="m1 1 5 5-5 5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  </button>
                  <button
                    onClick={() =>
                      recharging_speed < 21 && openBoosters("recharging_speed")
                    }
                    className={
                      recharging_speed >= 21
                        ? `earn-transparent-btn_block`
                        : `earn-transparent-btn`
                    }
                    style={{
                      // marginTop: "276px",
                      marginTop: "186px",
                      opacity: recharging_speed >= 21 ? "0.6" : "",
                    }}
                  >
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          paddingTop: "10px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            height: "70px",
                            backgroundColor:
                              "var(--palette-transparent-white-05)",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require("../../assets/images/lightning.png")}
                            className="UY49"
                          />
                        </div>

                        <div
                          style={{
                            paddingTop: "12px",
                            paddingLeft: "86px",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              fontSize: "17px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                            }}
                          >
                            Recharging speed
                          </div>
                          {recharging_speed < 21 ? (
                            score + balance >=
                            getBoosters(
                              "recharging_speed",
                              recharging_speed
                            ) ? (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="penny-icon"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters(
                                      "recharging_speed",
                                      recharging_speed
                                    )
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {recharging_speed} lvl
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "var(--pixelcoin-orange)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    ></path>
                                  </svg>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters(
                                      "recharging_speed",
                                      recharging_speed
                                    )
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {recharging_speed} lvl
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                textAlign: "left",
                                paddingTop: "4px",
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "rgba(235, 235, 245, 0.4)",
                              }}
                            >
                              Max level reached
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      >
                        {recharging_speed > 20 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            style={{
                              paddingTop: "32px",
                              paddingRight: "14px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                              stroke="#fff"
                              strokeOpacity="0.3"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : score + balance >=
                          getBoosters("recharging_speed", recharging_speed) ? (
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              paddingTop: "38px",
                              paddingRight: "20px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="m1 1 5 5-5 5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  </button>
                  <button
                    onClick={() =>
                      ghost_bounty < 21 && openBoosters("ghost_bounty")
                    }
                    className={
                      ghost_bounty >= 21
                        ? `earn-transparent-btn_block`
                        : `earn-transparent-btn`
                    }
                    style={{
                      // marginTop: "366px",
                      marginTop: "276px",
                      opacity: ghost_bounty >= 21 ? "0.6" : "",
                    }}
                  >
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          paddingTop: "10px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            height: "70px",
                            backgroundColor:
                              "var(--palette-transparent-white-05)",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require("../../assets/images/dashing-away.png")}
                            className="UY49"
                          />
                        </div>

                        <div
                          style={{
                            paddingTop: "12px",
                            paddingLeft: "86px",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "left",
                              fontSize: "17px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                            }}
                          >
                            Ghost Bounty
                          </div>
                          {ghost_bounty < 21 ? (
                            score + balance >=
                            getBoosters("ghost_bounty", ghost_bounty) ? (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="penny-icon"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters("ghost_bounty", ghost_bounty)
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {ghost_bounty} lvl
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "var(--pixelcoin-orange)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    ></path>
                                  </svg>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters("ghost_bounty", ghost_bounty)
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {ghost_bounty} lvl
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                textAlign: "left",
                                paddingTop: "4px",
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "rgba(235, 235, 245, 0.4)",
                              }}
                            >
                              Max level reached
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      >
                        {ghost_bounty > 20 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            style={{
                              paddingTop: "32px",
                              paddingRight: "14px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                              stroke="#fff"
                              strokeOpacity="0.3"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : score + balance >=
                          getBoosters("ghost_bounty", ghost_bounty) ? (
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              paddingTop: "38px",
                              paddingRight: "20px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="m1 1 5 5-5 5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  </button>
                  <button
                    onClick={() =>
                      auto_tap_bot < 21 && openBoosters("auto_tap_bot")
                    }
                    className={
                      auto_tap_bot >= 21
                        ? `earn-transparent-btn_block`
                        : `earn-transparent-btn`
                    }
                    style={{
                      // marginTop: "546px",
                      // marginTop: "456px",
                      marginTop: "366px",
                      borderRadius: "0px 0px 4px 4px",
                      opacity: auto_tap_bot >= 21 ? "0.6" : "",
                    }}
                  >
                    <>
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          paddingTop: "10px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "70px",
                            height: "70px",
                            backgroundColor:
                              "var(--palette-transparent-white-05)",
                            borderRadius: "12px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={require("../../assets/images/robot.png")}
                            className="UY49"
                          />
                        </div>

                        <div
                          style={{
                            paddingTop: "12px",
                            paddingLeft: "86px",
                          }}
                        >
                          <div
                            style={{
                              display: "inline-flex",
                            }}
                          >
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: "17px",
                                fontWeight: "500",
                                color: "#d4d4d4",
                              }}
                            >
                              Auto Tap bot
                            </div>
                            <div
                              style={{
                                padding: "3px 10px",
                                // height: "16px",
                                // width: "28px",
                                background: "rgba(235, 235, 245, 0.05)",
                                borderRadius: "32px",
                                marginTop: "0px",
                                marginLeft: "8px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "#fff",
                              }}
                            >
                              <div
                                style={{
                                  marginBottom: "2px",
                                }}
                              >
                                {auto_tap_bot > 0 ? `on` : `off`}
                              </div>
                              <div
                                style={{
                                  height: "10px",
                                  width: "10px",
                                  background:
                                    auto_tap_bot > 0 ? "#00cf07" : "#ff0000",
                                  borderRadius: "50%",
                                  marginLeft: "8px",
                                }}
                              ></div>
                            </div>
                          </div>

                          {auto_tap_bot < 21 ? (
                            score + balance >=
                            getBoosters("auto_tap_bot", auto_tap_bot) ? (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                <div
                                  className="penny-icon"
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters("auto_tap_bot", auto_tap_bot)
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {auto_tap_bot} lvl
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  textAlign: "left",
                                  paddingTop: "4px",
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "var(--pixelcoin-orange)",
                                }}
                              >
                                <div
                                  style={{
                                    height: "16px",
                                    width: "16px",
                                    marginTop: "2px",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    ></path>
                                  </svg>
                                </div>
                                <div
                                  style={{
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(
                                    getBoosters("auto_tap_bot", auto_tap_bot)
                                  )}
                                </div>

                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    fontWeight: "400",
                                    color: "rgba(235, 235, 245, 0.4)",
                                  }}
                                >
                                  {auto_tap_bot} lvl
                                </div>
                              </div>
                            )
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                textAlign: "left",
                                paddingTop: "4px",
                                fontSize: "15px",
                                fontWeight: "400",
                                color: "rgba(235, 235, 245, 0.4)",
                              }}
                            >
                              Max level reached
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "0px",
                          right: "0px",
                        }}
                      >
                        {auto_tap_bot > 20 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 28 28"
                            fill="none"
                            style={{
                              paddingTop: "32px",
                              paddingRight: "14px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                              stroke="#fff"
                              strokeOpacity="0.3"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : score + balance >=
                          getBoosters("auto_tap_bot", auto_tap_bot) ? (
                          <svg
                            width="16px"
                            height="16px"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              paddingTop: "38px",
                              paddingRight: "20px",
                              color: "rgba(235, 235, 245, 0.3)",
                            }}
                          >
                            <path
                              d="m1 1 5 5-5 5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  </button>
                </div>
                <div className="IUG3">NFT Skins</div>
                <Swiper
                  slidesPerView={1.1}
                  initialSlide={0}
                  spaceBetween={"12px"}
                  onSlideChange={handleSlideChange}
                >
                  <SwiperSlide>
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "14px",
                        background: "rgba(255, 255, 255, 0.12)",
                        padding: "186px 0",
                        marginTop: "14px",
                        width: "100%",
                      }}
                    >
                      <button
                        onClick={() =>
                          mainSkin !== "basic" ? openDetails("basic") : ``
                        }
                        className={
                          mainSkin === "basic"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "6px",
                          borderRadius: "4px 4px 0px 0px",
                          opacity: mainSkin === "basic" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              backgroundColor:
                                "var(--palette-transparent-white-05)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require("../../assets/images/moneta-small.png")}
                              className="IKJH"
                            />
                          </div>
                          {mainSkin === "basic" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.basic}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.basic}
                              </div>

                              {skins.includes("basic") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : score + balance >= 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    0
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    0
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("basic") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "basic" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          score + balance >= 0 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                      <button
                        onClick={() =>
                          mainSkin !== "pizza" ? openDetails("pizza") : ``
                        }
                        className={
                          mainSkin === "pizza"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "96px",
                          opacity: mainSkin === "pizza" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              backgroundColor:
                                "var(--palette-transparent-white-05)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require("../../assets/images/skin-pizza.png")}
                              className="IKJH"
                            />
                          </div>
                          {mainSkin === "pizza" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.pizza}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.pizza}
                              </div>

                              {skins.includes("pizza") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : score + balance >= 10000 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    10,000
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    10,000
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("pizza") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "pizza" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          score + balance >= 10000 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                      <button
                        onClick={() =>
                          mainSkin !== "cookie" ? openDetails("cookie") : ``
                        }
                        className={
                          mainSkin === "cookie"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "186px",
                          opacity: mainSkin === "cookie" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              backgroundColor:
                                "var(--palette-transparent-white-05)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require("../../assets/images/skin-cookie.png")}
                              className="IKJH"
                            />
                          </div>
                          {mainSkin === "cookie" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.cookie}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.cookie}
                              </div>

                              {skins.includes("cookie") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : energy_limit >= 7 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    Free
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    lvl 7 energy limit
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("cookie") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "cookie" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          energy_limit >= 7 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                      <button
                        onClick={() =>
                          mainSkin !== "galleon" ? openDetails("galleon") : ``
                        }
                        className={
                          mainSkin === "galleon"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "276px",
                          opacity: mainSkin === "galleon" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              backgroundColor:
                                "var(--palette-transparent-white-05)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require("../../assets/images/skin-galleon.png")}
                              className="IKJH"
                            />
                          </div>
                          {mainSkin === "galleon" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.galleon}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.galleon}
                              </div>

                              {skins.includes("galleon") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : ghost_bounty >= 9 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    Free
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    lvl 9 ghost bounty
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("galleon") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "galleon" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          ghost_bounty >= 9 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "14px",
                        background: "rgba(255, 255, 255, 0.12)",
                        padding: "186px 0",
                        marginTop: "14px",
                        width: "100%",
                      }}
                    >
                      <button
                        onClick={() =>
                          mainSkin !== "fallout" ? openDetails("fallout") : ``
                        }
                        className={
                          mainSkin === "fallout"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "6px",
                          borderRadius: "4px 4px 0px 0px",
                          opacity: mainSkin === "fallout" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              backgroundColor:
                                "var(--palette-transparent-white-05)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require("../../assets/images/skin-fallout.png")}
                              className="IKJH"
                            />
                          </div>
                          {mainSkin === "fallout" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.fallout}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.fallout}
                              </div>

                              {skins.includes("fallout") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : recharging_speed >= 10 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    Free
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    lvl 10 recharging speed
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("fallout") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "fallout" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          recharging_speed >= 10 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                      <button
                        onClick={() =>
                          mainSkin !== "dragon" ? openDetails("dragon") : ``
                        }
                        className={
                          mainSkin === "dragon"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "96px",
                          opacity: mainSkin === "dragon" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            className="skin-dragon-bg"
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                          {mainSkin === "dragon" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.dragon}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.dragon}
                              </div>

                              {skins.includes("dragon") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : energy_limit >= 12 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    Free
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    lvl 12 energy limit
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("dragon") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "dragon" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          energy_limit >= 12 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                      <button
                        onClick={() =>
                          mainSkin !== "pixelcoin"
                            ? openDetails("pixelcoin")
                            : ``
                        }
                        className={
                          mainSkin === "pixelcoin"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "186px",
                          borderRadius: "0px 0px 4px 4px",
                          opacity: mainSkin === "pixelcoin" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            className="skin-pixelcoin-bg "
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require("../../assets/images/skin-pixelcoin.png")}
                              style={{
                                borderRadius: "50%",
                              }}
                              className="IKJH"
                            />
                          </div>
                          {mainSkin === "pixelcoin" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.pixelcoin}
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                {parsedData.boost.pixelcoin}
                              </div>

                              {skins.includes("pixelcoin") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : multitap >= 13 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    Free
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    lvl 13 multitap
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("pixelcoin") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "pixelcoin" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          multitap >= 13 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                      <button
                        onClick={() =>
                          mainSkin !== "telegram" ? openDetails("telegram") : ``
                        }
                        className={
                          mainSkin === "telegram"
                            ? `earn-transparent-btn_block`
                            : `earn-transparent-btn`
                        }
                        style={{
                          marginTop: "276px",
                          borderRadius: "0px 0px 4px 4px",
                          opacity: mainSkin === "telegram" ? "0.6" : "",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            left: "0px",
                            top: "0px",
                            paddingTop: "10px",
                            paddingLeft: "16px",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              width: "70px",
                              height: "70px",
                              backgroundColor:
                                "var(--palette-transparent-white-05)",
                              borderRadius: "12px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={require("../../assets/images/skin-telegram.png")}
                              className="IKJH"
                            />
                          </div>
                          {mainSkin === "telegram" ? (
                            <div
                              style={{
                                paddingTop: "24px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                Telegram skin
                              </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                paddingTop: "12px",
                                paddingLeft: "86px",
                              }}
                            >
                              <div
                                style={{
                                  textAlign: "left",
                                  fontSize: "17px",
                                  fontWeight: "500",
                                  color: "#d4d4d4",
                                }}
                              >
                                Telegram skin
                              </div>

                              {skins.includes("telegram") ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "400",
                                    color: "rgba(255, 255, 255, 0.5)",
                                  }}
                                >
                                  {parsedData.boost.you_own_it}
                                </div>
                              ) : frens.length >= 3 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "#fff",
                                  }}
                                >
                                  <div
                                    className="penny-icon"
                                    style={{
                                      height: "16px",
                                      width: "16px",
                                      marginTop: "2px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    Free
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    textAlign: "left",
                                    paddingTop: "4px",
                                    fontSize: "15px",
                                    fontWeight: "600",
                                    color: "var(--pixelcoin-orange)",
                                  }}
                                >
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                      marginTop: "2px",
                                    }}
                                  >
                                    <path
                                      d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                                      fill="#FEB803"
                                    />
                                  </svg>
                                  <div
                                    style={{
                                      marginLeft: "4px",
                                    }}
                                  >
                                    Invite 3 frens
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {skins.includes("telegram") ? (
                          <div
                            style={{
                              position: "absolute",
                              top: "0",
                              right: "0",
                              marginTop: "34px",
                              marginRight: "20px",
                              backgroundColor: "rgba(235, 235, 245, 0.05)",
                              borderRadius: "12px",
                              padding: "3px 10px",
                            }}
                          >
                            {mainSkin === "telegram" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "rgba(255, 255, 255, 0.3)",
                                }}
                              >
                                {parsedData.boost.enabled}
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff",
                                }}
                              >
                                {parsedData.boost.turn_on}
                              </div>
                            )}
                          </div>
                        ) : (
                          frens.length >= 3 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                              }}
                            >
                              <svg
                                width="16px"
                                height="16px"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  paddingTop: "38px",
                                  paddingRight: "20px",
                                  color: "rgba(235, 235, 245, 0.3)",
                                }}
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          )
                        )}
                      </button>
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    marginTop: "24px",
                    marginBottom: "25px",
                  }}
                >
                  {parsedData.boost.built_with}
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "4px",
                      marginRight: "4px",
                    }}
                  >
                    😆
                  </div>
                  <div>{parsedData.boost.by}</div>
                  <div
                    onClick={() =>
                      WebApp.openTelegramLink("https://t.me/join_community")
                    }
                    style={{
                      color: "var(--pixelcoin-orange)",
                      marginLeft: "4px",
                    }}
                  >
                    {parsedData.boost.community}
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
          <div
            className={`notification ${isVisible || loader ? "visible" : ""}`}
          >
            <div>
              <div
                style={{
                  width: "100%",
                  borderRadius: "12px",
                  padding: "8px",
                  background: "rgba(28, 28, 30, .9)",
                  alignItems: "center",
                  boxSizing: "border-box",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  transition: "background ease",
                  transitionDuration: "0.3s",
                }}
              >
                {loader ? (
                  <div className="boost-loader"></div>
                ) : (
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      color: "#fff",
                      marginRight: "10px",
                    }}
                  >
                    <path
                      d="m6 6 16 16M6 22 22 6"
                      stroke="currentColor"
                      strokeWidth="1.8"
                      strokeLinecap="round"
                    ></path>
                  </svg>
                )}
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                  }}
                >
                  {loader
                    ? parsedData.boost.loading
                    : parsedData.boost.not_enough_coins}
                </div>
              </div>
            </div>
          </div>
          <div className={`details ${isActive ? "visible" : ""}`}>
            <div className="backdrop-style">
              <button onClick={closeDetails} className="backdrop-close">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="30"
                    height="30"
                    rx="15"
                    fill="rgba(123, 123, 123, 0.24)"
                  ></rect>
                  <path
                    d="M9.638 20.377a.834.834 0 0 1-.241-.415 1.046 1.046 0 0 1 0-.49.87.87 0 0 1 .24-.406l4.043-4.05-4.042-4.043a.87.87 0 0 1-.241-.407 1.012 1.012 0 0 1 0-.482.835.835 0 0 1 .24-.423.883.883 0 0 1 .424-.24.947.947 0 0 1 .482.008c.16.038.298.113.415.224L15 13.703l4.043-4.05a.914.914 0 0 1 .888-.224c.16.038.301.116.423.232a.835.835 0 0 1 .24.424c.04.16.04.32 0 .48a.884.884 0 0 1-.24.416l-4.034 4.034 4.034 4.05a.834.834 0 0 1 .24.416 1.01 1.01 0 0 1 0 .481.834.834 0 0 1-.24.415.983.983 0 0 1-.423.241.979.979 0 0 1-.474 0 .862.862 0 0 1-.415-.232L15 16.335l-4.043 4.05a.862.862 0 0 1-.415.233c-.16.039-.32.039-.481 0a.883.883 0 0 1-.423-.24Z"
                    fill="#aaaaaa"
                  ></path>
                </svg>
              </button>
              <div>
                <img
                  src={
                    selectSkin === "pizza"
                      ? require("../../assets/images/skin-pizza.png")
                      : selectSkin === "cookie"
                      ? require("../../assets/images/skin-cookie.png")
                      : selectSkin === "galleon"
                      ? require("../../assets/images/skin-galleon.png")
                      : selectSkin === "fallout"
                      ? require("../../assets/images/skin-fallout.png")
                      : selectSkin === "dragon"
                      ? require("../../assets/images/skin-dragon.png")
                      : selectSkin === "pixelcoin"
                      ? require("../../assets/images/skin-pixelcoin.png")
                      : selectSkin === "telegram"
                      ? require("../../assets/images/skin-telegram.png")
                      : require("../../assets/images/moneta-small.png")
                  }
                  style={{
                    width: "180px",
                    height: "180px",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "35px",
                    marginBottom: "28px",
                    borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "28px",
                    fontWeight: "600",
                    color: "#fff",
                    marginBottom: "6px",
                  }}
                >
                  {selectSkin === "pizza"
                    ? parsedData.boost.pizza
                    : selectSkin === "cookie"
                    ? parsedData.boost.cookie
                    : selectSkin === "galleon"
                    ? parsedData.boost.galleon
                    : selectSkin === "fallout"
                    ? parsedData.boost.fallout
                    : selectSkin === "dragon"
                    ? parsedData.boost.dragon
                    : selectSkin === "pixelcoin"
                    ? parsedData.boost.pixelcoin
                    : selectSkin === "telegram"
                    ? `Telegram skin`
                    : parsedData.boost.basic}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.9)",
                    textAlign: "center",
                    padding: "0 16px",
                    marginBottom: "20px",
                  }}
                >
                  {selectSkin === "pizza" ? (
                    `I'll pay 10,000 pixelcoins for a pizza... like maybe one large so I have some left over for the next day.`
                  ) : selectSkin === "cookie" ? (
                    <>
                      You feel like making cookies.
                      <br />
                      But nobody wants to eat your cookies.
                    </>
                  ) : selectSkin === "galleon" ? (
                    <>
                      Cryptocurrency with a twist.
                      <br />
                      It's like finding gold in memes.
                    </>
                  ) : selectSkin === "fallout" ? (
                    <>
                      If we get out of this alive,
                      <br />
                      the Nuka-Cola's on me.
                    </>
                  ) : selectSkin === "dragon" ? (
                    `龙年快乐`
                  ) : selectSkin === "pixelcoin" ? (
                    <>
                      Where every click is an adventure.
                      <br />
                      It's like stumbling upon treasure in the digital realm.
                    </>
                  ) : selectSkin === "telegram" ? (
                    `Definitely the best messenger`
                  ) : (
                    `Not all treasure is silver and gold, mate.`
                  )}
                </div>
                {(selectSkin === "pizza" &&
                  !skins.includes("pizza") &&
                  score < 10000) ||
                (selectSkin === "cookie" &&
                  !skins.includes("cookie") &&
                  energy_limit < 7) ||
                (selectSkin === "galleon" &&
                  !skins.includes("galleon") &&
                  ghost_bounty < 9) ||
                (selectSkin === "fallout" &&
                  !skins.includes("fallout") &&
                  recharging_speed < 10) ||
                (selectSkin === "dragon" &&
                  !skins.includes("dragon") &&
                  energy_limit < 12) ||
                (selectSkin === "pixelcoin" &&
                  !skins.includes("pixelcoin") &&
                  multitap < 13) ||
                (selectSkin === "telegram" &&
                  !skins.includes("telegram") &&
                  frens.length < 3) ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "var(--pixelcoin-orange)",
                      textAlign: "center",
                      padding: "0 16px",
                      marginBottom: "30px",
                    }}
                  >
                    <div
                      className="lock-icon"
                      style={{
                        marginBottom: "2px",
                        marginRight: "4px",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginTop: "2px" }}
                      >
                        <path
                          d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                          fill="#FEB803"
                        ></path>
                      </svg>
                    </div>
                    {selectSkin === "pizza"
                      ? `10,000`
                      : selectSkin === "cookie"
                      ? `lvl 7 energy limit`
                      : selectSkin === "galleon"
                      ? `lvl 9 ghost bounty`
                      : selectSkin === "fallout"
                      ? `lvl 10 recharging speed`
                      : selectSkin === "dragon"
                      ? `lvl 12 energy limit`
                      : selectSkin === "pixelcoin"
                      ? `lvl 13 multitap`
                      : selectSkin === "telegram"
                      ? `Invite 3 frens`
                      : `0`}
                  </div>
                ) : (
                  <></>
                )}
                {(selectSkin === "pizza" &&
                  !skins.includes("pizza") &&
                  score + balance >= 10000) ||
                (selectSkin === "cookie" &&
                  !skins.includes("cookie") &&
                  energy_limit >= 7) ||
                (selectSkin === "galleon" &&
                  !skins.includes("galleon") &&
                  ghost_bounty >= 9) ||
                (selectSkin === "fallout" &&
                  !skins.includes("fallout") &&
                  recharging_speed >= 10) ||
                (selectSkin === "dragon" &&
                  !skins.includes("dragon") &&
                  energy_limit >= 12) ||
                (selectSkin === "pixelcoin" &&
                  !skins.includes("pixelcoin") &&
                  multitap >= 13) ||
                (selectSkin === "telegram" &&
                  !skins.includes("telegram") &&
                  frens.length >= 3) ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#fff",
                      textAlign: "center",
                      padding: "0 16px",
                      marginBottom: "30px",
                    }}
                  >
                    <div
                      className="penny-icon"
                      style={{
                        height: "16px",
                        width: "16px",
                        marginTop: "2px",
                        marginRight: "4px",
                      }}
                    ></div>
                    {selectSkin === "pizza"
                      ? `10,000`
                      : selectSkin === "cookie"
                      ? `Free`
                      : selectSkin === "galleon"
                      ? `Free`
                      : selectSkin === "fallout"
                      ? `Free`
                      : selectSkin === "dragon"
                      ? `Free`
                      : selectSkin === "pixelcoin"
                      ? `Free`
                      : selectSkin === "telegram"
                      ? `Free`
                      : `Free`}
                  </div>
                ) : (
                  <></>
                )}
                <button className="backdrop-confirm-transparent"></button>
                <div
                  onClick={() => changeSkin(selectSkin)}
                  className="backdrop-confirm"
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    {(selectSkin === "basic" && skins.includes("basic")) ||
                    (selectSkin === "pizza" && skins.includes("pizza")) ||
                    (selectSkin === "cookie" && skins.includes("cookie")) ||
                    (selectSkin === "galleon" && skins.includes("galleon")) ||
                    (selectSkin === "fallout" && skins.includes("fallout")) ||
                    (selectSkin === "dragon" && skins.includes("dragon")) ||
                    (selectSkin === "pixelcoin" &&
                      skins.includes("pixelcoin")) ||
                    (selectSkin === "telegram" && skins.includes("telegram"))
                      ? parsedData.boost.apply_skin
                      : parsedData.boost.get}
                  </div>
                </div>
              </div>
            </div>
            {/* }  */}
          </div>
          <div className={`details ${isBoosters ? "visible" : ""}`}>
            <div className="backdrop-style">
              <button onClick={closeBoosters} className="backdrop-close">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="30"
                    height="30"
                    rx="15"
                    fill="rgba(123, 123, 123, 0.24)"
                  ></rect>
                  <path
                    d="M9.638 20.377a.834.834 0 0 1-.241-.415 1.046 1.046 0 0 1 0-.49.87.87 0 0 1 .24-.406l4.043-4.05-4.042-4.043a.87.87 0 0 1-.241-.407 1.012 1.012 0 0 1 0-.482.835.835 0 0 1 .24-.423.883.883 0 0 1 .424-.24.947.947 0 0 1 .482.008c.16.038.298.113.415.224L15 13.703l4.043-4.05a.914.914 0 0 1 .888-.224c.16.038.301.116.423.232a.835.835 0 0 1 .24.424c.04.16.04.32 0 .48a.884.884 0 0 1-.24.416l-4.034 4.034 4.034 4.05a.834.834 0 0 1 .24.416 1.01 1.01 0 0 1 0 .481.834.834 0 0 1-.24.415.983.983 0 0 1-.423.241.979.979 0 0 1-.474 0 .862.862 0 0 1-.415-.232L15 16.335l-4.043 4.05a.862.862 0 0 1-.415.233c-.16.039-.32.039-.481 0a.883.883 0 0 1-.423-.24Z"
                    fill="#aaaaaa"
                  ></path>
                </svg>
              </button>
              <div>
                <img
                  src={
                    selectBoost === "turbo" ? (
                      require("../../assets/images/rocket.png")
                    ) : selectBoost === "energy" ? (
                      require("../../assets/images/lightning.png")
                    ) : selectBoost === "multitap" ? (
                      require("../../assets/images/index-pointing-up.png")
                    ) : selectBoost === "energy_limit" ? (
                      require("../../assets/images/battery.png")
                    ) : selectBoost === "recharging_speed" ? (
                      require("../../assets/images/lightning.png")
                    ) : selectBoost === "ghost_bounty" ? (
                      require("../../assets/images/dashing-away.png")
                    ) : selectBoost === "auto_tap_bot" ? (
                      require("../../assets/images/robot.png")
                    ) : (
                      <></>
                    )
                  }
                  style={{
                    width: "115px",
                    height: "115px",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "70px",
                    marginBottom: "28px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "28px",
                    fontWeight: "600",
                    color: "#fff",
                    marginBottom: "6px",
                  }}
                >
                  {selectBoost === "turbo"
                    ? `Turbo`
                    : selectBoost === "energy"
                    ? `Energy`
                    : selectBoost === "multitap"
                    ? `Multitap`
                    : selectBoost === "energy_limit"
                    ? `Energy limit`
                    : selectBoost === "recharging_speed"
                    ? `Recharging speed`
                    : selectBoost === "ghost_bounty"
                    ? `Ghost Bounty`
                    : selectBoost === "auto_tap_bot"
                    ? `Auto Tap bot`
                    : ``}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.9)",
                    textAlign: "center",
                    padding: "0 16px",
                    marginBottom: "15px",
                  }}
                >
                  {selectBoost === "turbo" ? (
                    <>
                      {parsedData.boost.turbo_description1}
                      <br />
                      {parsedData.boost.turbo_description2}
                    </>
                  ) : selectBoost === "energy" ? (
                    <>
                      {parsedData.boost.full_energy_description1}
                      <br />
                      {parsedData.boost.full_energy_description2}
                    </>
                  ) : selectBoost === "multitap" ? (
                    <>
                      Increase amount of Pixelcoin
                      <br />
                      You can earn per one tap
                    </>
                  ) : selectBoost === "energy_limit" ? (
                    <>
                      Increase your energy limit,
                      <br />
                      so you can mine more per session.
                    </>
                  ) : selectBoost === "recharging_speed" ? (
                    <>Increase the energy recharging speed.</>
                  ) : selectBoost === "ghost_bounty" ? (
                    <>
                      Get coins for ghosts.
                      <br />
                      Now the ghosts will become friendly.
                    </>
                  ) : selectBoost === "auto_tap_bot" ? (
                    <>
                      Hi sir! I will earn Pixelcoin for you,
                      <br />
                      when you are in game.
                    </>
                  ) : (
                    ``
                  )}
                </div>
                {selectBoost !== "turbo" && selectBoost !== "energy" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "15px",
                      fontWeight: "400",
                      color: "rgba(255, 255, 255, 0.9)",
                      textAlign: "center",
                      padding: "0 16px",
                      marginBottom: "15px",
                    }}
                  >
                    {selectBoost === "multitap" ? (
                      <>+1 per tap for each level.</>
                    ) : selectBoost === "energy_limit" ? (
                      <>+2 sec for each level.</>
                    ) : selectBoost === "recharging_speed" ? (
                      <>+2% / sec for each level.</>
                    ) : selectBoost === "ghost_bounty" ? (
                      <>+20 per ghost for each level.</>
                    ) : selectBoost === "auto_tap_bot" ? (
                      <>+3 / sec for each level.</>
                    ) : (
                      ``
                    )}
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    fontWeight: "600",
                    color:
                      score + balance >=
                        getBoosters(
                          `${selectBoost}`,
                          selectBoost === "multitap"
                            ? multitap
                            : selectBoost === "energy_limit"
                            ? energy_limit
                            : selectBoost === "recharging_speed"
                            ? recharging_speed
                            : selectBoost === "ghost_bounty"
                            ? ghost_bounty
                            : auto_tap_bot
                        ) ||
                      selectBoost === "turbo" ||
                      selectBoost === "energy"
                        ? "#fff"
                        : "var(--pixelcoin-orange)",
                    textAlign: "center",
                    padding: "0 16px",
                    marginBottom: "30px",
                  }}
                >
                  {score + balance >=
                    getBoosters(
                      `${selectBoost}`,
                      selectBoost === "multitap"
                        ? multitap
                        : selectBoost === "energy_limit"
                        ? energy_limit
                        : selectBoost === "recharging_speed"
                        ? recharging_speed
                        : selectBoost === "ghost_bounty"
                        ? ghost_bounty
                        : auto_tap_bot
                    ) ||
                  selectBoost === "turbo" ||
                  selectBoost === "energy" ? (
                    <div
                      className="penny-icon"
                      style={{
                        height: "16px",
                        width: "16px",
                        marginTop: "2px",
                        marginRight: "4px",
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        height: "16px",
                        width: "16px",
                        marginBottom: "6px",
                        marginRight: "4px",
                      }}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.69554 15H11.3038C12.2167 15 12.6663 14.5336 12.6663 13.5018V8.20848C12.6663 7.27562 12.2985 6.80212 11.5354 6.72438V4.9788C11.5354 2.29329 9.81184 1 7.99968 1C6.18751 1 4.46391 2.29329 4.46391 4.9788V6.74558C3.75539 6.85866 3.33301 7.32509 3.33301 8.20848V13.5018C3.33301 14.5336 3.78264 15 4.69554 15ZM5.77194 4.85159C5.77194 3.20495 6.78021 2.30035 7.99968 2.30035C9.21233 2.30035 10.2274 3.20495 10.2274 4.85159V6.71025L5.77194 6.71731V4.85159Z"
                          fill="#FEB803"
                        ></path>
                      </svg>
                    </div>
                  )}
                  {selectBoost === "turbo" || selectBoost === "energy"
                    ? parsedData.boost.free
                    : selectBoost === "multitap"
                    ? addCommas(getBoosters("multitap", multitap))
                    : selectBoost === "energy_limit"
                    ? addCommas(getBoosters("energy_limit", energy_limit))
                    : selectBoost === "recharging_speed"
                    ? addCommas(
                        getBoosters("recharging_speed", recharging_speed)
                      )
                    : selectBoost === "ghost_bounty"
                    ? addCommas(getBoosters("ghost_bounty", ghost_bounty))
                    : addCommas(getBoosters("auto_tap_bot", auto_tap_bot))}
                  {selectBoost !== "turbo" && selectBoost !== "energy" ? (
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                        marginTop: "2px",
                        marginLeft: "4px",
                        color: "rgba(235, 235, 245, 0.4)",
                      }}
                    >
                      / Level{" "}
                      {selectBoost === "multitap"
                        ? multitap
                        : selectBoost === "energy_limit"
                        ? energy_limit
                        : selectBoost === "recharging_speed"
                        ? recharging_speed
                        : selectBoost === "ghost_bounty"
                        ? ghost_bounty
                        : auto_tap_bot}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <button className="backdrop-confirm-transparent"></button>
                <div
                  onClick={() =>
                    selectBoost === "turbo" || selectBoost === "energy"
                      ? navigateGame()
                      : updateBoost(selectBoost)
                  }
                  className="backdrop-confirm"
                >
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    {parsedData.boost.get}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
