import { useState } from "react";
import getColor from "../../utils/getColor";

export default function LeaguePhoto({ url_photo, first_name, searchType }) {
  const [hasError, setHasError] = useState(false);
  return (
    <>
      {url_photo !== "" && !hasError ? (
        <img
          src={url_photo}
          className={`league_skeleton__circle league_skeleton__circle_${searchType}`}
          onError={() => {
            setHasError(true);
          }}
        />
      ) : (
        searchType === "miners" && (
          <div
            className="league_skeleton__circle league_skeleton__circle_miners"
            style={{
              background: getColor(first_name.substring(0, 1)),
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "600",
                color: "#fff",
              }}
            >
              {first_name.substring(0, 2).toUpperCase()}
            </div>
          </div>
        )
      )}
    </>
  );
}
