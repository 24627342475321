import React from "react";
import Lottie from "lottie-react";
import Disco from "../../assets/animation/Disco.json";
import Duck_think_out from "../../assets/animation/Duck_think_out.json";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import UsersSkeleton from "../../components/UsersSkeleton";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import axios from "axios";
import getSquadLeague from "../../utils/getSquadLeague";
import getLeague from "../../utils/getLeague";
import abbreNumbers from "../../utils/abbreNumbers";
import addCommas from "../../utils/addCommas";

function sendMessageToUser(userId, parsedData) {
  // Ваш API ключ и URL для обращения к API Telegram
  const apiKey = "6967469657:AAFW2-pv_1HXN1NQ1jflalr07MHz_Mm7QJU";
  const telegramApiUrl = `https://api.telegram.org/bot${apiKey}/sendMessage`;

  // Создание объекта для отправки данных
  const formData = new FormData();
  formData.append("chat_id", userId);
  formData.append(
    "text",
    `${parsedData.create_squad.create_your_squad}\n\n${parsedData.create_squad.send_link}\n\n${parsedData.create_squad.examples}\nhttps://t.me/durоv\nhttps://www.instagram.com/durov/\nhttps://twitter.com/elоnmusk\nhttps://www.facebоok.com/zuck/ ${parsedData.create_squad.and_etc}`
  );

  // Опции запроса
  const requestOptions = {
    method: "POST",
    body: formData,
  };

  // Отправка запроса к API Telegram
  fetch(telegramApiUrl, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      WebApp.close();
    })
    .catch((error) => {
      console.error("Error sending message:", error);
      // Здесь вы можете добавить обработку ошибок при отправке сообщения
    });
}

export default function Squads({ userId }) {
  const navigate = useNavigate();

  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  const [userLoaded, setUserLoaded] = useState(false);
  // useEffect(() => {
  //   setTimeout(() => {
  //     // setUserLoaded(true);
  //   }, 5000);
  // }, []);

  const handleButtonClick = () => {
    setPlayAnimation(true);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const [showPage, setShowPage] = useState(false);

  setTimeout(() => {
    setShowPage(true);
  }, 500); // Задержка перед появлением страницы

  const [isVisible, setIsVisible] = useState(true);

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [starFirst, setStarFirst] = useState(false);

  setTimeout(() => {
    setStarFirst(true);
  }, 800);

  const [starSecond, setStarSecond] = useState(false);

  setTimeout(() => {
    setStarSecond(true);
  }, 1200);

  const [starThird, setStarThird] = useState(false);

  setTimeout(() => {
    setStarThird(true);
  }, 1200);

  const [squads, setSquads] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios.get(`https://pixelcoin.site:433/squads/`).then((response) => {
          setSquads(response.data);
          setShowPage(true);
        });
      } catch (error) {
        console.error("Error fetching squad:", error);
      }
    };

    fetchData();
  }, []);

  // const [squad1, setSquad1] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://pixelcoin.site:433/squads/1`
  //       );
  //       setSquad1(response.data);
  //       setShowPage(true)
  //     } catch (error) {
  //       console.error("Error fetching squad:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const league1 = getSquadLeague(squad1.score)

  // const [squad2, setSquad2] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://pixelcoin.site:433/squads/2`
  //       );
  //       setSquad2(response.data);
  //       setShowPage(true)
  //     } catch (error) {
  //       console.error("Error fetching squad:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const league2 = getSquadLeague(squad2.score)

  // const [squad3, setSquad3] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://pixelcoin.site:433/squads/3`
  //       );
  //       setSquad3(response.data);
  //       setShowPage(true)
  //     } catch (error) {
  //       console.error("Error fetching squad:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const league3 = getSquadLeague(squad3.score)

  // const [squad4, setSquad4] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://pixelcoin.site:433/squads/4`
  //       );
  //       setSquad4(response.data);
  //       setShowPage(true)
  //     } catch (error) {
  //       console.error("Error fetching squad:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const league4 = getSquadLeague(squad4.score)

  // const [squad5, setSquad5] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://pixelcoin.site:433/squads/5`
  //       );
  //       setSquad5(response.data);
  //       setShowPage(true)
  //     } catch (error) {
  //       console.error("Error fetching squad:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const league5 = getSquadLeague(squad5.score)

  // const [squad6, setSquad6] = useState([]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `https://pixelcoin.site:433/squads/6`
  //       );
  //       setSquad6(response.data);
  //       setShowPage(true)
  //     } catch (error) {
  //       console.error("Error fetching squad:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // const league6 = getSquadLeague(squad6.score)

  return (
    <>
      <BackButton onClick={() => navigate("/")} />
      <CSSTransition
        in={showPage}
        timeout={250}
        className="squads-animation"
        unmountOnExit
      >
        <div>
          <div className={`page scrolling`}>
            <div className="background-influencer"></div>

            <div className="container position-relative">
              <div className="page__content">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <CSSTransition
                    in={starFirst}
                    timeout={800}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_squads-1 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starSecond}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_squads-2 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starThird}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_squads-3 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                </div>
                <div className="sGES">
                  <div onClick={handleButtonClick}>
                    <CSSTransition
                      in={isVisible}
                      timeout={100}
                      className={`slide-down-enter`}
                      unmountOnExit
                    >
                      <div>
                        <div className="JGew disco-big-icon"></div>
                      </div>
                    </CSSTransition>
                  </div>
                </div>
                <div className="IfNG">{parsedData.squads.title}</div>
                <div className="nw2j">
                  {parsedData.squads.these_squads_receruiting_now}
                  <br />
                  {parsedData.squads.do_you_wanna_join}
                </div>
                <div
                  // onClick={() =>
                  //   WebApp.openTelegramLink("https://t.me/thepixelcoin/4")
                  // }
                  onClick={() => sendMessageToUser(userId, parsedData)}
                  className="JUcV"
                >
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">
                        {parsedData.squads.create_your_squad}
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="squads_skeleton">
                  {squads.map(
                    (user, index) =>
                      user.name !== "" && (
                        <li
                          onClick={() => {
                            navigate(`/squad?asset=${user.user_id}`);
                          }}
                          key={index}
                          className="squads_skeleton__wrapper"
                        >
                          <img
                            src={user.url_photo}
                            className="squads_skeleton__circle"
                          />
                          <div className="squads_element__box1">
                            {user.name}
                          </div>
                          <div className="squads_element__box2">
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div
                                className={`fren-cup-position icon-cup__${getSquadLeague(
                                  user.score
                                )}`}
                              ></div>
                              <div
                                style={{
                                  color: "rgb(212, 212, 212)",
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  marginLeft: "3px",
                                  marginTop: "4px",
                                }}
                              >
                                {getSquadLeague(user.score)}
                              </div>
                            </div>
                          </div>
                          <div className="squads_element__box3">
                            <div
                              style={{
                                marginTop: "2px",
                                color: "rgb(112, 117, 121)",
                              }}
                            >
                              <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="m1 1 5 5-5 5"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></path>
                              </svg>
                            </div>
                          </div>
                        </li>
                      )
                  )}
                </ul>
                {/* <div className="squads-transparent-div squads-transparent-div_second">
                  <button
                    onClick={() => {
                      navigate(`/squad?asset=1`);
                    }}
                    className={`squads-transparent-btn squads-transparent-btn_second`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <img
                        src={`${squad1.url_photo}`}
                        style={{
                          position: "absolute",
                          width: "48px",
                          height: "48px",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1px",
                        }}
                      />
                      <div
                        style={{
                          // paddingTop: "12px",
                          paddingLeft: "60px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#fff",
                            marginTop: "4px",
                            textAlign: "left",
                          }}
                        >
                          {squad1.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              marginTop: "1px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundColor: "transparent",
                            }}
                            className={`icon-cup__${league1}`}
                          ></div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                              marginLeft: "4px",
                            }}
                          >
                            {league1 === 'bronze' ? parsedData.squads.bronze : league1 === 'silver' ? parsedData.squads.silver : league1 === 'gold' ? parsedData.squads.gold : league1 === 'platinum' ? parsedData.squads.platinum : parsedData.squads.diamond}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        paddingTop: "28px",
                        paddingRight: "14px",
                        color: "rgba(235, 235, 245, 0.3)",
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/squad?asset=2");
                    }}
                    className={`squads-transparent-btn squads-transparent-btn_third`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <img
                        src={`${squad2.url_photo}`}
                        style={{
                          position: "absolute",
                          width: "48px",
                          height: "48px",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1px",
                        }}
                      />
                      <div
                        style={{
                          // paddingTop: "12px",
                          paddingLeft: "60px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#fff",
                            marginTop: "4px",
                            textAlign: "left",
                          }}
                        >
                          {squad2.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              marginTop: "1px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundColor: "transparent",
                            }}
                            className={`icon-cup__${league2}`}
                          ></div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                              marginLeft: "4px",
                            }}
                          >
                            {league2 === 'bronze' ? parsedData.squads.bronze : league2 === 'silver' ? parsedData.squads.silver : league2 === 'gold' ? parsedData.squads.gold : league2 === 'platinum' ? parsedData.squads.platinum : parsedData.squads.diamond}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        paddingTop: "28px",
                        paddingRight: "14px",
                        color: "rgba(235, 235, 245, 0.3)",
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/squad?asset=3");
                    }}
                    className={`squads-transparent-btn squads-transparent-btn_fourth`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <img
                        src={`${squad3.url_photo}`}
                        style={{
                          position: "absolute",
                          width: "48px",
                          height: "48px",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1px",
                        }}
                      />
                      <div
                        style={{
                          // paddingTop: "12px",
                          paddingLeft: "60px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#fff",
                            marginTop: "4px",
                            textAlign: "left",
                          }}
                        >
                          {squad3.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              marginTop: "1px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundColor: "transparent",
                            }}
                            className={`icon-cup__${league3}`}
                          ></div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                              marginLeft: "4px",
                            }}
                          >
                            {league3 === 'bronze' ? parsedData.squads.bronze : league3 === 'silver' ? parsedData.squads.silver : league3 === 'gold' ? parsedData.squads.gold : league3 === 'platinum' ? parsedData.squads.platinum : parsedData.squads.diamond}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        paddingTop: "28px",
                        paddingRight: "14px",
                        color: "rgba(235, 235, 245, 0.3)",
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/squad?asset=4");
                    }}
                    className={`squads-transparent-btn squads-transparent-btn_fifth`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <img
                        src={`${squad4.url_photo}`}
                        style={{
                          position: "absolute",
                          width: "48px",
                          height: "48px",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1px",
                        }}
                      />
                      <div
                        style={{
                          // paddingTop: "12px",
                          paddingLeft: "60px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#fff",
                            marginTop: "4px",
                            textAlign: "left",
                          }}
                        >
                          {squad4.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              marginTop: "1px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundColor: "transparent",
                            }}
                            className={`icon-cup__${league4}`}
                          ></div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                              marginLeft: "4px",
                            }}
                          >
                            {league4 === 'bronze' ? parsedData.squads.bronze : league4 === 'silver' ? parsedData.squads.silver : league4 === 'gold' ? parsedData.squads.gold : league4 === 'platinum' ? parsedData.squads.platinum : parsedData.squads.diamond}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        paddingTop: "28px",
                        paddingRight: "14px",
                        color: "rgba(235, 235, 245, 0.3)",
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/squad?asset=5");
                    }}
                    className={`squads-transparent-btn squads-transparent-btn_sixth`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <img
                        src={`${squad5.url_photo}`}
                        style={{
                          position: "absolute",
                          width: "48px",
                          height: "48px",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1px",
                        }}
                      />
                      <div
                        style={{
                          // paddingTop: "12px",
                          paddingLeft: "60px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#fff",
                            marginTop: "4px",
                            textAlign: "left",
                          }}
                        >
                          {squad5.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              marginTop: "1px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundColor: "transparent",
                            }}
                            className={`icon-cup__${league5}`}
                          ></div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                              marginLeft: "4px",
                            }}
                          >
                            {league5 === 'bronze' ? parsedData.squads.bronze : league5 === 'silver' ? parsedData.squads.silver : league5 === 'gold' ? parsedData.squads.gold : league5 === 'platinum' ? parsedData.squads.platinum : parsedData.squads.diamond}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        paddingTop: "28px",
                        paddingRight: "14px",
                        color: "rgba(235, 235, 245, 0.3)",
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      navigate("/squad?asset=6");
                    }}
                    className={`squads-transparent-btn squads-transparent-btn_seventh`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <img
                        src={`${squad6.url_photo}`}
                        style={{
                          position: "absolute",
                          width: "48px",
                          height: "48px",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "1px",
                        }}
                      />
                      <div
                        style={{
                          // paddingTop: "12px",
                          paddingLeft: "60px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "17px",
                            fontWeight: "600",
                            color: "#fff",
                            marginTop: "4px",
                            textAlign: "left",
                          }}
                        >
                          {squad6.name}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            marginTop: "2px",
                          }}
                        >
                          <div
                            style={{
                              width: "16px",
                              height: "16px",
                              marginTop: "1px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundColor: "transparent",
                            }}
                            className={`icon-cup__${league6}`}
                          ></div>
                          <div
                            style={{
                              fontSize: "14px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                              marginLeft: "4px",
                            }}
                          >
                            {league6 === 'bronze' ? parsedData.squads.bronze : league6 === 'silver' ? parsedData.squads.silver : league6 === 'gold' ? parsedData.squads.gold : league6 === 'platinum' ? parsedData.squads.platinum : parsedData.squads.diamond}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        paddingTop: "28px",
                        paddingRight: "14px",
                        color: "rgba(235, 235, 245, 0.3)",
                      }}
                    >
                      <svg
                        width="14px"
                        height="14px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
