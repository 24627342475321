import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import Fren from "./pages/Fren";
import Earn from "./pages/Earn";
import About from "./pages/About";
import League from "./pages/League";
import PartyKings from "./pages/PartyKings";
import Squads from "./pages/Squads";
import Stats from "./pages/Stats";
import Referral from "./pages/Referral";
import QrCode from "./pages/QrCode";
import WebApp from "@twa-dev/sdk";
import Loader from "./components/Loader";
import { langpack } from "./utils/langpack";
import Compete from "./pages/Compete";
import Squad from "./pages/Squad";
import JoinSquad from "./pages/JoinSquad";
import LastAction from "./components/LastAction";
import axios from "axios";
import Boost from "./pages/Boost";
import convertLeagueBonus from "./utils/convertLeagueBonus";
import getLeague from "./utils/getLeague";
import getLeagueBonus from "./utils/getLeagueBonus";
import { useNavigate } from "react-router-dom";
import Trade from "./pages/Trade";
import Send from "./pages/Send";
import Complete from "./pages/Complete";
import ReceiverSearch from "./pages/ReceiverSearch";
import getEnergyLimit from "./utils/getEnergyLimit";
import getAutoTapBot from "./utils/getAutoTapBot";

// const allowed_platforms = ["ios", "android", "android_x", "unknown"];
const allowed_platforms = ["ios", "android", "android_x"];

function isPlatformAllowed(platform) {
  return allowed_platforms.includes(platform);
}

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    WebApp.expand();
    WebApp.setHeaderColor("#000");
    WebApp.setBackgroundColor("#000");
  }, []);

  const [userId, setUserId] = useState(WebApp.initDataUnsafe.user.id);
  // const [userId, setUserId] = useState(5668476910);

  const [loading, setLoading] = useState(true);

  const [score, setScore] = useState(0);

  const fetchScore = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/score`)
        .then((response) => {
          setScore(response.data.score);
          setOldScore(response.data.score);
          setTimeout(() => {
            setLoading(false);
          }, 350);
        });
    } catch (error) {
      console.error("Error fetching score:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchScore();
  }, []);

  const updateScore = async (newScore) => {
    setScore(newScore);
    setLastUpdateTime(Date.now());
  };

  const [oldScore, setOldScore] = useState(score);

  const updateOldScore = async (newOldScore) => {
    setOldScore(newOldScore);
  };

  const [rocketNum, setRocketNum] = useState(0);

  const fetchRocketNum = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/rocket_num`)
        .then((response) => {
          setRocketNum(response.data.rocket_num);
        });
    } catch (error) {
      console.error("Error fetching rocket_num:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchRocketNum();
  }, []);

  const updateRocketNum = async (newRocketNum) => {
    try {
      setRocketNum(newRocketNum);
    } catch (error) {
      console.error("Ошибка при обновлении", error);
    }
  };

  const [touch, setTouch] = useState(0);

  const fetchTouch = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/touch`)
        .then((response) => {
          setTouch(response.data.touch);
        });
    } catch (error) {
      console.error("Error fetching touch:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchTouch();
  }, []);

  const updateTouch = async (newTouch) => {
    try {
      setTouch(newTouch);
    } catch (error) {
      console.error("Ошибка при обновлении", error);
    }
  };

  const [session, setSession] = useState(0);

  const updateSession = async (newSession) => {
    try {
      setSession(newSession);
    } catch (error) {
      console.log("Error session", error);
    }
  };

  const [squadScore, setSquadScore] = useState(0);

  const fetchSquadScore = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/squad_score`)
        .then((response) => {
          setSquadScore(response.data.squad_score);
        });
    } catch (error) {
      console.error("Error fetching squad_score:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchSquadScore();
  }, []);

  const updateSquadScore = async (newSquadScore) => {
    try {
      setSquadScore(newSquadScore);
    } catch (error) {
      console.log("Error squad score", error);
    }
  };

  const [inviter, setInviter] = useState(0);

  const fetchInviter = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/inviter`)
        .then((response) => {
          setInviter(response.data.inviter);
        });
    } catch (error) {
      console.error("Error fetching inviter:", error);
    }
  };

  useEffect(() => {
    fetchInviter();
  }, []);

  const referralSystem = async () => {
    let leagueBonus = 0;
    let bonuses = 0;
    let inviterBalance = 0;
    let frensBalance = 0;
    axios
      .get(`https://pixelcoin.site:433/users/${userId}/league_bonus`)
      .then((response) => {
        leagueBonus = response.data.league_bonus;
        bonuses = getLeagueBonus(leagueBonus, getLeague(score));
      })
      .then(() => {
        if (
          inviter !== 0 &&
          convertLeagueBonus(getLeague(score)) > leagueBonus
        ) {
          axios
            .get(`https://pixelcoin.site:433/users/${inviter}/balance`)
            .then((response1) => {
              inviterBalance = response1.data.balance + bonuses.bonus;
              axios.put(`https://pixelcoin.site:433/users/${inviter}/balance`, {
                balance: inviterBalance,
              });
            })
            .catch((error) => {
              console.error("Ошибка при обновлении баланса:", error);
            });
          axios
            .get(`https://pixelcoin.site:433/users/${inviter}/frens_balance`)
            .then((response1) => {
              frensBalance = response1.data.frens_balance + bonuses.bonus;
              axios.put(
                `https://pixelcoin.site:433/users/${inviter}/frens_balance`,
                {
                  frens_balance: frensBalance,
                }
              );
            })
            .catch((error) => {
              console.error("Ошибка при обновлении баланса:", error);
            });

          axios
            .put(`https://pixelcoin.site:433/users/${userId}/league_bonus`, {
              league_bonus: bonuses.newLeagueBonus,
            })
            .catch((error) => {
              console.error("Ошибка при обновлении баланса:", error);
            });
        }
      });
  };

  const [lastUpdateTime, setLastUpdateTime] = useState(null);

  const [confirm, setConfirm] = useState(null);

  useEffect(() => {
    if (lastUpdateTime !== null && !confirm) {
      WebApp.enableClosingConfirmation();
      setConfirm(true);
    }
    if (lastUpdateTime === null && confirm) {
      WebApp.disableClosingConfirmation();
      setConfirm(false);
    }
  }, [lastUpdateTime]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (lastUpdateTime !== null && Date.now() - lastUpdateTime >= 5000) {
        setLastUpdateTime(null);
        try {
          axios
            .put(`https://pixelcoin.site:433/users/${userId}/score`, {
              score: score,
            })
            .then((response) => {
              setScore(response.data.score);
            });
        } catch (error) {
          console.error("Ошибка при обновлении баланса:", error);
        }
        try {
          axios
            .put(`https://pixelcoin.site:433/users/${userId}/touch`, {
              touch: touch,
            })
            .then((response) => {
              setTouch(response.data.touch);
            });
        } catch (error) {
          console.error("Ошибка при обновлении баланса:", error);
        }
        try {
          axios
            .put(`https://pixelcoin.site:433/users/${userId}/rocket_num`, {
              rocket_num: rocketNum,
            })
            .then((response) => {
              setRocketNum(response.data.rocket_num);
            });
        } catch (error) {
          console.error("Ошибка при обновлении баланса:", error);
        }

        try {
          axios
            .get(`https://pixelcoin.site:433/users/${userId}/squad`)
            .then((response) => {
              if (response.data.squad !== "") {
                axios
                  .put(`https://pixelcoin.site:433/users/${userId}/squad_score`, {
                    squad_score: squadScore,
                  })
                  .then((response) => {
                    setSquadScore(response.data.squad_score);
                  });
              } else {
                setSquadScore(0);
              }
            });
        } catch (error) {
          console.error("Ошибка при обновлении баланса:", error);
        }

        try {
          axios
            .get(`https://pixelcoin.site:433/users/${userId}/squad`)
            .then((response) => {
              if (response.data.squad !== "") {
                axios
                  .get(
                    `https://pixelcoin.site:433/squads/${response.data.squad}/score`
                  )
                  .then((response1) => {
                    axios
                      .put(
                        `https://pixelcoin.site:433/squads/${response.data.squad}/score`,
                        {
                          score: response1.data.score + session,
                        }
                      )
                      .then(() => {
                        setSession(0);
                      });
                  });
              } else {
                setSession(0);
              }
            });
        } catch (error) {
          console.log("error session squad");
        }
        referralSystem();
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [lastUpdateTime]);

  const [balance, setBalance] = useState(0);

  const fetchBalance = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/balance`)
        .then((response) => {
          setBalance(response.data.balance);
        });
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };

  // Функция для обновления баланса
  const updateBalance = async (newBalance) => {
    try {
      axios
        .put(`https://pixelcoin.site:433/users/${userId}/balance`, {
          balance: newBalance,
        })
        .then((response) => {
          setBalance(response.data.balance);
        });
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  const updateStateBalance = () => {
    setBalance((preBalance) => preBalance + getAutoTapBot(auto_tap_bot));
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchBalance();
  }, []);

  const [oldBalance, setOldBalance] = useState(balance);

  const updateOldBalance = async (newOldBalance) => {
    setOldBalance(newOldBalance);
  };

  const [onboarding, setOnboarding] = useState([]);

  const fetchOnboarding = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/onboarding`)
        .then((response) => {
          setOnboarding(response.data.onboarding);
        });
    } catch (error) {
      console.error("Error fetching onboarding:", error);
    }
  };

  const updateOnboarding = async (newOnboarding) => {
    try {
      axios
        .put(`https://pixelcoin.site:433/users/${userId}/onboarding`, {
          onboarding: [...onboarding, newOnboarding],
        })
        .then((response) => {
          setOnboarding(response.data.onboarding);
        });
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchOnboarding();
  }, []);

  const [mainSkin, setMainSkin] = useState("");

  const fetchMainSkin = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/mainSkin`)
        .then((response) => {
          setMainSkin(response.data.mainSkin);
        });
    } catch (error) {
      console.error("Error fetching mainSkin:", error);
    }
  };

  // Функция для обновления баланса
  const updateMainSkin = async (newMainSkin) => {
    try {
      axios
        .put(`https://pixelcoin.site:433/users/${userId}/mainSkin`, {
          mainSkin: newMainSkin,
        })
        .then((response) => {
          setMainSkin(response.data.mainSkin);
          navigate("/");
        });
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchMainSkin();
  }, []);

  const [skins, setSkins] = useState([]);

  const fetchSkins = async () => {
    try {
      axios
        .get(`https://pixelcoin.site:433/users/${userId}/skins`)
        .then((response) => {
          setSkins(response.data.skins);
          try {
            setMultitap(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("multitap"))[0]
                  .replace("multitap", "")
              )
            );
          } catch {
            setMultitap(0);
          }
          try {
            setEnergyLimit(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("energy_limit"))[0]
                  .replace("energy_limit", "")
              )
            );
          } catch {
            setEnergyLimit(0);
          }
          try {
            setRechargingSpeed(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("recharging_speed"))[0]
                  .replace("recharging_speed", "")
              )
            );
          } catch {
            setRechargingSpeed(0);
          }
          try {
            setGhostBounty(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("ghost_bounty"))[0]
                  .replace("ghost_bounty", "")
              )
            );
          } catch {
            setGhostBounty(0);
          }
          try {
            setAutoTapBot(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("auto_tap_bot"))[0]
                  .replace("auto_tap_bot", "")
              )
            );
          } catch {
            setAutoTapBot(0);
          }
        });
    } catch (error) {
      console.error("Error fetching skins:", error);
    }
  };

  const updateSkins = async (newSkins) => {
    try {
      axios
        .put(`https://pixelcoin.site:433/users/${userId}/skins`, {
          skins: [...skins, newSkins],
        })
        .then((response) => {
          setSkins(response.data.skins);
          try {
            setMultitap(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("multitap"))[0]
                  .replace("multitap", "")
              )
            );
          } catch {
            setMultitap(0);
          }
          try {
            setEnergyLimit(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("energy_limit"))[0]
                  .replace("energy_limit", "")
              )
            );
          } catch {
            setEnergyLimit(0);
          }
          try {
            setRechargingSpeed(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("recharging_speed"))[0]
                  .replace("recharging_speed", "")
              )
            );
          } catch {
            setRechargingSpeed(0);
          }
          try {
            setGhostBounty(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("ghost_bounty"))[0]
                  .replace("ghost_bounty", "")
              )
            );
          } catch {
            setGhostBounty(0);
          }
          try {
            setAutoTapBot(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("auto_tap_bot"))[0]
                  .replace("auto_tap_bot", "")
              )
            );
          } catch {
            setAutoTapBot(0);
          }
        });
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  const updateAllSkins = async (newSkins) => {
    try {
      axios
        .put(`https://pixelcoin.site:433/users/${userId}/skins`, {
          skins: newSkins,
        })
        .then((response) => {
          setSkins(response.data.skins);
          try {
            setMultitap(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("multitap"))[0]
                  .replace("multitap", "")
              )
            );
          } catch {
            setMultitap(0);
          }
          try {
            setEnergyLimit(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("energy_limit"))[0]
                  .replace("energy_limit", "")
              )
            );
          } catch {
            setEnergyLimit(0);
          }
          try {
            setRechargingSpeed(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("recharging_speed"))[0]
                  .replace("recharging_speed", "")
              )
            );
          } catch {
            setRechargingSpeed(0);
          }
          try {
            setGhostBounty(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("ghost_bounty"))[0]
                  .replace("ghost_bounty", "")
              )
            );
          } catch {
            setGhostBounty(0);
          }
          try {
            setAutoTapBot(
              parseInt(
                response.data.skins
                  .filter((item) => item.includes("auto_tap_bot"))[0]
                  .replace("auto_tap_bot", "")
              )
            );
          } catch {
            setAutoTapBot(0);
          }
        });
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchSkins();
  }, []);

  const [click, setClick] = useState(false);

  // Функция для обновления баланса
  const updateClick = async (newClick) => {
    try {
      setClick(newClick);
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  const [rocket, setRocket] = useState(false);

  // Функция для обновления баланса
  const updateRocket = async (newRocket) => {
    try {
      // Здесь можно добавить логику обновления баланса в базе данных, если это необходимо
      setRocket(newRocket);
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  const [secondsClick, setSecondsClick] = useState(15);

  const [isActiveClick, setIsActiveClick] = useState(false);

  useEffect(() => {
    let intervalIdClick;

    if (isActiveClick) {
      intervalIdClick = setInterval(() => {
        setSecondsClick((prevSecondsClick) => prevSecondsClick - 1);
      }, 1000);
    }

    return () => clearInterval(intervalIdClick);
  }, [isActiveClick]);

  useEffect(() => {
    if (secondsClick === 0) {
      setIsActiveClick(false);
      // if (skins.includes("cookie")) {
      //   setSecondsClick(30);
      // } else {
      setSecondsClick(15 + getEnergyLimit(energy_limit));
      // }
    }
  }, [secondsClick]);

  useEffect(() => {
    if (!isActiveClick) {
      // if (skins.includes("cookie")) {
      //   setSecondsClick(30);
      // } else {
      setSecondsClick(15 + getEnergyLimit(energy_limit));
      // }
    }
  }, [isActiveClick]);

  const updateIsActiveClick = async (newIsActiveClick) => {
    try {
      // Здесь можно добавить логику обновления баланса в базе данных, если это необходимо
      setIsActiveClick(newIsActiveClick);
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  const [secondsRocket, setSecondsRocket] = useState(20);
  const [isActiveRocket, setIsActiveRocket] = useState(false);

  useEffect(() => {
    let intervalIdRocket;

    if (isActiveRocket) {
      intervalIdRocket = setInterval(() => {
        setSecondsRocket((prevSecondsRocket) => prevSecondsRocket - 1);
      }, 1000);
    }

    return () => clearInterval(intervalIdRocket);
  }, [isActiveRocket]);

  useEffect(() => {
    if (secondsRocket === 0) {
      setIsActiveRocket(false);
      // if (skins.includes("dragon")) {
      //   setSecondsRocket(40);
      // } else {
      setSecondsRocket(20 + getEnergyLimit(energy_limit));
      // }
    }
  }, [secondsRocket]);

  useEffect(() => {
    if (!isActiveRocket) {
      // if (skins.includes("dragon")) {
      //   setSecondsRocket(40);
      // } else {
      setSecondsRocket(20 + getEnergyLimit(energy_limit));
      // }
    }
  }, [isActiveRocket]);

  const updateIsActiveRocket = async (newIsActiveRocket) => {
    try {
      // Здесь можно добавить логику обновления баланса в базе данных, если это необходимо
      setIsActiveRocket(newIsActiveRocket);
    } catch (error) {
      console.error("Ошибка при обновлении баланса:", error);
    }
  };

  const [multitap, setMultitap] = useState(0);

  // useEffect(() => {
  //   try {
  //     console.log(parseInt(skins.filter(item => item.includes("multitap"))[0].replace("multitap", "")))
  //     setMultitap(parseInt(skins.filter(item => item.includes("multitap"))[0].replace("multitap", "")));
  //   } catch {
  //     console.log(skins)
  //     setMultitap(0);
  //   }
  // }, [])

  const updateMultitap = async (newMultitap) => {
    setMultitap(newMultitap);
  };

  const [energy_limit, setEnergyLimit] = useState(0);

  // useEffect(() => {
  //   try {
  //     setEnergyLimit(parseInt(skins.filter(item => item.includes("energy_limit"))[0].replace("energy_limit", "")));
  //   } catch {
  //     setEnergyLimit(0);
  //   }
  // }, [])

  const updateEnergyLimit = async (newEnergyLimit) => {
    setEnergyLimit(newEnergyLimit);
  };

  const [recharging_speed, setRechargingSpeed] = useState(0);

  // useEffect(() => {
  //   try {
  //     setRechargingSpeed(parseInt(skins.filter(item => item.includes("recharging_speed"))[0].replace("recharging_speed", "")));
  //   } catch {
  //     setRechargingSpeed(0);
  //   }
  // }, [])

  const updateRechargingSpeed = async (newRechargingSpeed) => {
    setRechargingSpeed(newRechargingSpeed);
  };

  const [ghost_bounty, setGhostBounty] = useState(0);

  // useEffect(() => {
  //   try {
  //     setGhostBounty(parseInt(skins.filter(item => item.includes("ghost_bounty"))[0].replace("ghost_bounty", "")));
  //   } catch {
  //     setGhostBounty(0);
  //   }
  // }, [])

  const updateGhostBounty = async (newGhostBounty) => {
    setGhostBounty(newGhostBounty);
  };

  const [auto_tap_bot, setAutoTapBot] = useState(0);

  // useEffect(() => {
  //   try {
  //     setAutoTapBot(parseInt(skins.filter(item => item.includes("auto_tap_bot"))[0].replace("auto_tap_bot", "")));
  //   } catch {
  //     setAutoTapBot(0);
  //   }
  // }, [])

  const updateAutoTapBot = async (newAutoTapBot) => {
    setAutoTapBot(newAutoTapBot);
  };

  // useEffect(() => {
  //   let intervalId;
  
  //   if (auto_tap_bot > 0) {
  //     // Устанавливаем интервал для обновления состояния баланса каждую секунду
  //     intervalId = setInterval(() => {
  //       updateStateBalance();
  //     }, 1000);
  //   }
  
  //   return () => clearInterval(intervalId);
  // }, [auto_tap_bot]);

  // useEffect(() => {
  //   let intervalId;
  //   if (auto_tap_bot > 0) {
  //     intervalId = setInterval(() => {
  //       updateBalance(balance)
  //     }, 60000);
  //   }
  
  //   return () => clearInterval(intervalId);
  // }, [auto_tap_bot]);



  const [place, setPlace] = useState(false);

  const updatePlace = async (newPlace) => {
    setPlace(newPlace);
  };

  const [squad, setSquad] = useState([]);

  const updateSquad = async (newSquad) => {
    setSquad(newSquad);
  };









  var bodyElement = document.body;
  let lang;
  try {
    lang = WebApp.initDataUnsafe.user.language_code;
  } catch {
    lang = "en";
  }
  // const lang = WebApp.initDataUnsafe.user.language_code;
  const platform = WebApp.platform;
  if (platform === "ios" || platform === "macos") {
    bodyElement.classList.add(`apple`);
  } else {
    if (platform !== "unknown") {
      bodyElement.classList.add(`material`);
    } else {
      bodyElement.classList.add(`material`);
    }
  }

  const result = langpack(lang);

  const welcome = localStorage.getItem("welcome");
  if (!welcome) {
    localStorage.setItem("welcome", false);
  }

  useEffect(() => {
    // Ваш объект для сохранения в sessionStorage
    const pixelcoinLangpack = {
      main: {
        join_squad: result.main.join_squad,
        bronze: result.main.bronze,
        silver: result.main.silver,
        gold: result.main.gold,
        platinum: result.main.platinum,
        diamond: result.main.diamond,
        potato_server: result.main.potato_server,
        frens: result.main.frens,
        earn: result.main.earn,
        boosts: result.main.boosts,
        trade: result.main.trade,
      },
      squad: {
        bronze: result.squad.bronze,
        silver: result.squad.silver,
        gold: result.squad.gold,
        platinum: result.squad.platinum,
        diamond: result.squad.diamond,
        how_it_works: result.squad.how_it_works,
        mined_in_squad: result.squad.mined_in_squad,
        players: result.squad.players,
        join_squad: result.squad.join_squad,
        invite_a_fren: result.squad.invite_a_fren,
        leave_squad: result.squad.leave_squad,
        want_to_leave: result.squad.want_to_leave,
        squad: result.squad.squad,
      },
      squads: {
        title: result.squads.title,
        these_squads_receruiting_now:
          result.squads.these_squads_receruiting_now,
        do_you_wanna_join: result.squads.do_you_wanna_join,
        empty_squad: result.squads.empty_squad,
        under_maintenance: result.squads.under_maintenance,
        create_your_squad: result.squads.create_your_squad,
        bronze: result.squads.bronze,
        silver: result.squads.silver,
        gold: result.squads.gold,
        platinum: result.squads.platinum,
        diamond: result.squads.diamond,
      },
      join_squad: {
        join: result.join_squad.join,
        squad: result.join_squad.squad,
        header_text: result.join_squad.header_text,
        footer_text: result.join_squad.footer_text,
        button: result.join_squad.button,
      },
      about: {
        title_default: result.about.title_default,
        title_squads: result.about.title_squads,
        join: result.about.join,
        follow: result.about.follow,
        card_title_play: result.about.card_title_play,
        card_text_play: result.about.card_text_play,
        card_title_earn: result.about.card_title_earn,
        card_text_earn: result.about.card_text_earn,
        card_title_league: result.about.card_title_league,
        card_text_league: result.about.card_text_league,
        card_title_rocket: result.about.card_title_rocket,
        card_text_rocket: result.about.card_text_rocket,
        card_title_squad: result.about.card_title_squad,
        card_text_squad: result.about.card_text_squad,
        card_title_squads: result.about.card_title_squads,
        card_text_squads: result.about.card_text_squads,
        card_title_bag: result.about.card_title_bag,
        card_text_bag: result.about.card_text_bag,
        card_title_medal: result.about.card_title_medal,
        card_text_medal: result.about.card_text_medal,
        full_guide: result.about.full_guide,
        play: result.about.play,
        good: result.about.good,
        telegraph: result.about.telegraph,
      },
      earn: {
        your_balance: result.earn.your_balance,
        send: result.earn.send,
        receive: result.earn.receive,
        onboarding: result.earn.onboarding,
        send_coins: result.earn.send_coins,
        join_squad: result.earn.join_squad,
        invite_ten_frens: result.earn.invite_ten_frens,
        completed: result.earn.completed,
        competitive: result.earn.competitive,
        compete: result.earn.compete,
        earn_more_coins: result.earn.earn_more_coins,
        full_guide: result.earn.full_guide,
        invite_bonus: result.earn.invite_bonus,
        up_to: result.earn.up_to,
        for_fren: result.earn.for_fren,
        specials: result.earn.specials,
        follow_pixelcoin: result.earn.follow_pixelcoin,
        pixelcoin_on_x: result.earn.pixelcoin_on_x,
        web_world: result.earn.web_world,
        coming_soon: result.earn.coming_soon,
        just_wait: result.earn.just_wait,
        built_with: result.earn.built_with,
        by: result.earn.by,
        community: result.earn.community,
      },
      boost: {
        your_balance: result.boost.your_balance,
        how_boost_works: result.boost.how_boost_works,
        free_daily_boosters: result.boost.free_daily_boosters,
        turbo: result.boost.turbo,
        turbo_description1: result.boost.turbo_description1,
        turbo_description2: result.boost.turbo_description2,
        full_energy: result.boost.full_energy,
        full_energy_description1: result.boost.full_energy_description1,
        full_energy_description2: result.boost.full_energy_description2,
        level: result.boost.level,
        unlimited: result.boost.unlimited,
        hours_left: result.boost.hours_left,
        free: result.boost.free,
        title_nft: result.boost.title_nft,
        description_nft: result.boost.description_nft,
        you_own_it: result.boost.you_own_it,
        enabled: result.boost.enabled,
        turn_on: result.boost.turn_on,
        apply_skin: result.boost.apply_skin,
        get: result.boost.get,
        basic: result.boost.basic,
        basic_description: result.boost.basic_description,
        pizza: result.boost.pizza,
        pizza_description: result.boost.pizza_description,
        cookie: result.boost.cookie,
        cookie_description1: result.boost.cookie_description1,
        cookie_description2: result.boost.cookie_description2,
        cookie_description3: result.boost.cookie_description3,
        galleon: result.boost.galleon,
        galleon_description1: result.boost.galleon_description1,
        galleon_description2: result.boost.galleon_description2,
        galleon_description3: result.boost.galleon_description3,
        fallout: result.boost.fallout,
        fallout_description1: result.boost.fallout_description1,
        fallout_description2: result.boost.fallout_description2,
        fallout_description3: result.boost.fallout_description3,
        dragon: result.boost.dragon,
        dragon_description1: result.boost.dragon_description1,
        dragon_description2: result.boost.dragon_description2,
        dragon_description3: result.boost.dragon_description3,
        pixelcoin: result.boost.pixelcoin,
        pixelcoin_description1: result.boost.pixelcoin_description1,
        pixelcoin_description2: result.boost.pixelcoin_description2,
        pixelcoin_description3: result.boost.pixelcoin_description3,
        not_enough_coins: result.boost.not_enough_coins,
        loading: result.boost.loading,
        built_with: result.boost.built_with,
        by: result.boost.by,
        community: result.boost.community,
      },
      stats: {
        balance_text_fiat: result.stats.balance_text_fiat,
        total_players: result.stats.total_players,
        daily_users: result.stats.daily_users,
        online: result.stats.online,
        invite_a_fren: result.stats.invite_a_fren,
      },
      party_kings: {
        title: result.party_kings.title,
        description: result.party_kings.description,
        invite_frens: result.party_kings.invite_frens,
        frens: result.party_kings.frens,
      },
      qr_code: {
        desktop_is_boring: result.qr_code.desktop_is_boring,
        play_on_your_mobile: result.qr_code.play_on_your_mobile,
      },
      referral: {
        title_invite: result.referral.title_invite,
        invite_fren: result.referral.invite_fren,
        for_you_and_fren: result.referral.for_you_and_fren,
        fren_with: result.referral.fren_with,
        telegram_premium: result.referral.telegram_premium,
        title_level: result.referral.title_level,
        level_up: result.referral.level_up,
        regular_fren: result.referral.regular_fren,
        premium: result.referral.premium,
        silver: result.referral.silver,
        gold: result.referral.gold,
        platinum: result.referral.platinum,
        diamonds: result.referral.diamonds,
        invite_a_fren: result.referral.invite_a_fren,
      },
      fren: {
        title: result.fren.title,
        title_short: result.fren.title_short,
        how_to_make: result.fren.how_to_make,
        top: result.fren.top,
        leaders: result.fren.leaders,
        title_invite: result.fren.title_invite,
        invite_fren: result.fren.invite_fren,
        for_you_and_fren: result.fren.for_you_and_fren,
        fren_with: result.fren.fren_with,
        telegram_premium: result.fren.telegram_premium,
        open_details: result.fren.open_details,
        title_frens_list: result.fren.title_frens_list,
        empty_frens_list: result.fren.empty_frens_list,
        under_maintenance: result.fren.under_maintenance,
        invite_a_fren: result.fren.invite_a_fren,
      },
      league: {
        pixelcoiners: result.league.pixelcoiners,
        stats: result.league.stats,
        bronze_league: result.league.bronze_league,
        silver_league: result.league.silver_league,
        gold_league: result.league.gold_league,
        platinum_league: result.league.platinum_league,
        diamond_league: result.league.diamond_league,
        miners: result.league.miners,
        squads: result.league.squads,
        empty_list: result.league.empty_list,
        under_maintenance: result.league.under_maintenance,
        you_will_join_leaderboard: result.league.you_will_join_leaderboard,
        in_the_silver_league: result.league.in_the_silver_league,
        leaderboard_is_under_construction:
          result.league.leaderboard_is_under_construction,
        bronze_miners: result.league.bronze_miners,
        bronze_squads: result.league.bronze_squads,
        silver_miners: result.league.silver_miners,
        silver_squads: result.league.silver_squads,
        gold_miners: result.league.gold_miners,
        gold_squads: result.league.gold_squads,
        platinum_miners: result.league.platinum_miners,
        platinum_squads: result.league.platinum_squads,
        diamond_miners: result.league.diamond_miners,
        diamond_squads: result.league.diamond_squads,
        you: result.league.you,
        your: result.league.your,
      },
      compete: {
        title: result.compete.title,
        play_tournaments_and_earn_coins:
          result.compete.play_tournaments_and_earn_coins,
        do_you_wanna_join: result.compete.do_you_wanna_join,
        empty_tournaments: result.compete.empty_tournaments,
        under_maintenance: result.compete.under_maintenance,
      },
      referral_system: {
        play_and_earn: result.referral_system.play_and_earn,
        for_you_and_fren: result.referral_system.for_you_and_fren,
      },
      create_squad: {
        create_your_squad: result.create_squad.create_your_squad,
        send_link: result.create_squad.send_link,
        examples: result.create_squad.examples,
        and_etc: result.create_squad.and_etc,
      },
    };

    // Преобразование объекта в строку JSON
    const jsonString = JSON.stringify(pixelcoinLangpack);

    // Сохранение строки JSON в sessionStorage
    sessionStorage.setItem("pixelcoinLangpack", jsonString);
  }, []);

  return (
    <>
      <LastAction userId={userId} />
      <Routes>
        {isPlatformAllowed(WebApp.platform) ? (
          <>
            {loading ? (
              <Route path="/" Component={Loader} />
            ) : (
              <Route
                path="/"
                element={
                  <Main
                    userId={userId}
                    score={score}
                    updateScore={updateScore}
                    oldScore={oldScore}
                    updateOldScore={updateOldScore}
                    updateStateBalance={updateStateBalance}
                    session={session}
                    updateSession={updateSession}
                    squadScore={squadScore}
                    updateSquadScore={updateSquadScore}
                    balance={balance}
                    updateBalance={updateBalance}
                    oldBalance={oldBalance}
                    updateOldBalance={updateOldBalance}
                    click={click}
                    updateClick={updateClick}
                    rocket={rocket}
                    updateRocket={updateRocket}
                    secondsClick={secondsClick}
                    isActiveClick={isActiveClick}
                    updateIsActiveClick={updateIsActiveClick}
                    secondsRocket={secondsRocket}
                    updateIsActiveRocket={updateIsActiveRocket}
                    rocketNum={rocketNum}
                    updateRocketNum={updateRocketNum}
                    touch={touch}
                    updateTouch={updateTouch}
                    squad={squad}
                    updateSquad={updateSquad}
                    place={place}
                    updatePlace={updatePlace}
                    mainSkin={mainSkin}
                    skins={skins}
                    multitap={multitap}
                    recharging_speed={recharging_speed}
                    ghost_bounty={ghost_bounty}
                    energy_limit={energy_limit}
                    auto_tap_bot={auto_tap_bot}
                  />
                }
              />
            )}

            <Route
              path="/earn"
              element={
                <Earn
                  userId={userId}
                  score={score}
                  updateScore={updateScore}
                  balance={balance}
                  updateBalance={updateBalance}
                  onboarding={onboarding}
                  updateOnboarding={updateOnboarding}
                />
              }
            />
            <Route
              path="/boost"
              element={
                <Boost
                  userId={userId}
                  score={score}
                  updateScore={updateScore}
                  balance={balance}
                  updateBalance={updateBalance}
                  mainSkin={mainSkin}
                  updateMainSkin={updateMainSkin}
                  skins={skins}
                  updateSkins={updateSkins}
                  updateAllSkins={updateAllSkins}
                  rocketNum={rocketNum}
                  multitap={multitap}
                  energy_limit={energy_limit}
                  recharging_speed={recharging_speed}
                  ghost_bounty={ghost_bounty}
                  auto_tap_bot={auto_tap_bot}
                  updateMultitap={updateMultitap}
                  updateEnergyLimit={updateEnergyLimit}
                  updateRechargingSpeed={updateRechargingSpeed}
                  updateGhostBounty={updateGhostBounty}
                  updateAutoTapBot={updateAutoTapBot}
                />
              }
            />
            <Route path="/fren" element={<Fren userId={userId} squad={squad} />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/league"
              element={
                <League userId={userId} score={score} mainSkin={mainSkin} />
              }
            />
            <Route path="/party" element={<PartyKings userId={userId} squad={squad} />} />
            <Route path="/squad" element={<Squad userId={userId} />} />
            <Route path="/squads" element={<Squads userId={userId} />} />
            <Route path="/join_squad" element={<JoinSquad userId={userId} />} />
            <Route path="/stats" element={<Stats userId={userId} squad={squad} />} />
            <Route path="/referral" element={<Referral userId={userId} squad={squad} />} />
            <Route path="/compete" element={<Compete score={score} />} />
            <Route path="/trade" element={<Trade userId={userId} />} />
            <Route
              path="/receiver-search"
              element={<ReceiverSearch userId={userId} />}
            />
            <Route
              path="/send"
              element={
                <Send
                  userId={userId}
                  score={score}
                  updateScore={updateScore}
                  balance={balance}
                  updateBalance={updateBalance}
                />
              }
            />
            <Route path="/complete" element={<Complete userId={userId} />} />
          </>
        ) : (
          <Route path="/" element={<QrCode />} />
        )}
      </Routes>
    </>
  );
}

export default App;
