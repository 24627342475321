import React, { useCallback } from "react";
import Lottie from "lottie-react";
import Disco from "../../assets/animation/Disco.json";
import Duck_think_out from "../../assets/animation/Duck_think_out.json";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./style.scss";
import UsersSkeleton from "../../components/UsersSkeleton";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import axios from "axios";
import getSquadLeague from "../../utils/getSquadLeague";
import abbreNumbers from "../../utils/abbreNumbers";
import addCommas from "../../utils/addCommas";
import LeadersPhoto from "../../components/LeadersPhoto";
import Duck_x3_out from "../../assets/animation/Duck_x3_out.json";

function Search() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const asset = params.get("asset");
  const type = params.get("type");
  return { asset, type };
}

export default function Squad({ userId }) {
  const navigate = useNavigate();

  const result = Search();

  const [showPage, setShowPage] = useState(false);

  const [squad, setSquad] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios
          .get(`https://pixelcoin.site:433/squads/${result.asset}`)
          .then((response) => {
            setSquad(response.data);
            setShowPage(true);
          });
      } catch (error) {
        console.error("Error fetching squad:", error);
      }
    };

    fetchData();
  }, []);

  const league = getSquadLeague(squad.score);

  const [newSquad, setNewSquad] = useState(0);

  // Функция для получения баланса пользователя
  const fetchNewSquad = async () => {
    try {
      const response = await axios.get(
        `https://pixelcoin.site:433/users/${userId}/squad`
      );
      setNewSquad(response.data.squad);
    } catch (error) {
      console.error("Error fetching new_squad:", error);
    }
  };

  // Функция для обновления баланса пользователя
  const updateNewSquad = async (newSquad) => {
    try {
      const response = await axios.put(
        `https://pixelcoin.site:433/users/${userId}/squad`,
        {
          squad: newSquad,
        }
      );
      setNewSquad(response.data.squad);
    } catch (error) {
      console.error("Error updating squad:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchNewSquad();
  }, []);

  const leaveSquad = () => {
    axios
      .get(`https://pixelcoin.site:433/squads/${result.asset}/players`)
      .then((response1) => {
        const players = response1.data.players;
        // Find the index of userId in the players array and remove it
        const index = players.indexOf(userId);
        if (index > -1) {
          players.splice(index, 1);
        }
        return players; // Return the modified players array
      })
      .then((players) => {
        axios.put(`https://pixelcoin.site:433/squads/${result.asset}/players`, {
          players: players,
        });
      })
      .then(() => {
        axios.put(
          `https://pixelcoin.site:433/users/${userId}/squad_score`,
          {
            squad_score: 0,
          }
        );
      })
      .then(() => {
        // Additional then block to execute after axios.put
        updateNewSquad("");
      })
      .catch((error) => {
        console.error("Ошибка при обновлении отряда:", error);
      });
    // updateSquadScore(0);
  };

  const [loading, setLoading] = useState(true);

  const [leaders, setLeaders] = useState([]);

  useEffect(() => {
    const fetchLeaders = async () => {
      try {
        axios
          .get(`https://pixelcoin.site:433/squads/${result.asset}/leaders`)
          .then((response) => {
            setLeaders(response.data);
            setLoading(false);
          });
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };

    fetchLeaders();
  }, []);

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  return (
    <>
      <BackButton
        onClick={() =>
          newSquad !== "" && newSquad === result.asset
            ? navigate("/")
            : navigate("/squads")
        }
      />
      <CSSTransition
        in={showPage}
        timeout={50}
        className="earn-animation"
        unmountOnExit
      >
        <div>
          <div className={`page scrolling`}>
            <div className={`background-league theme-league__${league}`}></div>
            <div className="container position-relative">
              <div className="page__content uY4S">
                <div className="Jh2w IueR">
                  <img
                    src={`${squad.url_photo}`}
                    style={{
                      width: "112px",
                      height: "112px",
                      borderRadius: "32px",
                    }}
                  />
                </div>
                <div
                  className="Jh2w"
                  style={{
                    marginTop: "24px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "32px",
                      fontWeight: "700",
                      color: "#fff",
                    }}
                  >
                    {squad.name}
                  </div>
                  <div
                    onClick={() => {
                      squad.link.indexOf("t.me") !== -1
                        ? WebApp.openTelegramLink(squad.link)
                        : WebApp.openLink(squad.link, {
                            try_instant_view: true,
                          });
                    }}
                    style={{
                      marginLeft: "6px",
                      marginTop: "10px",
                    }}
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 16.5V17.5C20 19.1569 18.6569 20.5 17 20.5H7C5.34315 20.5 4 19.1569 4 17.5V7.5C4 5.84315 5.34315 4.5 7 4.5H8"
                        stroke="#EBEBF5"
                        strokeOpacity="0.6"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M13 4.5H16H19.999C19.9996 4.5 20 4.50045 20 4.501V8.5V11.5"
                        stroke="#EBEBF5"
                        strokeOpacity="0.6"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M11.2929 11.7929C10.9024 12.1834 10.9024 12.8166 11.2929 13.2071C11.6834 13.5976 12.3166 13.5976 12.7071 13.2071L11.2929 11.7929ZM19.2929 3.79289L11.2929 11.7929L12.7071 13.2071L20.7071 5.20711L19.2929 3.79289Z"
                        fill="#EBEBF5"
                        fillOpacity="0.6"
                      />
                    </svg>
                  </div>
                </div>
                <div className="Jh2w">
                  <div
                    onClick={() =>
                      navigate(`/league?assetLeague=${league}&type=squads`)
                    }
                    className="Li2p Z5dC Hexc"
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          className={`icon-cup-position icon-cup__${league}`}
                        ></div>
                        <div className="KI3d Iu3d">{`${
                          league === "bronze"
                            ? parsedData.main.bronze
                            : league === "silver"
                            ? parsedData.main.silver
                            : league === "gold"
                            ? parsedData.main.gold
                            : league === "platinum"
                            ? parsedData.main.platinum
                            : parsedData.main.diamond
                        }`}</div>
                        <div className="Hexc">
                          <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon-shrink icon-shrink__arrow"
                          >
                            <path
                              d="m1 1 5 5-5 5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate("/about?asset=squads");
                  }}
                  className="Jh2w"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  <div className="KU2l">{parsedData.squad.how_it_works}</div>
                </div>

                {squad.text !== "" && (
                  <div
                    className="Jh2w"
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <div
                      className="JIYc"
                      style={{
                        color: "#fff",
                      }}
                    >
                      {squad.text}
                    </div>
                  </div>
                )}

                <div
                  className={`black-transparent ${
                    newSquad !== "" && newSquad === result.asset
                      ? `IU4c`
                      : `JHec`
                  }`}
                >
                  <div className="B0WY">
                    <div className="">
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          paddingTop: "10px",
                          paddingLeft: "16px",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            width: "24px",
                            height: "24px",
                            minWidth: "24px",
                            marginTop: "12px",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundColor: "transparent",
                          }}
                          className="icon-coin-small"
                        ></div>
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#fff",
                            marginLeft: "30px",
                            marginTop: "12px",
                          }}
                        >
                          {abbreNumbers(squad.score)}
                        </div>
                        <div
                          style={{
                            marginLeft: "30px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                            }}
                          >
                            {parsedData.squad.mined_in_squad}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          marginTop: "26px",
                          marginLeft: "202px",
                        }}
                      >
                        <div
                          style={{
                            height: "44px",
                            width: "1px",
                            backgroundColor: "rgba(235, 235, 245, 0.3)",
                          }}
                        ></div>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          left: "0px",
                          top: "0px",
                          paddingTop: "10px",
                          paddingLeft: "200px",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#fff",
                            marginLeft: "22px",
                            marginTop: "12px",
                          }}
                        >
                          {squad && squad.players ? squad.players.length : 0}
                        </div>
                        <div
                          style={{
                            marginLeft: "22px",
                          }}
                        >
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#d4d4d4",
                            }}
                          >
                            {parsedData.squad.players}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        newSquad !== "" && newSquad === result.asset
                          ? WebApp.openTelegramLink(
                              `https://t.me/share/url?text=${
                                parsedData.referral_system.play_and_earn
                              }${`50,000`}${
                                parsedData.referral_system.for_you_and_fren
                              }&url=https://t.me/pixelcoinbot?start=r_${
                                result.asset
                              }_${userId}`
                            )
                          : navigate(`/join_squad?asset=${result.asset}`);
                      }}
                      className={
                        newSquad !== "" && newSquad === result.asset
                          ? `top-squad-btn`
                          : `bottom-squad-btn bottom-squad-btn-active`
                      }
                      // className="top-squad-btn"
                    >
                      <div className="Jh2w">
                        <div className="Li2p">
                          <div className="Iy4o">
                            {newSquad !== "" && newSquad === result.asset
                              ? parsedData.squad.invite_a_fren
                              : parsedData.squad.join_squad}
                          </div>
                        </div>
                      </div>
                    </div>
                    {newSquad !== "" && newSquad === result.asset && (
                      <div
                        onClick={() => {
                          WebApp.showConfirm(
                            `${parsedData.squad.want_to_leave} ${squad.name} ${parsedData.squad.squad}?`,
                            (confirmed) => {
                              if (confirmed) {
                                navigate(`/squad?asset=${result.asset}`);
                                leaveSquad();
                              }
                            }
                          );
                        }}
                        className="bottom-squad-btn bottom-squad-btn-gray"
                      >
                        <div className="Jh2w">
                          <div className="Li2p">
                            <div className="Iy4o">
                              {parsedData.squad.leave_squad}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {loading ? (
                  <UsersSkeleton />
                ) : leaders.length > 0 ? (
                  <ul className="league_skeleton">
                    {leaders.map((user, index) => (
                      <li key={index} className="league_skeleton__wrapper">
                        <LeadersPhoto
                          url_photo={user.url_photo}
                          first_name={user.first_name}
                        />
                        <div className="league_element__box1">
                          {user.first_name}
                        </div>
                        <div className="league_element__box2">
                          <div
                            className="penny-icon vBwQ"
                            style={{
                              position: "absolute",
                              display: "inline-block",
                              marginTop: "4px",
                            }}
                          ></div>
                          <div
                            style={{
                              display: "inline",
                              color: "#d4d4d4",
                              marginLeft: "20px",
                            }}
                          >
                            {addCommas(user.squad_score)}
                          </div>
                        </div>
                        {index === 0 ? (
                          <img
                            src={require("../../assets/images/1st-place-medal.png")}
                            className="league_element__box3"
                          />
                        ) : index === 1 ? (
                          <img
                            src={require("../../assets/images/2nd-place-medal.png")}
                            className="league_element__box3"
                          />
                        ) : index === 2 ? (
                          <img
                            src={require("../../assets/images/3rd-place-medal.png")}
                            className="league_element__box3"
                          />
                        ) : (
                          <div className="league_element__box3">
                            {index + 1}
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="UIx1">
                    <div className="lgqO">
                      <div className="JKpY Z5dC">
                        {/* <div onClick={handleButtonClick} className="JKpY Z5dC"> */}
                        <Lottie
                          // key={animationKey}
                          // loop={false}
                          // autoPlay={playAnimation}
                          animationData={Duck_x3_out}
                        />
                      </div>
                      <div className="OCW2">{parsedData.league.empty_list}</div>
                    </div>
                  </div>
                )}
                {/* <UsersSkeleton /> */}
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
