import getRocket from "./getRocket";
import getMultitap from "./getMultitap";

export default function getClick(score, rocket, rocketNum, skins, multitap) {
  if (rocket) {
      score = parseInt(2 * getMultitap(multitap) * (getRocket(rocketNum) - 1));
  } else {
    score = parseInt(getMultitap(multitap));
  }
  return score;
}
