import "./style.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import BlackTransparent from "../../components/BlackTransparent";
import { CSSTransition } from "react-transition-group";
import addCommas from "../../utils/addCommas";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import coinImage from "../../assets/images/coin-min.svg";
import getLeague from "../../utils/getLeague";
import abbreNumbers from "../../utils/abbreNumbers";
import TradePhoto from "../../components/TradePhoto";

export default function Trade({ userId }) {
  const navigate = useNavigate();

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [isVisible, setIsVisible] = useState(false);

  const handleCopy = () => {
    setIsVisible(true);
    navigator.clipboard.writeText(userId);
    setTimeout(() => {
      setIsVisible(false);
    }, 2000);
  };

  return (
    <>
      <BackButton onClick={() => navigate("/")} />
      <div
        className="page"
        style={{
          overflow: "hidden",
        }}
      >
        <div className={`container`}>
          <div className="page__content">
            <CSSTransition
              in={true}
              timeout={50}
              className="trade-animation"
              unmountOnExit
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => {
                      navigate("/earn");
                    }}
                    className="Yi28"
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        marginTop: "6px",
                        marginLeft: "10px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                          style={{
                            opacity: ".6",
                          }}
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                      }}
                    ></div>
                    <div
                      style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        color: "#fff",
                      }}
                    >
                      Exclusive for
                    </div>
                    <div className="imageCoin-trade"></div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#fff",
                        marginLeft: "2px",
                        marginTop: "2px",
                      }}
                    >
                      10K+
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        marginTop: "6px",
                        marginRight: "10px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                          style={{
                            opacity: ".6",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "80px",
                    fontFamily: "var(--font-display-title) !important",
                    // display: "inline-block",
                    verticalAlign: "middle",
                    width: "auto",
                    fontSize: "48px",
                    lineHeight: "48px",
                    fontWeight: "700",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Pixelcoin
                </div>
                <div
                  style={{
                    // display: "inline-block",
                    fontFamily: "var(--font-display-title) !important",
                    verticalAlign: "middle",
                    fontSize: "48px",
                    width: "auto",
                    lineHeight: "48px",
                    fontWeight: "700",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Transfers
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    // fontSize: "16px",
                    // fontWeight: "500",
                    color: "#ffffff80",
                  }}
                >
                  Send Pixelcoins via platform,
                  <br />
                  before token is issued
                </div>

                <div className="_imagesContainer_c9uy9_85">
                  <div className="_image_c9uy9_85 _imageUser_c9uy9_116 _imageUser1"></div>
                  <div className="_imageLarge_c9uy9_123 _imageCoin_c9uy9_116"></div>
                  <div className="_image_c9uy9_85 _imageUser_c9uy9_116 _imageUser2"></div>
                </div>
                <div
                  style={{
                    // marginTop: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#ffffff80",
                  }}
                >
                  Exchange Coins to $PX
                  <br />
                  after token generation event.
                  <br />
                  Or convert in-game balance directly.
                </div>
                <div
                  onClick={() => {
                    navigate("/about?asset=default");
                  }}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#42a0ff",
                  }}
                >
                  Learn more
                </div>
                <div
                  onClick={() => {
                    handleCopy();
                  }}
                  className="receive-trade-btn"
                >
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">Receive Coins</div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate("/receiver-search");
                  }}
                  className="send-trade-btn"
                >
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">Send Coins</div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
          <div className={`notification ${isVisible ? "visible" : ""}`}>
            <div>
              <div
                style={{
                  width: "100%",
                  borderRadius: "12px",
                  padding: "8px",
                  background: "rgba(28, 28, 30, .9)",
                  alignItems: "center",
                  boxSizing: "border-box",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  transition: "background ease",
                  transitionDuration: "0.3s",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  viewBox="0 0 28 28"
                >
                  <rect
                    width="14"
                    height="14"
                    x="9"
                    y="6"
                    fill="#fff"
                    rx="3"
                  ></rect>
                  <rect
                    width="15.6"
                    height="15.6"
                    x="4.2"
                    y="9.2"
                    fill="#fff"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    rx="3.8"
                  ></rect>
                </svg>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    marginLeft: "10px"
                  }}
                >
                  Address is copied to clipboard.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
