import "./style.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import Lottie from "lottie-react";
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import BlackTransparent from "../../components/BlackTransparent";
import { CSSTransition } from "react-transition-group";
import addCommas from "../../utils/addCommas";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import getLeague from "../../utils/getLeague";
import Pigeon from "../../assets/animation/Pigeon.json";
import Chick_out from "../../assets/animation/Chick_out.json";
import Chick_stnd from "../../assets/animation/Chick_stnd.json";
import Chick from "../../assets/animation/Chick.json";
import getRocket from "../../utils/getRocket";
import Counter from "../../components/Counter";

export default function Earn({
  userId,
  score,
  balance,
  updateBalance,
  onboarding,
  updateOnboarding,
}) {
  const navigate = useNavigate();

  const [showPage, setShowPage] = useState(false);

  const checkSubscription = async (userId, channelId) => {
    // const user = 5668476910;
    const botToken = "6967469657:AAFW2-pv_1HXN1NQ1jflalr07MHz_Mm7QJU";
    // const channelId = "-1002026213949";
    const response = await fetch(
      `https://api.telegram.org/bot${botToken}/getChatMember?chat_id=${channelId}&user_id=${userId}`
    );
    const data = await response.json();
    if (
      data.ok &&
      (data.result.status === "member" || data.result.status === "creator")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const [frens, setFrens] = useState([]);

  useEffect(() => {
    const fetchFrens = async () => {
      try {
        const response = await axios.get(
          `https://pixelcoin.site:433/users/${userId}/frens`
        );
        setFrens(response.data.frens);
      } catch (error) {
        console.error("Failed to fetch frens:", error);
      }
    };

    fetchFrens();

    // Очистка эффекта
    return () => {
      // Если нужно выполнить какие-то действия при размонтировании компонента
    };
  }, []);

  const [squad, setSquad] = useState("");

  // Функция для получения баланса пользователя
  const fetchSquad = async () => {
    try {
      const response = await axios.get(
        `https://pixelcoin.site:433/users/${userId}/squad`
      );
      setSquad(response.data.squad);
      setShowPage(true);
    } catch (error) {
      console.error("Error fetching squad:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchSquad();
  }, []);

  const [received, setReceived] = useState("");

  // Функция для получения баланса пользователя
  const fetchReceived = async () => {
    try {
      const response = await axios.get(
        `https://pixelcoin.site:433/users/${userId}/received`
      );
      setReceived(response.data.received);
    } catch (error) {
      console.error("Error fetching received:", error);
    }
  };

  // Вызываем функцию получения баланса при монтировании компонента
  useEffect(() => {
    fetchReceived();
  }, []);

  const [itemFirst, setItemFirst] = useState(false);
  const [itemSecond, setItemSecond] = useState(false);
  const [itemThird, setItemThird] = useState(false);
  const [itemFourth, setItemFourth] = useState(false);
  const [itemFifth, setItemFifth] = useState(false);
  const [twitterAction, setTwitterAction] = useState(0);
  // const [itemSixth, setItemSixth] = useState(false);

  const funcOnboarding = (newOnboarding) => {
    if (newOnboarding === "send_ten_thousand") {
      setItemFirst(true);
      setTimeout(() => {
        setItemFirst(false);
        if (received >= 10000) {
          if (!onboarding.includes("send_ten_thousand")) {
            updateBalance(balance + 30000);
          }
          updateOnboarding("send_ten_thousand");
        } else {
          navigate("/trade");
        }
      }, 2000);
    } else if (newOnboarding === "join_squad") {
      setItemSecond(true);
      setTimeout(() => {
        setItemSecond(false);
        if (squad != "") {
          if (!onboarding.includes("join_squad")) {
            updateBalance(balance + 30000);
          }
          updateOnboarding("join_squad");
        } else {
          navigate("/squads");
        }
      }, 2000);
    } else if (newOnboarding === "ten_frens") {
      setItemThird(true);
      setTimeout(() => {
        setItemThird(false);
        if (frens.length >= 10) {
          if (!onboarding.includes("ten_frens")) {
            updateBalance(balance + 500000);
          }
          updateOnboarding("ten_frens");
        } else {
          navigate("/fren");
        }
      }, 2000);
    } else if (newOnboarding === "channel") {
      setItemFourth(true);
      setTimeout(() => {
        setItemFourth(false);
        checkSubscription(userId, -1002026213949)
          .then((isSubscribed) => {
            if (isSubscribed) {
              if (!onboarding.includes("channel")) {
                updateBalance(balance + 200000);
              }
              updateOnboarding("channel");
            } else {
              WebApp.openTelegramLink("https://t.me/thepixelcoin");
            }
          })
          .catch((error) => {
            WebApp.openTelegramLink("https://t.me/thepixelcoin");
          });
      }, 2000);
    } else if (newOnboarding === "twitter") {
      setItemFifth(true);
      setTimeout(() => {
        setItemFifth(false);
        if (twitterAction > 0) {
          if (!onboarding.includes("twitter")) {
            updateBalance(balance + 100000);
          }
          updateOnboarding("twitter");
        } else {
          setTwitterAction(twitterAction + 1);
          WebApp.openLink("https://twitter.com/thepixelcoin", {
            try_instant_view: true,
          });
        }
      }, 2000);
    }
    // else if (newOnboarding === "twitter") {

    // }
  };

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [starFirst, setStarFirst] = useState(false);

  setTimeout(() => {
    setStarFirst(true);
  }, 1800);

  const [starSecond, setStarSecond] = useState(false);

  setTimeout(() => {
    setStarSecond(true);
  }, 800);

  const [starThird, setStarThird] = useState(false);

  setTimeout(() => {
    setStarThird(true);
  }, 1200);

  const [starFourth, setStarFourth] = useState(false);

  setTimeout(() => {
    setStarFourth(true);
  }, 1200);

  return (
    <>
      <BackButton onClick={() => navigate("/")} />
      <div className="page">
        <div className="background-yellow theme-yellow"></div>
        <div
          style={{
            position: "relative",
          }}
        >
          <CSSTransition
            in={starFirst}
            timeout={800}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_earn-1 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starSecond}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_earn-2 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starThird}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_earn-3 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starFourth}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_earn-4 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
        </div>
        <div className={`container position-relative scrolling`}>
          <div className="page__content">
            <CSSTransition
              in={showPage}
              timeout={50}
              className="earn-animation"
              unmountOnExit
            >
              <div>
                {/* <div className="Jh2w">
                  <div className="Li2p">
                    <div className="Uye4">{parsedData.earn.your_balance}</div>
                  </div>
                </div>
                <div className="Jh2w IueR">
                  <div className="Li2p">
                    <div className="balance-icon"></div>
                    <div className="BeIu">
                      <Counter start={oldBalance + score} end={balance + score}/>
                      </div>
                  </div>
                </div> */}
                <div className="Jh2w">
                  <div className="Li2p">
                    <div className="earn-icon"></div>
                  </div>
                </div>
                <div
                  className="Jh2w"
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <div className="Li2p">
                    <div
                      style={{
                        fontSize: "32px",
                        fontWeight: "600",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      {parsedData.earn.earn_more_coins}
                    </div>
                  </div>
                </div>
                <div
                  className="Jh2w"
                  style={{
                    marginTop: "4px",
                  }}
                >
                  <div
                    onClick={() => {
                      navigate("/about?asset=default");
                    }}
                    className="Li2p Z5dC"
                  >
                    <div className="KH5k">{parsedData.earn.full_guide}</div>
                  </div>
                </div>
                <div
                  className="earn-transparent-div earn-transparent-div_first"
                  style={{
                    marginTop: "50px",
                  }}
                >
                  <button
                    className="earn-transparent-btn earn-transparent-btn_first"
                    onClick={() => {
                      navigate("/fren");
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/gift.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">🧸</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          {parsedData.earn.invite_bonus}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            paddingTop: "4px",
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {parsedData.earn.up_to} 1M
                          <div
                            className="penny-icon"
                            style={{
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                              marginTop: "1px",
                              marginRight: "2px",
                            }}
                          ></div>
                          <div
                            style={{
                              color: "#d4d4d4",
                              fontWeight: "400",
                              marginLeft: "4px",
                            }}
                          >
                            {parsedData.earn.for_fren}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        paddingTop: "38px",
                        paddingRight: "20px",
                        color: "rgba(235, 235, 245, 0.3)",
                      }}
                    >
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    marginTop: "42px",
                  }}
                >
                  <button
                    // onClick={() => {
                    //   navigate("/receiver-search");
                    // }}
                    className="send-btn"
                    style={{
                      opacity: "0.6",
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#fff",
                        marginTop: "24px",
                        marginLeft: "16px",
                      }}
                    >
                      {parsedData.earn.send}
                    </div>
                    <img
                      src={require("../../assets/images/money-with-wings.png")}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        height: "32px",
                        width: "32px",
                        marginTop: "18px",
                        marginRight: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  </button>
                  <button
                    // onClick={() => {
                    //   navigate("/receive");
                    // }}
                    className="receive-btn"
                    style={{
                      opacity: "0.6",
                      pointerEvents: "none",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#fff",
                        marginTop: "24px",
                        marginLeft: "16px",
                      }}
                    >
                      {parsedData.earn.receive}
                    </div>
                    <img
                      src={require("../../assets/images/wallet.png")}
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        height: "32px",
                        width: "32px",
                        marginTop: "16px",
                        marginRight: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  </button>
                </div> */}
                <div className="IUG3">{parsedData.earn.onboarding}</div>
                <div className="earn-transparent-div earn-transparent-div_second">
                  <button
                    // className={`earn-transparent-btn${
                    //   itemFirst ? `_block` : ``
                    // } earn-transparent-btn_second ${fiveFrens ? `none` : ``}`}
                    // className={`earn-transparent-btn${
                    //   itemFirst ? `_block` : ``
                    // } earn-transparent-btn_second none`}

                    className={`earn-transparent-btn${
                      itemFirst ? `_block` : ``
                    } earn-transparent-btn_second ${
                      onboarding.includes("send_ten_thousand") ? `none` : ``
                    }`}
                    onClick={() => {
                      {
                        !itemFirst && funcOnboarding("send_ten_thousand");
                      }
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/hatching-chick.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">🐣</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          {parsedData.earn.send_coins}
                        </div>
                        {onboarding.includes("send_ten_thousand") ? (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#d4d4d4",
                            }}
                          >
                            {parsedData.earn.completed}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#fff",
                            }}
                          >
                            +30,000
                            <div
                              className="penny-icon"
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "4px",
                                marginTop: "1px",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      {itemFirst ? (
                        <div
                          style={{
                            paddingTop: "36px",
                            paddingRight: "18px",
                          }}
                        >
                          <div className="task-loader"></div>
                        </div>
                      ) : onboarding.includes("send_ten_thousand") ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          style={{
                            paddingTop: "32px",
                            paddingRight: "16px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                            stroke="#fff"
                            strokeOpacity="0.3"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16px"
                          height="16px"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            paddingTop: "38px",
                            paddingRight: "20px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="m1 1 5 5-5 5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      )}
                    </div>
                  </button>
                  <button
                    className={`earn-transparent-btn${
                      itemSecond ? `_block` : ``
                    } earn-transparent-btn_third ${
                      onboarding.includes("join_squad") ? `none` : ``
                    }`}
                    onClick={() => {
                      {
                        !itemSecond && funcOnboarding("join_squad");
                      }
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/front-facing-baby-chick.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">🐥</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          {parsedData.earn.join_squad}
                        </div>
                        {onboarding.includes("join_squad") ? (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#d4d4d4",
                            }}
                          >
                            {parsedData.earn.completed}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#fff",
                            }}
                          >
                            +30,000
                            <div
                              className="penny-icon"
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "4px",
                                marginTop: "1px",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      {itemSecond ? (
                        <div
                          style={{
                            paddingTop: "36px",
                            paddingRight: "18px",
                          }}
                        >
                          <div className="task-loader"></div>
                        </div>
                      ) : onboarding.includes("join_squad") ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          style={{
                            paddingTop: "32px",
                            paddingRight: "16px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                            stroke="#fff"
                            strokeOpacity="0.3"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16px"
                          height="16px"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            paddingTop: "38px",
                            paddingRight: "20px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="m1 1 5 5-5 5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      )}
                    </div>
                  </button>
                  <button
                    className={`earn-transparent-btn${
                      itemThird ? `_block` : ``
                    } earn-transparent-btn_fourth ${
                      onboarding.includes("ten_frens") ? `none` : ``
                    }`}
                    onClick={() => {
                      {
                        !itemThird && funcOnboarding("ten_frens");
                      }
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/baby-chick.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">🐤</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          {parsedData.earn.invite_ten_frens}
                        </div>
                        {onboarding.includes("ten_frens") ? (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#d4d4d4",
                            }}
                          >
                            {parsedData.earn.completed}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#fff",
                            }}
                          >
                            +500,000
                            <div
                              className="penny-icon"
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "4px",
                                marginTop: "1px",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      {itemThird ? (
                        <div
                          style={{
                            paddingTop: "36px",
                            paddingRight: "18px",
                          }}
                        >
                          <div className="task-loader"></div>
                        </div>
                      ) : onboarding.includes("ten_frens") ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          style={{
                            paddingTop: "32px",
                            paddingRight: "16px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                            stroke="#fff"
                            strokeOpacity="0.3"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16px"
                          height="16px"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            paddingTop: "38px",
                            paddingRight: "20px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="m1 1 5 5-5 5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      )}
                    </div>
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "40px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    {parsedData.earn.specials}
                  </div>
                  {/* <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "rgba(235, 235, 245, 0.3)",
                      marginLeft: "6px",
                    }}
                  >
                    1/1
                  </div> */}
                </div>

                <div className="earn-transparent-div earn-transparent-div_third">
                  <button
                    // className={`earn-transparent-btn_block earn-transparent-btn_second none`}
                    className={`earn-transparent-btn${
                      itemFourth ? `_block` : ``
                    } earn-transparent-btn_second ${
                      onboarding.includes("channel") ? `none` : ``
                    }`}
                    onClick={() => {
                      {
                        !itemFirst && funcOnboarding("channel");
                      }
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/waving-hand.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">👋</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          {parsedData.earn.follow_pixelcoin}
                        </div>
                        {onboarding.includes("channel") ? (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#d4d4d4",
                            }}
                          >
                            {parsedData.earn.completed}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#fff",
                            }}
                          >
                            +200,000
                            <div
                              className="penny-icon"
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "4px",
                                marginTop: "1px",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      {itemFourth ? (
                        <div
                          style={{
                            paddingTop: "36px",
                            paddingRight: "18px",
                          }}
                        >
                          <div className="task-loader"></div>
                        </div>
                      ) : onboarding.includes("channel") ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          style={{
                            paddingTop: "32px",
                            paddingRight: "16px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                            stroke="#fff"
                            strokeOpacity="0.3"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16px"
                          height="16px"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            paddingTop: "38px",
                            paddingRight: "20px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="m1 1 5 5-5 5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      )}
                    </div>
                  </button>
                  <button
                    className={`earn-transparent-btn${
                      itemFifth ? `_block` : ``
                    } earn-transparent-btn_third ${
                      onboarding.includes("twitter") ? `none` : ``
                    }`}
                    onClick={() => {
                      {
                        !itemFifth && funcOnboarding("twitter");
                      }
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/bird.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">🐦</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          {parsedData.earn.pixelcoin_on_x}
                        </div>
                        {onboarding.includes("twitter") ? (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#d4d4d4",
                            }}
                          >
                            {parsedData.earn.completed}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              textAlign: "left",
                              paddingTop: "4px",
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#fff",
                            }}
                          >
                            +100,000
                            <div
                              className="penny-icon"
                              style={{
                                width: "16px",
                                height: "16px",
                                marginLeft: "4px",
                                marginTop: "1px",
                              }}
                            ></div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      {itemFifth ? (
                        <div
                          style={{
                            paddingTop: "36px",
                            paddingRight: "18px",
                          }}
                        >
                          <div className="task-loader"></div>
                        </div>
                      ) : onboarding.includes("twitter") ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          style={{
                            paddingTop: "32px",
                            paddingRight: "16px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="M7.5 15.5L11.8318 20.7944C11.9147 20.8958 12.0726 20.8866 12.1432 20.7762L20 8.5"
                            stroke="#fff"
                            strokeOpacity="0.3"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="16px"
                          height="16px"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{
                            paddingTop: "38px",
                            paddingRight: "20px",
                            color: "rgba(235, 235, 245, 0.3)",
                          }}
                        >
                          <path
                            d="m1 1 5 5-5 5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      )}
                    </div>
                    {/* <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            paddingTop: "4px",
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          +100,000
                          <div
                            className="penny-icon"
                            style={{
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                              marginTop: "1px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          paddingTop: "38px",
                          paddingRight: "20px",
                          color: "rgba(235, 235, 245, 0.3)",
                        }}
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div> */}
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "40px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    {parsedData.earn.web_world}
                  </div>
                  {/* <div
                    style={{
                      fontSize: "22px",
                      fontWeight: "600",
                      color: "rgba(235, 235, 245, 0.3)",
                      marginLeft: "6px",
                    }}
                  >
                    1/1
                  </div> */}
                </div>
                <div className="earn-transparent-div earn-transparent-div_first">
                  <button
                    className={`earn-transparent-btn_block earn-transparent-btn_first none`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/heart.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">💓</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          Pixelcoin Fam
                        </div>

                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            paddingTop: "4px",
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          +100,000
                          <div
                            className="penny-icon"
                            style={{
                              width: "16px",
                              height: "16px",
                              marginLeft: "4px",
                              marginTop: "1px",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          paddingTop: "38px",
                          paddingRight: "20px",
                          color: "rgba(235, 235, 245, 0.3)",
                        }}
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
                <div className="IUG3">{parsedData.earn.competitive}</div>
                <div className="earn-transparent-div earn-transparent-div_first">
                  <button
                    onClick={() => {
                      navigate("/compete?type=miners");
                    }}
                    className={`earn-transparent-btn${
                      itemFirst ? `_block` : ``
                    } earn-transparent-btn_first`}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: "0px",
                        top: "0px",
                        paddingTop: "10px",
                        paddingLeft: "16px",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          width: "70px",
                          height: "70px",
                          backgroundColor:
                            "var(--palette-transparent-white-05)",
                          borderRadius: "12px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <img
                          src={require("../../assets/images/trophy.png")}
                          className="hUYa"
                        /> */}
                        <div className="hUYa">🏆</div>
                      </div>
                      <div
                        style={{
                          paddingTop: "12px",
                          paddingLeft: "86px",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "left",
                            fontSize: "17px",
                            fontWeight: "400",
                            color: "#d4d4d4",
                          }}
                        >
                          {parsedData.earn.compete}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            textAlign: "left",
                            paddingTop: "4px",
                            fontSize: "14px",
                            color: "rgba(212, 212, 212)",
                          }}
                        >
                          {parsedData.earn.earn_more_coins}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    >
                      <svg
                        width="16px"
                        height="16px"
                        viewBox="0 0 7 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          paddingTop: "38px",
                          paddingRight: "20px",
                          color: "rgba(235, 235, 245, 0.3)",
                        }}
                      >
                        <path
                          d="m1 1 5 5-5 5"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    marginTop: "24px",
                    marginBottom: "25px",
                  }}
                >
                  {parsedData.earn.built_with}
                  <div
                    style={{
                      fontSize: "12px",
                      marginLeft: "4px",
                      marginRight: "4px",
                    }}
                  >
                    😆
                  </div>
                  <div>{parsedData.earn.by}</div>
                  <div
                    onClick={() =>
                      WebApp.openTelegramLink("https://t.me/join_community")
                    }
                    style={{
                      color: "var(--pixelcoin-orange)",
                      marginLeft: "4px",
                    }}
                  >
                    {parsedData.earn.community}
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </>
  );
}
