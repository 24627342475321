import React from "react";
import CountUp from "react-countup";

export default function Counter({ start, end }) {
  return (<CountUp
  // duration={(Math.abs(end - start) <= 2) ? 2 : 1.25}
  duration={1.25}
  start={start}
  end={end}
  delay={0}
  // useEasing={true}
  separator=","
  easingFn={(t, b, c, d) => (c * t / d) * (3 / (2 + t / d)) + b}
/>)
}
