import { useState } from "react";
import getRocket from "../../utils/getRocket";

export default function ProgressBoosts(props) {
  if (props.mode === "turbo") {
    const rocketNum = props.rocketNum
    let hunderedPercent;
    let min;
    if (rocketNum < 6) {
      hunderedPercent = 6;
      min = 0;
    } else if (5 < rocketNum && rocketNum < 12) {
      hunderedPercent = 7;
      min = 6;
    } else if (11 < rocketNum && rocketNum < 19) {
      hunderedPercent = 8;
      min = 12;
    } else if (18 < rocketNum && rocketNum < 27) {
      hunderedPercent = 9;
      min = 19;
    } else if (26 < rocketNum && rocketNum < 36) {
      hunderedPercent = 10;
      min = 27;
    } else if (35 < rocketNum && rocketNum < 46) {
      hunderedPercent = 11;
      min = 36;
    } else if (45 < rocketNum && rocketNum < 57) {
      hunderedPercent = 12;
      min = 46;
    }
    else if (56 < rocketNum && rocketNum < 69) {
      hunderedPercent = 13;
      min = 57;
    } 
    else if (68 < rocketNum && rocketNum < 101) {
      hunderedPercent = 33;
      min = 69
    }
    return (
      <div className="progress-bar">
        <div
          className={`progress-bar-line progress-bar-color_type-turbo`}
          style={{
            width: rocketNum < 101 ? `${((rocketNum - min) / hunderedPercent) * 100}%` : `100%`,
          }}
        ></div>
      </div>
    );
  } else {
    return (
      <div className="progress-bar">
        <div
          className={`progress-bar-line progress-bar-color_type-gold`}
          style={{
            width: `${(props.hours / 24) * 100}%`,
          }}
        ></div>
      </div>
    );
  }
}
