import "./style.scss";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import Lottie from "lottie-react";
import Crystal from "../../assets/animation/Crystal.json";
import axios from "axios";

function Search() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const asset = params.get("asset");
  return asset;
}

export default function About() {
  const navigate = useNavigate();

  const asset = Search();

  const [showPage, setShowPage] = useState(false);

  setTimeout(() => {
    setShowPage(true);
  }, 200); // Задержка перед появлением страницы

  const [itemFirst, setItemFirst] = useState(false);

  setTimeout(() => {
    setItemFirst(true);
  }, 250); // Задержка перед появлением страницы

  const [itemSecond, setItemSecond] = useState(false);

  setTimeout(() => {
    setItemSecond(true);
  }, 500); // Задержка перед появлением страницы

  const [itemThird, setItemThird] = useState(false);

  setTimeout(() => {
    setItemThird(true);
  }, 750); // Задержка перед появлением страницы

  const [itemFourth, setItemFourth] = useState(false);

  setTimeout(() => {
    setItemFourth(true);
  }, 1000); // Задержка перед появлением страницы

  const [itemFifth, setItemFifth] = useState(false);

  setTimeout(() => {
    setItemFifth(true);
  }, 1250); // Задержка перед появлением страницы

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [welcome, setWelcome] = useState(() => {
    const storedWelcome = localStorage.getItem("welcome");
    return storedWelcome ? storedWelcome : false;
  });

  const changeWelcome = () => {
    localStorage.setItem("welcome", true);
  };

  const [starFirst, setStarFirst] = useState(false);

  setTimeout(() => {
    setStarFirst(true);
  }, 2600);

  const [starSecond, setStarSecond] = useState(false);

  setTimeout(() => {
    setStarSecond(true);
  }, 2000);

  const [starThird, setStarThird] = useState(false);

  setTimeout(() => {
    setStarThird(true);
  }, 1200);

  const [starFourth, setStarFourth] = useState(false);

  setTimeout(() => {
    setStarFourth(true);
  }, 2400);

  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    function handleResize() {
      const windowHeight = window.innerHeight;
      const documentHeight = 640;
      if (documentHeight > windowHeight) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      {welcome === "true" && (
        <BackButton onClick={() => navigate(window.history.back())} />
      )}
      <CSSTransition
        in={showPage}
        timeout={50}
        className="earn-animation"
        unmountOnExit
      >
        <div>
          <div className="page">
            <div className={`container ${shouldScroll ? `scrolling` : ``}`}>
              <div className="page__content uY4S">
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <CSSTransition
                    in={starFirst}
                    timeout={800}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_about-1 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starSecond}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_about-2 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starThird}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_about-3 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                  <CSSTransition
                    in={starFourth}
                    timeout={1200}
                    className="star-animation"
                    unmountOnExit
                  >
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                        className="stars_about-4 star-glitter"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </CSSTransition>
                </div>
                <div className="xMOh">
                  <button
                    onClick={() =>
                      WebApp.openTelegramLink("https://t.me/thepixelcoin")
                    }
                    className="about-transparent-btn"
                  >
                    <div className="Li2p">
                      <div
                        style={{
                          marginRight: "3px",
                        }}
                      >
                        <div className="B0WY">
                          <svg
                            width="29"
                            height="28"
                            viewBox="0 0 29 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.87446 12.9603C10.2414 10.622 13.8202 9.08043 15.6108 8.33568C20.7235 6.20917 21.7859 5.83975 22.4783 5.82752C22.6306 5.82486 22.9711 5.86262 23.1916 6.04161C23.5189 6.30712 23.5207 6.88344 23.4844 7.26488C23.2073 10.176 22.0085 17.2404 21.3986 20.5008C21.1405 21.8804 20.6324 22.343 20.1405 22.3883C19.0714 22.4867 18.2595 21.6817 17.224 21.003C15.6037 19.9408 14.6883 19.2796 13.1155 18.2432C11.2979 17.0454 12.4762 16.3871 13.512 15.3112C13.7831 15.0296 18.4936 10.745 18.5848 10.3563C18.5962 10.3077 18.6068 10.1265 18.4992 10.0309C18.3915 9.93521 18.2326 9.96785 18.118 9.99389C17.9555 10.0308 15.367 11.7417 10.3525 15.1266C9.61779 15.6311 8.95229 15.8769 8.35605 15.8641C7.69868 15.8499 6.43428 15.4924 5.49429 15.1868C4.34136 14.8121 3.42502 14.6139 3.50485 13.9774C3.54641 13.6458 4.00293 13.3068 4.87446 12.9603Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="JHs0">{parsedData.about.join}</div>
                    </div>
                  </button>
                  <button
                    onClick={() =>
                      WebApp.openLink("https://twitter.com/thepixelcoin", {
                        try_instant_view: true,
                      })
                    }
                    className="about-transparent-btn"
                  >
                    <div className="Li2p">
                      <div
                        style={{
                          marginRight: "3px",
                          marginBottom: "2px",
                        }}
                      >
                        <div className="TYw1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                          >
                            <path
                              d="M14.0222 11.2749L19.9785 4.5H18.5671L13.3952 10.3826L9.26437 4.5H4.5L10.7466 13.3955L4.5 20.5H5.91155L11.3732 14.2878L15.7356 20.5H20.5L14.0218 11.2749H14.0222ZM12.0889 13.4738L11.456 12.5881L6.42015 5.53974H8.5882L12.6522 11.228L13.2851 12.1137L18.5677 19.5075H16.3997L12.0889 13.4742V13.4738Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="JHs0">{parsedData.about.follow}</div>
                    </div>
                  </button>
                </div>
                <div className="Jh2w">
                  <div className="Li2p">
                    <div className="Jye1">
                      {asset === "default"
                        ? parsedData.about.title_default
                        : parsedData.about.title_squads}
                    </div>
                  </div>
                </div>
                <CSSTransition
                  in={itemFirst}
                  timeout={250}
                  className="about-slide-down"
                  unmountOnExit
                >
                  <div>
                    <div className="B0WY">
                      <div className="bvrx">
                        {asset === "default" ? (
                          <div
                            className="about-icon about-icon__pixelcoin-icon"
                            style={{
                              position: "relative",
                            }}
                          >
                            <Lottie
                              animationData={Crystal}
                              style={{
                                position: "absolute",
                                height: "24px",
                                width: "24px",
                                marginTop: "12px",
                                marginLeft: "12px",
                              }}
                            />
                          </div>
                        ) : (
                          <div className="about-icon about-icon__squad-coin"></div>
                        )}
                      </div>
                      <div className="jbux">
                        <div className="j4YN">
                          {asset === "default"
                            ? parsedData.about.card_title_play
                            : parsedData.about.card_title_squads}
                          <br />
                          <div className="Wt6t">
                            {asset === "default"
                              ? parsedData.about.card_text_play
                              : parsedData.about.card_text_squads}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CSSTransition>
                <CSSTransition
                  in={itemSecond}
                  timeout={500}
                  className="about-slide-down"
                  unmountOnExit
                >
                  <div>
                    <div className="B0WY">
                      <div className="QPvY">
                        <div
                          className={`about-icon about-icon__${
                            asset === "default" ? `lightning-icon` : `money-bag`
                          }`}
                        ></div>
                      </div>

                      <div className="QPvY">
                        <div className="j4YN">
                          {asset === "default"
                            ? parsedData.about.card_title_earn
                            : parsedData.about.card_title_bag}
                          <br />
                          <div className="Wt6t">
                            {asset === "default"
                              ? parsedData.about.card_text_earn
                              : parsedData.about.card_text_bag}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CSSTransition>
                <CSSTransition
                  in={itemThird}
                  timeout={750}
                  className="about-slide-down"
                  unmountOnExit
                >
                  <div>
                    <div className="B0WY">
                      <div className="QPvY">
                        <div
                          className={`about-icon about-icon__diamond-cup-icon`}
                        ></div>
                      </div>
                      <div className="QPvY">
                        <div className="j4YN">
                          {parsedData.about.card_title_league}
                          <br />
                          <div className="Wt6t">
                            {parsedData.about.card_text_league}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CSSTransition>
                <CSSTransition
                  in={itemFourth}
                  timeout={1000}
                  className="about-slide-down"
                  unmountOnExit
                >
                  <div>
                    <div className="B0WY">
                      <div className="QPvY">
                        <div
                          className={`about-icon about-icon__${
                            asset === "default"
                              ? `money-icon`
                              : `medal-first`
                          }`}
                        ></div>
                      </div>

                      <div className="QPvY">
                        <div className="j4YN">
                          {asset === "default"
                            ? parsedData.about.card_title_squad
                            : parsedData.about.card_title_medal}
                          <br />
                          <div className="Wt6t">
                            {asset === "default"
                              ? parsedData.about.card_text_squad
                              : parsedData.about.card_text_medal}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CSSTransition>
                <div
                  onClick={() =>
                    WebApp.openLink(parsedData.about.telegraph, {
                      try_instant_view: true,
                    })
                  }
                  className="Jh2w full-guide Z5dC"
                >
                  <div className="Li2p">
                    <div className="qLoL">{parsedData.about.full_guide}</div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    changeWelcome();
                    navigate(asset === "default" ? "/" : window.history.back());
                  }}
                  className="about-footer-btn"
                >
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">
                        {asset === "default"
                          ? parsedData.about.play
                          : parsedData.about.good}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
}
