import "./style.scss";
import { useState, useEffect, useRef, useId } from "react";
import axios from "axios";
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import BlackTransparent from "../../components/BlackTransparent";
import { CSSTransition } from "react-transition-group";
import addCommas from "../../utils/addCommas";
import { BackButton, MainButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import coinImage from "../../assets/images/coin-min.svg";
import getLeague from "../../utils/getLeague";
import abbreNumbers from "../../utils/abbreNumbers";
import TradePhoto from "../../components/TradePhoto";

export default function ReceiverSearch({ userId }) {
  const navigate = useNavigate();

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [showPage, setShowPage] = useState(true);

  const [frens, setFrens] = useState([]);

  useEffect(() => {
    const fetchDataFrens = async () => {
      try {
        const response = await axios.get(
          `https://pixelcoin.site:433/users/${userId}/frens_list`
        );
        setFrens(response.data);
        setShowPage(true);
      } catch (error) {
        console.error("Error fetching frens:", error);
      }
    };

    fetchDataFrens();
  }, []);

  const [shouldScroll, setShouldScroll] = useState(true);

  // useEffect(() => {
  //   function handleResize() {
  //     const windowHeight = window.innerHeight;
  //     const documentHeight = 640;
  //     if (documentHeight > windowHeight) {
  //       setShouldScroll(true);
  //     } else {
  //       setShouldScroll(false);
  //     }
  //   }

  //   handleResize();

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const [inputValue, setInputValue] = useState("");
  const [inputId, setInputId] = useState(undefined);
  const [loader, setLoader] = useState(false);

  const [receiver, setReceiver] = useState(0);

  const fetchData = async (userId) => {
    try {
      const response = await axios.get(
        `https://pixelcoin.site:433/users/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching:", error);
      return null;
    }
  };

  const pasteInputValue = async () => {
    clearTimeout(inputId);
    setReceiver(0);
    const clipboardText = await navigator.clipboard.readText();
    if (/^[\d]*$/.test(clipboardText) && clipboardText !== "") {
      setInputValue(clipboardText);
      setLoader(true);
      setInputId(
        setTimeout(async () => {
          setLoader(false);
          const userData = await fetchData(clipboardText);
          if (userData && parseInt(clipboardText) !== userId) {
            setReceiver(userData);
          } else {
            setReceiver("error");
          }
        }, 1500)
      );
    } else if (clipboardText === "") {
      setInputValue(clipboardText);
      setReceiver(0);
    }
  };

  const deleteInputValue = () => {
    setReceiver(0);
    setInputValue("");
  };

  const handleInputChange = async (event) => {
    clearTimeout(inputId);
    setReceiver(0);
    const { value } = event.target;
    if (/^[\d]*$/.test(value) && value !== "") {
      setInputValue(value);
      setLoader(true);
      setInputId(
        setTimeout(async () => {
          setLoader(false);
          const userData = await fetchData(value);
          if (userData && parseInt(value) !== userId) {
            setReceiver(userData);
          } else {
            setReceiver("error");
          }
        }, 1500)
      );
    } else if (value === "") {
      setInputValue(value);
      setReceiver(0);
    }
  };

  return (
    <>
      <BackButton onClick={() => navigate("/trade")} />
      {inputValue !== "" && (
        <MainButton
          text="Continue"
          color={
            receiver === "error" && inputValue !== "" && !loader
              ? `rgba(235, 235, 245, 0.3)`
              : `rgba(0, 122, 255, 1)`
          }
          textColor="#fff"
          disabled={receiver === "error" || loader ? true : false}
          progress={loader ? true : false}
          onClick={() => navigate(`/send?asset=${receiver.user_id}`)}
        />
      )}
      <div
        className="page"
        style={{
          overflow: "hidden",
        }}
      >
        <div className={`container scrolling`}>
          <div
            className="page__content"
            // style={{
            //   opacity: isSend ? "0.6" : "",
            //   transitionDuration: isSend ? "0.3s" : "",
            //   pointerEvents: isSend ? "none" : "",
            // }}
          >
            <CSSTransition
              in={showPage}
              timeout={50}
              className="trade-animation"
              unmountOnExit
            >
              <div>
                <div className="input_container__aDhZI">
                  <label className="styles_root__aDhZI">
                    <svg
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="styles_icon__tlXf7"
                    >
                      <path
                        d="M3.87109 10.4463C3.87109 9.5332 4.04264 8.67822 4.38574 7.88135C4.72884 7.07894 5.20475 6.37337 5.81348 5.76465C6.4222 5.15592 7.125 4.68001 7.92188 4.33691C8.72428 3.99382 9.58203 3.82227 10.4951 3.82227C11.4082 3.82227 12.2632 3.99382 13.0601 4.33691C13.8625 4.68001 14.568 5.15592 15.1768 5.76465C15.7855 6.37337 16.2614 7.07894 16.6045 7.88135C16.9476 8.67822 17.1191 9.5332 17.1191 10.4463C17.1191 11.2044 16.9974 11.9238 16.7539 12.6045C16.516 13.2852 16.1839 13.9022 15.7578 14.4556L19.8169 18.5396C19.9054 18.6281 19.9718 18.7305 20.0161 18.8467C20.0659 18.9629 20.0908 19.0874 20.0908 19.2202C20.0908 19.4028 20.0493 19.5688 19.9663 19.7183C19.8888 19.8677 19.7782 19.9839 19.6343 20.0669C19.4904 20.1554 19.3244 20.1997 19.1362 20.1997C19.0034 20.1997 18.8761 20.1748 18.7544 20.125C18.6382 20.0807 18.5303 20.0116 18.4307 19.9175L14.3467 15.8252C13.8044 16.2126 13.2067 16.5169 12.5537 16.7383C11.9007 16.9596 11.2145 17.0703 10.4951 17.0703C9.58203 17.0703 8.72428 16.8988 7.92188 16.5557C7.125 16.2126 6.4222 15.7367 5.81348 15.1279C5.20475 14.5192 4.72884 13.8164 4.38574 13.0195C4.04264 12.2171 3.87109 11.3594 3.87109 10.4463ZM5.29053 10.4463C5.29053 11.1657 5.42334 11.8408 5.68896 12.4717C5.96012 13.097 6.33366 13.6476 6.80957 14.1235C7.29102 14.5994 7.8444 14.973 8.46973 15.2441C9.10059 15.5153 9.77572 15.6509 10.4951 15.6509C11.2145 15.6509 11.8869 15.5153 12.5122 15.2441C13.1431 14.973 13.6965 14.5994 14.1724 14.1235C14.6483 13.6476 15.0218 13.097 15.293 12.4717C15.5641 11.8408 15.6997 11.1657 15.6997 10.4463C15.6997 9.72689 15.5641 9.05452 15.293 8.4292C15.0218 7.79834 14.6483 7.24495 14.1724 6.76904C13.6965 6.2876 13.1431 5.91406 12.5122 5.64844C11.8869 5.37728 11.2145 5.2417 10.4951 5.2417C9.77572 5.2417 9.10059 5.37728 8.46973 5.64844C7.8444 5.91406 7.29102 6.2876 6.80957 6.76904C6.33366 7.24495 5.96012 7.79834 5.68896 8.4292C5.42334 9.05452 5.29053 9.72689 5.29053 10.4463Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    <input
                      placeholder="Enter address"
                      onChange={handleInputChange}
                      value={inputValue}
                      autoComplete="off"
                      inputMode="decimal"
                      className="styles_input__bJkBB styles_iconIncluded__2srZt"
                    />
                    
                    {inputValue !== "" ? (
                      <div
                      onClick={deleteInputValue}
                      className="style_actionHolder__grO6j"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="styles_clearIcon__UmO-J"
                        tabindex="0"
                      >
                        <path
                          d="M9.5 19C14.1944 19 18 15.1943 18 10.5C18 5.80566 14.1944 2 9.5 2C4.80557 2 1 5.80566 1 10.5C1 15.1943 4.80557 19 9.5 19ZM13.2657 6.73438C13.5781 7.04663 13.5781 7.55322 13.2657 7.86572L10.6314 10.5L13.2657 13.1343C13.5781 13.4468 13.5781 13.9534 13.2657 14.2656C12.9533 14.5781 12.4467 14.5781 12.1343 14.2656L9.5 11.6313L6.86569 14.2656C6.55328 14.5781 6.04675 14.5781 5.73431 14.2656C5.42191 13.9534 5.42191 13.4468 5.73431 13.1343L8.36862 10.5L5.73431 7.86572C5.42191 7.55322 5.42191 7.04663 5.73431 6.73438C6.04672 6.42188 6.55325 6.42188 6.86569 6.73438L9.5 9.36865L12.1343 6.73438C12.4467 6.42188 12.9533 6.42188 13.2657 6.73438Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                    ) : (
                      <></>
                    )}
                  </label>
                </div>
                {inputValue !== "" && receiver !== 0 && (
                  <>
                    <ul className="trade_skeleton">
                      {receiver !== "error" ? (
                        <li
                          onClick={() =>
                            navigate(`/send?asset=${receiver.user_id}`)
                          }
                          className="trade_skeleton__wrapper"
                        >
                          <TradePhoto
                            url_photo={receiver.url_photo}
                            first_name={receiver.first_name}
                          />

                          <div className="trade_element__box1">
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div>{receiver.first_name}</div>
                            </div>
                          </div>
                          <div className="trade_element__box2">
                            <div className="Li2p">
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    className="penny-icon vBwQ"
                                    style={{
                                      marginTop: "8px",
                                    }}
                                  ></div>
                                  <div
                                    style={{
                                      color: "#fff",
                                      marginTop: "4px",
                                      marginLeft: "4px",
                                    }}
                                  >
                                    {addCommas(receiver.score)}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li>
                          {/* <TradePhoto
                            url_photo={receiver.url_photo}
                            first_name={receiver.first_name}
                          /> */}
                          <div className="trade_skeleton__circle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              fill="none"
                              viewBox="0 0 28 28"
                              style={{
                                color: "#fff",
                              }}
                              // style={{color: var(--tg-theme-subtitle-text-color)}}
                            >
                              <path
                                fill="currentColor"
                                fill-rule="evenodd"
                                d="M12.78 3.26c-.89.396-1.572 1.577-2.937 3.94L3.435 18.3c-1.364 2.363-2.046 3.544-1.945 4.514a3 3 0 0 0 1.22 2.113c.79.573 2.153.573 4.882.573h12.817c2.728 0 4.092 0 4.88-.573a3 3 0 0 0 1.221-2.113c.102-.97-.58-2.151-1.944-4.514L18.157 7.2c-1.364-2.363-2.046-3.544-2.937-3.94a3 3 0 0 0-2.44 0m2.301 7.34-.114 6.45a.967.967 0 0 1-1.933 0l-.114-6.45a1.08 1.08 0 1 1 2.161 0m.17 9.9a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>

                          <div className="trade_element__box1">
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <div>Invalid Address</div>
                            </div>
                          </div>
                          <div className="trade_element__box2">
                            <div className="Li2p">
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "#fff",
                                      marginTop: "4px",
                                      // marginLeft: "4px",
                                    }}
                                  >
                                    Enter other address
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </>
                )}
                {frens.length > 0 && inputValue === "" && (
                  <ul className="trade_skeleton">
                    {frens.map((user, index) => (
                      <li
                        onClick={() => navigate(`/send?asset=${user.user_id}`)}
                        key={index}
                        className="trade_skeleton__wrapper"
                      >
                        <TradePhoto
                          url_photo={user.url_photo}
                          first_name={user.first_name}
                        />
                        <div className="trade_element__box1">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div>{user.first_name}</div>
                          </div>
                        </div>
                        <div className="trade_element__box2">
                          <div className="Li2p">
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div
                                  className="penny-icon vBwQ"
                                  style={{
                                    marginTop: "8px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    color: "#fff",
                                    marginTop: "4px",
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(user.score)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {index !== frens.length - 1 && (
                          <div className="trade_element__box3"></div>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
                {/* <div onClick={openReceive} className="send-trade-btn">
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">Continue</div>
                    </div>
                  </div>
                </div> */}
              </div>
            </CSSTransition>
          </div>
          {/* <div className={`notification ${isVisible ? "visible" : ""}`}>
            <div>
              <div
                style={{
                  width: "100%",
                  borderRadius: "12px",
                  padding: "8px",
                  background: "rgba(28, 28, 30, .9)",
                  alignItems: "center",
                  boxSizing: "border-box",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                  transition: "background ease",
                  transitionDuration: "0.3s",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="none"
                  viewBox="0 0 28 28"
                >
                  <rect
                    width="14"
                    height="14"
                    x="9"
                    y="6"
                    fill="white"
                    rx="3"
                  ></rect>
                  <rect
                    width="15.6"
                    height="15.6"
                    x="4.2"
                    y="9.2"
                    fill="white"
                    stroke="rgba(28, 28, 30, 0.9)"
                    strokeWidth="1.6"
                    rx="3.8"
                  ></rect>
                </svg>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#fff",
                    marginLeft: "4px",
                  }}
                >
                  Address is copied to clipboard.
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className={`details ${isSend ? "visible" : ""}`}>
            <div
              style={{
                width: "100%",
                borderRadius: "25px 25px 0 0",
                background: "rgba(28, 28, 30, .99)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                cursor: "pointer",
                transition: "background ease",
                transitionDuration: "0.3s",
                willChange: "transition",
                paddingBlock: "32px",
                position: "relative",
                padding: "122px 16px",
              }}
            >
              <button onClick={closeSend} className="backdrop-close">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="30"
                    height="30"
                    rx="15"
                    fill="rgba(123, 123, 123, 0.24)"
                  ></rect>
                  <path
                    d="M9.638 20.377a.834.834 0 0 1-.241-.415 1.046 1.046 0 0 1 0-.49.87.87 0 0 1 .24-.406l4.043-4.05-4.042-4.043a.87.87 0 0 1-.241-.407 1.012 1.012 0 0 1 0-.482.835.835 0 0 1 .24-.423.883.883 0 0 1 .424-.24.947.947 0 0 1 .482.008c.16.038.298.113.415.224L15 13.703l4.043-4.05a.914.914 0 0 1 .888-.224c.16.038.301.116.423.232a.835.835 0 0 1 .24.424c.04.16.04.32 0 .48a.884.884 0 0 1-.24.416l-4.034 4.034 4.034 4.05a.834.834 0 0 1 .24.416 1.01 1.01 0 0 1 0 .481.834.834 0 0 1-.24.415.983.983 0 0 1-.423.241.979.979 0 0 1-.474 0 .862.862 0 0 1-.415-.232L15 16.335l-4.043 4.05a.862.862 0 0 1-.415.233c-.16.039-.32.039-.481 0a.883.883 0 0 1-.423-.24Z"
                    fill="#aaaaaa"
                  ></path>
                </svg>
              </button>
              <div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    height: "48px",
                    width: "48px",
                    background: "green",
                    borderRadius: "50%",
                    marginTop: "16px",
                    marginLeft: "16px",
                  }}
                ></div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    fontSize: "17px",
                    fontWeight: "600",
                    textAlign: "left",
                    color: "#fff",
                    marginTop: "17px",
                    marginLeft: "76px",
                  }}
                >
                  Maskceo
                </div>
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    fontSize: "16px",
                    fontWeight: "500",
                    textAlign: "left",
                    color: "#fff",
                    marginTop: "40px",
                    marginLeft: "76px",
                  }}
                >
                  Получатель
                </div>
                <img
                  src={require("../../assets/images/rocket.png")}
                  style={{
                    width: "115px",
                    height: "115px",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "70px",
                    marginBottom: "28px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "28px",
                    fontWeight: "600",
                    color: "#fff",
                    marginBottom: "6px",
                  }}
                >
                  {parsedData.boost.turbo}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    textAlign: "center",
                    padding: "0 16px",
                    marginBottom: "20px",
                  }}
                >
                  <>
                    {parsedData.boost.turbo_description1}
                    <br />
                    {parsedData.boost.turbo_description2}
                  </>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#fff",
                    textAlign: "center",
                    padding: "0 16px",
                    marginBottom: "30px",
                  }}
                >
                  <div
                    className="penny-icon"
                    style={{
                      height: "16px",
                      width: "16px",
                      marginTop: "2px",
                      marginRight: "4px",
                    }}
                  ></div>
                  {parsedData.boost.free}
                </div>
                <div
                  style={{
                    // display: "flex",
                    // marginTop: "70px",
                    // marginBottom: "200px",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    marginTop: "100px",
                    marginLeft: "16px",
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#fff",
                  }}
                >
                  Сумма
                </div>
                <div>
                  <nav className="styles_root__3z53k">
                    <div className="style_root__9vqgd">
                      <label className="styles_root__aDhZI">
                        <svg
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="styles_icon__tlXf7"
                        >
                          <path
                            d="M3.87109 10.4463C3.87109 9.5332 4.04264 8.67822 4.38574 7.88135C4.72884 7.07894 5.20475 6.37337 5.81348 5.76465C6.4222 5.15592 7.125 4.68001 7.92188 4.33691C8.72428 3.99382 9.58203 3.82227 10.4951 3.82227C11.4082 3.82227 12.2632 3.99382 13.0601 4.33691C13.8625 4.68001 14.568 5.15592 15.1768 5.76465C15.7855 6.37337 16.2614 7.07894 16.6045 7.88135C16.9476 8.67822 17.1191 9.5332 17.1191 10.4463C17.1191 11.2044 16.9974 11.9238 16.7539 12.6045C16.516 13.2852 16.1839 13.9022 15.7578 14.4556L19.8169 18.5396C19.9054 18.6281 19.9718 18.7305 20.0161 18.8467C20.0659 18.9629 20.0908 19.0874 20.0908 19.2202C20.0908 19.4028 20.0493 19.5688 19.9663 19.7183C19.8888 19.8677 19.7782 19.9839 19.6343 20.0669C19.4904 20.1554 19.3244 20.1997 19.1362 20.1997C19.0034 20.1997 18.8761 20.1748 18.7544 20.125C18.6382 20.0807 18.5303 20.0116 18.4307 19.9175L14.3467 15.8252C13.8044 16.2126 13.2067 16.5169 12.5537 16.7383C11.9007 16.9596 11.2145 17.0703 10.4951 17.0703C9.58203 17.0703 8.72428 16.8988 7.92188 16.5557C7.125 16.2126 6.4222 15.7367 5.81348 15.1279C5.20475 14.5192 4.72884 13.8164 4.38574 13.0195C4.04264 12.2171 3.87109 11.3594 3.87109 10.4463ZM5.29053 10.4463C5.29053 11.1657 5.42334 11.8408 5.68896 12.4717C5.96012 13.097 6.33366 13.6476 6.80957 14.1235C7.29102 14.5994 7.8444 14.973 8.46973 15.2441C9.10059 15.5153 9.77572 15.6509 10.4951 15.6509C11.2145 15.6509 11.8869 15.5153 12.5122 15.2441C13.1431 14.973 13.6965 14.5994 14.1724 14.1235C14.6483 13.6476 15.0218 13.097 15.293 12.4717C15.5641 11.8408 15.6997 11.1657 15.6997 10.4463C15.6997 9.72689 15.5641 9.05452 15.293 8.4292C15.0218 7.79834 14.6483 7.24495 14.1724 6.76904C13.6965 6.2876 13.1431 5.91406 12.5122 5.64844C11.8869 5.37728 11.2145 5.2417 10.4951 5.2417C9.77572 5.2417 9.10059 5.37728 8.46973 5.64844C7.8444 5.91406 7.29102 6.2876 6.80957 6.76904C6.33366 7.24495 5.96012 7.79834 5.68896 8.4292C5.42334 9.05452 5.29053 9.72689 5.29053 10.4463Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <input className="styles_input__bJkBB styles_callout__z6vfq styles_iconIncluded__2srZt" placeholder="Search" autoComplete="off" enterKeyHint="search"/>
                      </label>
                      <button className="">
                        <p className="">
                          Cancel
                        </p>
                      </button>
                    </div>
                  </nav>
                </div>
                <button className="backdrop-confirm-transparent"></button>
                <div className="backdrop-confirm">
                  <div
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      color: "#fff",
                    }}
                  >
                    Перевести
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

{
  /* <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="Yi28"
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        marginTop: "6px",
                        marginLeft: "10px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                          style={{
                            opacity: ".6",
                          }}
                        />
                      </svg>
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                      }}
                    ></div>
                    <div
                      style={{
                        fontSize: "17px",
                        fontWeight: "700",
                        color: "#fff",
                      }}
                    >
                      Exclusive for
                    </div>
                    <div className="imageCoin-trade"></div>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#fff",
                        marginLeft: "2px",
                        marginTop: "2px",
                      }}
                    >
                      10K+
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        marginTop: "6px",
                        marginRight: "10px",
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="11"
                        viewBox="0 0 10 11"
                        fill="none"
                      >
                        <path
                          d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                          fill="white"
                          style={{
                            opacity: ".6",
                          }}
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "80px",
                    fontFamily: "var(--font-display-title)",
                    // display: "inline-block",
                    verticalAlign: "middle",
                    width: "auto",
                    fontSize: "48px",
                    lineHeight: "48px",
                    fontWeight: "700",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Pixelcoin
                </div>
                <div
                  style={{
                    // display: "inline-block",
                    fontFamily: "var(--font-display-title)",
                    verticalAlign: "middle",
                    fontSize: "48px",
                    width: "auto",
                    lineHeight: "48px",
                    fontWeight: "700",
                    textAlign: "center",
                    color: "#fff",
                  }}
                >
                  Pre-Market
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    // fontSize: "16px",
                    // fontWeight: "500",
                    color: "#ffffff80",
                  }}
                >
                  Trade Pixelcoins via platform,
                  <br />
                  before token is issued
                </div>

                <div
                  style={{
                    marginTop: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#ffffff80",
                  }}
                >
                  Exchange Coins to $PX
                  <br />
                  after token generation event.
                  <br />
                  Or convert in-game balance directly.
                </div>
                <div
                  onClick={() => {
                    navigate("/about?asset=default");
                  }}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#42a0ff",
                  }}
                >
                  Learn more
                </div>
                <div onClick={openSend} className="send-trade-btn">
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">Pixelcoin → Send</div>
                    </div>
                  </div>
                </div>
                <div onClick={openReceive} className="receive-trade-btn">
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">Receive Pixelcoins</div>
                    </div>
                  </div>
                </div>
              </div> */
}
{
  /* <div className={`details ${isReceive ? "visible" : ""}`}>
            <div
              style={{
                width: "100%",
                borderRadius: "25px 25px 0 0",
                background: "rgba(28, 28, 30, .99)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                cursor: "pointer",
                transition: "background ease",
                transitionDuration: "0.3s",
                willChange: "transition",
                paddingBlock: "32px",
                position: "relative",
                padding: "0 16px",
              }}
            >
              <button onClick={closeReceive} className="backdrop-close">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="30"
                    height="30"
                    rx="15"
                    fill="rgba(123, 123, 123, 0.24)"
                  ></rect>
                  <path
                    d="M9.638 20.377a.834.834 0 0 1-.241-.415 1.046 1.046 0 0 1 0-.49.87.87 0 0 1 .24-.406l4.043-4.05-4.042-4.043a.87.87 0 0 1-.241-.407 1.012 1.012 0 0 1 0-.482.835.835 0 0 1 .24-.423.883.883 0 0 1 .424-.24.947.947 0 0 1 .482.008c.16.038.298.113.415.224L15 13.703l4.043-4.05a.914.914 0 0 1 .888-.224c.16.038.301.116.423.232a.835.835 0 0 1 .24.424c.04.16.04.32 0 .48a.884.884 0 0 1-.24.416l-4.034 4.034 4.034 4.05a.834.834 0 0 1 .24.416 1.01 1.01 0 0 1 0 .481.834.834 0 0 1-.24.415.983.983 0 0 1-.423.241.979.979 0 0 1-.474 0 .862.862 0 0 1-.415-.232L15 16.335l-4.043 4.05a.862.862 0 0 1-.415.233c-.16.039-.32.039-.481 0a.883.883 0 0 1-.423-.24Z"
                    fill="#aaaaaa"
                  ></path>
                </svg>
              </button>
              <div>
                <img
                  src={coinImage}
                  style={{
                    width: "115px",
                    height: "115px",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "70px",
                    marginBottom: "28px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "28px",
                    fontWeight: "600",
                    color: "#fff",
                    marginBottom: "6px",
                  }}
                >
                  Receive
                </div>
                <div
                  onClick={copyAddress}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "rgba(255, 255, 255, 0.5)",
                    textAlign: "center",
                    fontFamily: "monospace",
                    padding: "0 16px",
                    marginBottom: "20px",
                  }}
                >
                  <>
                    {userId}
                  </>
                </div>
                <button
                  onClick={() => {
                    WebApp.openTelegramLink(
                      `https://t.me/share/url?text=Address: ${userId}&url=https://t.me/pixelcoinbot?start=rp_${userId}`
                    );
                  }}
                  className="backdrop-confirm-trade"
                >
                  <div className="backdropConfirm-share">
                    <div style={{
                      marginRight: "4px",
                      marginBottom: "-4px"
                    }}>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 16.5V17.5C20 19.1569 18.6569 20.5 17 20.5H7C5.34315 20.5 4 19.1569 4 17.5V7.5C4 5.84315 5.34315 4.5 7 4.5H8"
                          stroke="#EBEBF5"
                          stroke-opacity="0.6"
                          stroke-width="2"
                          stroke-linecap="round"
                        ></path>
                        <path
                          d="M13 4.5H16H19.999C19.9996 4.5 20 4.50045 20 4.501V8.5V11.5"
                          stroke="#EBEBF5"
                          stroke-opacity="0.6"
                          stroke-width="2"
                          stroke-linecap="round"
                        ></path>
                        <path
                          d="M11.2929 11.7929C10.9024 12.1834 10.9024 12.8166 11.2929 13.2071C11.6834 13.5976 12.3166 13.5976 12.7071 13.2071L11.2929 11.7929ZM19.2929 3.79289L11.2929 11.7929L12.7071 13.2071L20.7071 5.20711L19.2929 3.79289Z"
                          fill="#EBEBF5"
                          fill-opacity="0.6"
                        ></path>
                      </svg>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      Share
                    </div>
                  </div>
                </button>
                <button
                  onClick={copyAddress}
                  className="backdrop-confirm-trade"
                >
                  <div className="backdropConfirm-receive">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        fill="none"
                        viewBox="0 0 28 28"
                      >
                        <rect
                          width="14"
                          height="14"
                          x="9"
                          y="6"
                          fill="white"
                          rx="3"
                        ></rect>
                        <rect
                          width="15.6"
                          height="15.6"
                          x="4.2"
                          y="9.2"
                          fill="white"
                          stroke="#007aff"
                          stroke-width="1.6"
                          rx="3.8"
                        ></rect>
                      </svg>
                    </div>
                    <div
                      style={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#fff",
                      }}
                    >
                      Copy address
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div> */
}
