export default function abbreNumbers(num) {
    if (num >= 1000 && num < 1000000) {
        num = parseFloat((num / 1000).toFixed(3)) + "K"
    } else if (num >= 1000000 && num < 1000000000) {
        num = parseFloat((num / 1000000).toFixed(3)) + "M"
    } else if (num >= 1000000000) {
        num = parseFloat((num / 1000000000).toFixed(3)) + "B"
    } else {
        return num;
    }
    return num;
}