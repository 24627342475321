import "./style.scss";
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import BlackTransparent from "../../components/BlackTransparent";
import { CSSTransition } from "react-transition-group";
import addCommas from "../../utils/addCommas";
import { BackButton, MainButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import coinImage from "../../assets/images/coin-min.svg";
import getLeague from "../../utils/getLeague";
import abbreNumbers from "../../utils/abbreNumbers";
import getColor from "../../utils/getColor";

function sendMessageToUser(userId, inputBalance) {
  // Ваш API ключ и URL для обращения к API Telegram
  const apiKey = "6967469657:AAFW2-pv_1HXN1NQ1jflalr07MHz_Mm7QJU";
  const telegramApiUrl = `https://api.telegram.org/bot${apiKey}/sendMessage`;

  // Создание объекта для отправки данных
  const formData = new FormData();
  formData.append("chat_id", userId);
  formData.append(
    "text",
    `✅ You’ve received: ${addCommas(inputBalance)} Pixelcoins`
  );

  // Опции запроса
  const requestOptions = {
    method: "POST",
    body: formData,
  };

  // Отправка запроса к API Telegram
  fetch(telegramApiUrl, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      console.log("Message sent:", data);
      // Здесь вы можете добавить обработку успешной отправки сообщения
    })
    .catch((error) => {
      console.error("Error sending message:", error);
      // Здесь вы можете добавить обработку ошибок при отправке сообщения
    });
}

function Search() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const asset = params.get("asset");
  return asset;
}

export default function Send({
  userId,
  score,
  updateScore,
  balance,
  updateBalance,
}) {
  const navigate = useNavigate();

  const result = Search();

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [showPage, setShowPage] = useState(true);

  const [hasError, setHasError] = useState(false);

  const [receiver, setReceiver] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        axios.get(`https://pixelcoin.site:433/users/${result}`).then((response) => {
          setReceiver(response.data);
          setShowPage(true);
        });
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };

    fetchData();
  }, []);
  const [shouldScroll, setShouldScroll] = useState(true);

  // useEffect(() => {
  //   function handleResize() {
  //     const windowHeight = window.innerHeight;
  //     const documentHeight = 640;
  //     if (documentHeight > windowHeight) {
  //       setShouldScroll(true);
  //     } else {
  //       setShouldScroll(false);
  //     }
  //   }

  //   handleResize();

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const [searchParams, setSearchParams] = useSearchParams();
  const textAreaRef = useRef();

  const [error, setError] = useState(false);

  // const onKeyDown = (event) => {
  //   const { value } = event.target;
  //   if (/^[\d]*$/.test(event.key) || event.key === "Backspace") {
  //     // setInputValue(value.key)
  //   }  else {
  //     event.preventDefault()
  //   }
  //   // console.log(/^[\d]*$/.test(event.key))
  // }

  // Обновляем URL и состояние ввода при изменении пользователем

  // const [inputId, setInputId] = useState(undefined);

  // const handleInputChange = (event) => {
  //   clearTimeout(inputId);
  //   const { value } = event.target;
  //   setError(false);
  //   if (parseInt(value) === 0 && inputValue === "") {
  //     return;
  //   } else {
  //     // Проверяем, является ли введенное значение числом
  //     if (/^[\d]*$/.test(value) && value < 1000000000) {
  //       setInputValue(value);
  //       setSearchParams({ value: value || "0", asset: result }, "replace");
  //       if (value > score + balance) {
  //         setInputId(
  //           setTimeout(() => {
  //             setError(true);
  //           }, 1000)
  //         );
  //       }
  //     }
  //   }
  // };

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const [progress, setProgress] = useState(false);

  const sendFunds = () => {
    if (parseInt(val.replaceAll(",", "")) <= score + balance) {
      if (parseInt(val.replaceAll(",", "")) <= balance) {
        setProgress(true);
        updateBalance(balance - parseInt(val.replaceAll(",", "")));
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/received`)
          .then((response) => {
            const newReceived =
              response.data.received + parseInt(val.replaceAll(",", ""));
            // Возвращаем результат для использования в следующем then
            axios.put(`https://pixelcoin.site:433/users/${userId}/received`, {
              received: newReceived,
            });
          });
        axios
          .get(`https://pixelcoin.site:433/users/${result}/balance`)
          .then((response) => {
            const newBalance =
              response.data.balance + parseInt(val.replaceAll(",", ""));
            // Возвращаем результат для использования в следующем then
            axios.put(`https://pixelcoin.site:433/users/${result}/balance`, {
              balance: newBalance,
            });
          })
          .then(() => {
            sendMessageToUser(result, parseInt(val.replaceAll(",", "")));
            navigate("/complete");
          })
          .catch((error) => {
            console.error("Ошибка:", error);
          });
      } else {
        setProgress(true);
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/score`)
          .then((response) => {
            const newScore =
              response.data.score -
              (parseInt(val.replaceAll(",", "")) - balance);
            updateScore(newScore);
            axios.put(`https://pixelcoin.site:433/users/${userId}/score`, {
              score: newScore,
            });
          });
        updateBalance(0);
        axios
          .get(`https://pixelcoin.site:433/users/${userId}/received`)
          .then((response) => {
            const newReceived =
              response.data.received + parseInt(val.replaceAll(",", ""));
            axios.put(`https://pixelcoin.site:433/users/${userId}/received`, {
              received: newReceived,
            });
          });
        axios
          .get(`https://pixelcoin.site:433/users/${result}/balance`)
          .then((response) => {
            const newBalance =
              response.data.balance + parseInt(val.replaceAll(",", ""));
            // Возвращаем результат для использования в следующем then
            axios.put(`https://pixelcoin.site:433/users/${result}/balance`, {
              balance: newBalance,
            });
          })
          .then(() => {
            sendMessageToUser(result, parseInt(val.replaceAll(",", "")));
            navigate("/complete");
          })
          .catch((error) => {
            console.error("Ошибка:", error);
          });
      }
    }
  };

  // const handleInputChange = (event) => {
  //   clearTimeout(inputId);
  //   const { value } = event.target;
  //   setError(false);
  //   if (parseInt(value) === 0 && inputValue === "") {
  //     return;
  //   } else {
  //     // Проверяем, является ли введенное значение числом
  //     if (/^[\d]*$/.test(value) && value < 1000000000) {
  //       setInputValue(value);
  //       setSearchParams({ value: value || "0", asset: result }, "replace");
  //       if (value > score + balance) {
  //         setInputId(
  //           setTimeout(() => {
  //             setError(true);
  //           }, 1000)
  //         );
  //       }
  //     }
  //   }
  // };

  const [inputId, setInputId] = useState(undefined);

  const [val, setVal] = useState("");
  const onChange = (e) => {
    clearTimeout(inputId);
    const { value } = e.target;
    setError(false);
    if (parseInt(value.replaceAll(",", "")) === 0 && val === "") {
      return;
    }
    console.log(parseInt(value.replaceAll(",", "")));
    if (parseInt(value.replaceAll(",", "")) >= 1000000000) {
      return;
    }
    if (parseInt(value.replaceAll(",", "")) > score + balance) {
      setInputId(
        setTimeout(() => {
          setError(true);
        }, 1000)
      );
    }

    e.preventDefault();
    setVal(value);
  };
  const onKeyDown = (e) => {
    if (e.shiftKey === true) {
      if (e.which == 9) {
        return true;
      }
      e.preventDefault();
    }
    if (e.which > 57) {
      e.preventDefault();
    }
    if (e.which == 32) {
      e.preventDefault();
    }
    return true;
  };
  const formatValue = (value) => {
    return value
      .toString()
      .replaceAll(",", "")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <>
      <BackButton onClick={() => navigate("/receiver-search")} />
      <MainButton
        text="Send"
        color={
          parseInt(val.replaceAll(",", "")) > score + balance ||
          parseInt(val.replaceAll(",", "")) === 0 ||
          parseInt(val.replaceAll(",", "")) === ""
            ? `rgba(235, 235, 245, 0.3)`
            : `rgba(0, 122, 255, 1)`
        }
        textColor="#fff"
        disabled={error ? true : false}
        progress={progress ? true : false}
        onClick={() => sendFunds()}
      />
      <div
        className="page"
        style={{
          overflow: "hidden",
        }}
      >
        <div className={`container`}>
          <div className="page__content">
            <CSSTransition
              in={showPage}
              timeout={50}
              className="trade-animation"
              unmountOnExit
            >
              <div>
                <form className={``}>
                  <div className="send-unbr send-e1Tl">
                    <div
                      onClick={() => {
                        navigate("/receiver-search");
                      }}
                      className="send-_Nat send-yhvK"
                    >
                      <label className="send-dv_2 send-KU0V" htmlFor="input">
                        <div className="send-PW2J">
                          <div className="send-tt_o">
                            <div className="send-spEm">
                              {receiver.url_photo !== "" && !hasError ? (
                                <img
                                  src={receiver.url_photo}
                                  style={{
                                    height: "32px",
                                    width: "32px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                  }}
                                  onError={() => {
                                    setHasError(true);
                                  }}
                                />
                              ) : (
                                <div
                                  style={{
                                    height: "32px",
                                    width: "32px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    borderRadius: "50%",
                                    background: getColor(
                                      receiver.first_name.substring(0, 1)
                                    ),
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#fff",
                                    }}
                                  >
                                    {receiver.first_name
                                      .substring(0, 2)
                                      .toUpperCase()}
                                  </div>
                                </div>
                              )}
                            </div>
                            <span className="send-TlJ_ send-r68T">
                              <span>Send to</span>
                              <b>{receiver.first_name}</b>
                            </span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="send-CpWq">
                      <div className="send-_m3Z">
                        <div className="send-JUMA">
                          <div
                            style={{
                              maxWidth: "100%",
                              whiteSpace: "nowrap",
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              style={{
                                transformOrigin: "left center 0px",
                                flexGrow: "initial",
                                transform: "scale(1)",
                              }}
                            >
                              <div className={`send-B7pe send-Ty3v`}>
                                <div className="send-EolD send-JdkE">
                                  {/* <input
                                    id="input"
                                    className="send-gw_6 input-transparent"
                                    autoComplete="off"
                                    inputMode="decimal"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                    ref={textAreaRef}
                                    autoFocus
                                    style={{ opacity: "0" }}
                                  /> */}
                                  <input
                                    inputMode="decimal"
                                    onKeyDown={onKeyDown}
                                    onChange={onChange}
                                    value={formatValue(val)}
                                    className={`send-gw_6 input-transparent ${
                                      error && `send-NCgc D3GE`
                                    }`}
                                  />
                                  <div
                                    className={`send-_R84 ${
                                      error && `send-NCgc D3GE`
                                    }`}
                                  >
                                    {/* {!inputValue
                                      ? 0
                                      : numberWithCommas(inputValue)} */}
                                    {!val && 0}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="send-TPHb send-K3Zv send-XGbG">
                                  TON
                                </div> */}
                    {error && (
                      <div className="send-xqwU send-RdIr">
                        <div className="send-NCgc">Not enough coins.</div>
                      </div>
                    )}
                  </div>
                </form>
                <div className="send-Z5dC">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="send-Bcb3">
                      <div className="send-RkvK">
                        <img src={coinImage} />
                      </div>
                    </div>
                    <div className="send-YrPC">
                      <div className="send-jOCs send-TYgZ send-If_N send-NXXw">
                        <div className="send-cpHh send-IqPa send-CF5m send-Ka5f send-kzP3 send-Vysp">
                          {addCommas(score + balance)}
                        </div>
                        <div className="send-cpHh send-UBSx send-Fx5C send-Bgj6">
                          From Balance
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </>
  );
}
