import React from "react";
import Lottie from "lottie-react";
import Cup from "../../assets/animation/Cup.json";
import Duck_x3_out from "../../assets/animation/Duck_x3_out.json";
import {
  BrowserRouter as Router,
  Route,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Swiper, SwiperSlide } from "swiper/react";
import "./style.scss";
import { BackButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import UsersSkeleton from "../../components/UsersSkeleton";
import ProgressBar from "../../components/ProgressBar";
import addCommas from "../../utils/addCommas";
import PlayersList from "../../components/PlayersList";
import getLeague from "../../utils/getLeague";
import getScoreLeague from "../../utils/getScoreLeague";
import getColor from "../../utils/getColor";
import LeaguePhoto from "../../components/LeaguePhoto";
import getSquadLeague from "../../utils/getSquadLeague";

function Search() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const assetLeague = params.get("assetLeague");
  const type = params.get("type");
  return { assetLeague, type };
}

export default function League({ userId, score, mainSkin }) {
  const navigate = useNavigate();

  const result = Search();

  const [showStats, setShowStats] = useState(false);

  const [update, setUpdate] = useState();

  let [searchParams, setSearchParams] = useSearchParams();
  let [searchLeague, setSearchLeague] = useState(searchParams.assetLeague);
  let [searchType, setSearchType] = useState(searchParams.type);

  useEffect(() => {
    const l = searchParams.get("assetLeague");
    if (l && l != searchLeague) {
      setSearchLeague(searchParams.get("assetLeague"));
    }
    const t = searchParams.get("type");
    if (t && t != searchType) {
      setSearchType(searchParams.get("type"));
    }
  }, [searchParams]);

  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  const handleButtonClick = () => {
    setPlayAnimation(true);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  const league = getLeague(score);

  const [totalPlayers, setTotalPlayers] = useState(0);

  const fetchTotalPlayers = async () => {
    try {
      const response = await axios.get(
        "https://pixelcoin.site:433/total_players"
      );
      setTotalPlayers(response.data.total_players);
      setShowStats(true);
    } catch (error) {
      console.error("Error fetching total players:", error);
    }
  };

  useEffect(() => {
    fetchTotalPlayers();
  }, []);

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);
  const [loading, setLoading] = useState(true);

  const [leagueArray, setLeagueArray] = useState([]);

  useEffect(() => {
    setUpdate(false);
    setTimeout(() => {
      setUpdate(true);
    }, 2);
  }, [searchLeague]);

  useEffect(() => {
    if (!searchLeague || !searchType || searchLeague === "bronze") {
      return setLeagueArray([]);
    }
    setLoading(true);
    const fetchData = async () => {
      try {
        axios
          .get(
            `https://pixelcoin.site:433/${searchType}_league_${searchLeague}`
          )
          .then((response) => {
            setLeagueArray(response.data);
            setLoading(false);
          });
      } catch (error) {
        console.error("Error fetching league silver:", error);
      }
    };
    fetchData();
  }, [searchLeague, searchType]);

  const [squad, setSquad] = useState([]);

  const fetchSquad = async () => {
    try {
      const response1 = await axios.get(
        `https://pixelcoin.site:433/users/${userId}/squad`
      );
      const squadId = response1.data.squad;

      if (squadId !== "") {
        axios
          .get(`https://pixelcoin.site:433/squads/${squadId}`)
          .then((response2) => {
            setSquad(response2.data);
          })
          .catch((error) => {
            console.error("Error fetching squad:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching squad:", error);
    }
  };

  useEffect(() => {
    fetchSquad();
  }, []);

  return (
    <>
      <BackButton onClick={() => navigate("/")} />
      <div className={`page scrolling`}>
        <div
          className={`background-league theme-league__${searchLeague}`}
        ></div>
        <div className="container position-relative">
          <div className="page__content uY4S">
            {showStats ? (
              <CSSTransition
                in={showStats}
                timeout={50}
                className="earn-animation"
                unmountOnExit
              >
                <div>
                  <div onClick={() => navigate("/stats")} className="w4i3">
                    <div>
                      <div className="jYed IYe4"></div>
                      <div className="Nzze"></div>
                      <div className="jYed Kebc"></div>
                      <div className="nEhw"></div>
                      <div className="jYed IYUe"></div>
                      <div className="Fp2m"></div>
                      <div className="J4ec">
                        {(totalPlayers * 7) % 2 === 0
                          ? addCommas(
                              parseInt(
                                ((totalPlayers * 13 + 8) * 7 -
                                  (totalPlayers * 5 - 12) +
                                  14) /
                                  3
                              )
                            )
                          : addCommas(
                              parseInt(
                                ((totalPlayers * 13 + 8) * 7 -
                                  (totalPlayers * 5 - 12) +
                                  15) /
                                  3
                              )
                            )}{" "}
                        {parsedData.league.pixelcoiners}
                      </div>
                    </div>
                    <div>
                      <div className="JYcc">{parsedData.league.stats}</div>
                      <div className="Yevc">
                        <svg
                          width="7"
                          height="12"
                          viewBox="0 0 7 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="KYci"
                        >
                          <path
                            d="m1 1 5 5-5 5"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            ) : (
              <div
                className="w4i3"
                style={{
                  background: "transparent",
                }}
              ></div>
            )}
            <div>
              <div className="swiper">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="_QtZ">
                      <div>
                        <div
                          className={`league-position league-icon__${searchLeague} ${
                            update && `slide-down-enter`
                          }`}
                        ></div>
                      </div>
                      <button
                        onClick={() => {
                          searchLeague !== "bronze" &&
                            navigate(
                              `/league?assetLeague=${
                                (searchLeague === "silver" && "bronze") ||
                                (searchLeague === "gold" && "silver") ||
                                (searchLeague === "platinum" && "gold") ||
                                (searchLeague === "diamond" && "platinum")
                              }&type=${searchType}`
                            );
                        }}
                        className={`nav-btn ${
                          (searchLeague === "bronze" &&
                            "nav-btn_prev-disable") ||
                          "nav-btn_prev"
                        }`}
                      >
                        <svg
                          height="16px"
                          width="16px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 492.004 492.004"
                          className={
                            (searchLeague === "bronze" && "I3cl") || "tVxl"
                          }
                        >
                          <g>
                            <g>
                              <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" />
                            </g>
                          </g>
                        </svg>
                      </button>
                      <button
                        onClick={() => {
                          searchLeague !== "diamond" &&
                            navigate(
                              `/league?assetLeague=${
                                (searchLeague === "bronze" && "silver") ||
                                (searchLeague === "silver" && "gold") ||
                                (searchLeague === "gold" && "platinum") ||
                                (searchLeague === "platinum" && "diamond")
                              }&type=${searchType}`
                            );
                        }}
                        className={`nav-btn ${
                          (searchLeague === "diamond" &&
                            "nav-btn_next-disable") ||
                          "nav-btn_next"
                        }`}
                      >
                        <svg
                          height="16px"
                          width="16px"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 492.004 492.004"
                          className={
                            (searchLeague === "diamond" && "I3cl") || "tVxl"
                          }
                        >
                          <g>
                            <g>
                              <path d="M382.678,226.804L163.73,7.86C158.666,2.792,151.906,0,144.698,0s-13.968,2.792-19.032,7.86l-16.124,16.12    c-10.492,10.504-10.492,27.576,0,38.064L293.398,245.9l-184.06,184.06c-5.064,5.068-7.86,11.824-7.86,19.028    c0,7.212,2.796,13.968,7.86,19.04l16.124,16.116c5.068,5.068,11.824,7.86,19.032,7.86s13.968-2.792,19.032-7.86L382.678,265    c5.076-5.084,7.864-11.872,7.848-19.088C390.542,238.668,387.754,231.884,382.678,226.804z" />
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="P8Ey">
                <div className={`og0c ${update && `league-opacity`}`}>
                  {(searchLeague === "bronze" &&
                    parsedData.league.bronze_league) ||
                    (searchLeague === "silver" &&
                      parsedData.league.silver_league) ||
                    (searchLeague === "gold" &&
                      parsedData.league.gold_league) ||
                    (searchLeague === "platinum" &&
                      parsedData.league.platinum_league) ||
                    (searchLeague === "diamond" &&
                      parsedData.league.diamond_league)}
                </div>
              </div>
            </div>
            <div className="Jh2w bgqq">
              <div className="Zo7S">
                {(searchType == "miners" &&
                  searchLeague === "bronze" &&
                  league === "bronze" &&
                  `${addCommas(score)} / 10K`) ||
                  (searchType == "miners" &&
                    searchLeague === "silver" &&
                    league === "silver" &&
                    `${addCommas(score)} / 200K`) ||
                  (searchType == "miners" &&
                    searchLeague === "gold" &&
                    league === "gold" &&
                    `${addCommas(score)} / 2M`) ||
                  (searchType == "miners" &&
                    searchLeague === "platinum" &&
                    league === "platinum" &&
                    `${addCommas(score)} / 10M`) ||
                  (searchType == "miners" &&
                    searchLeague === "diamond" &&
                    league === "diamond" &&
                    `${addCommas(score)} / ∞`) ||
                  (searchType == "squads" &&
                    searchLeague === "silver" &&
                    getSquadLeague(squad.score) === "silver" &&
                    `${addCommas(squad.score)} / 100M`) ||
                  (searchType == "squads" &&
                    searchLeague === "gold" &&
                    getSquadLeague(squad.score) === "gold" &&
                    `${addCommas(squad.score)} / 900M`) ||
                  (searchType == "squads" &&
                    searchLeague === "platinum" &&
                    getSquadLeague(squad.score) === "platinum" &&
                    `${addCommas(squad.score)} / 5B`) ||
                  (searchType == "squads" &&
                    searchLeague === "diamond" &&
                    getSquadLeague(squad.score) === "diamond" &&
                    `${addCommas(squad.score)} / ∞`) ||
                  (searchType == "miners" &&
                    searchLeague === "bronze" &&
                    parsedData.league.bronze_miners) ||
                  (searchType === "miners" &&
                    searchLeague === "silver" &&
                    parsedData.league.silver_miners) ||
                  (searchType === "miners" &&
                    searchLeague === "gold" &&
                    parsedData.league.gold_miners) ||
                  (searchType === "miners" &&
                    searchLeague === "platinum" &&
                    parsedData.league.platinum_miners) ||
                  (searchType === "miners" &&
                    searchLeague === "diamond" &&
                    parsedData.league.diamond_miners) ||
                  (searchType === "squads" &&
                    searchLeague === "bronze" &&
                    parsedData.league.bronze_squads) ||
                  (searchType === "squads" &&
                    searchLeague === "silver" &&
                    parsedData.league.silver_squads) ||
                  (searchType === "squads" &&
                    searchLeague === "gold" &&
                    parsedData.league.gold_squads) ||
                  (searchType === "squads" &&
                    searchLeague === "platinum" &&
                    parsedData.league.platinum_squads) ||
                  (searchType === "squads" &&
                    searchLeague === "diamond" &&
                    parsedData.league.diamond_squads)}
              </div>
            </div>
            {(searchLeague === "bronze" &&
              league === "bronze" &&
              searchType === "miners") ||
            (searchLeague === "silver" &&
              league === "silver" &&
              searchType === "miners") ||
            (searchLeague === "gold" &&
              league === "gold" &&
              searchType === "miners") ||
            (searchLeague === "platinum" &&
              league === "platinum" &&
              searchType === "miners") ||
            (searchLeague === "diamond" &&
              league === "diamond" &&
              searchType === "miners") ? (
              <div className="Ug3o">
                <ProgressBar
                  score={update ? score : 0}
                  mainSkin={mainSkin}
                  type={"miners"}
                />
              </div>
            ) : (searchLeague === "silver" &&
                getSquadLeague(squad.score) === "silver" &&
                searchType === "squads") ||
              (searchLeague === "gold" &&
                getSquadLeague(squad.score) === "gold" &&
                searchType === "squads") ||
              (searchLeague === "platinum" &&
                getSquadLeague(squad.score) === "platinum" &&
                searchType === "squads") ||
              (searchLeague === "diamond" &&
                getSquadLeague(squad.score) === "diamond" &&
                searchType === "squads") ? (
              <div className="Ug3o">
                <ProgressBar
                  score={update ? squad.score : 0}
                  mainSkin={mainSkin}
                  type={"squads"}
                />
              </div>
            ) : (
              <div className="Iued"></div>
            )}

            <div className="LibrarySegmentedControl LibrarySegmentedControl--state-fixed LibrarySegmentedControl--glider">
              <div
                onClick={() => {
                  navigate(`/league?assetLeague=${searchLeague}&type=miners`);
                }}
                className={`LibrarySegmentedControlItem__link ${
                  (searchType === "miners" &&
                    "LibrarySegmentedControlItem__link--active") ||
                  ""
                }`}
              >
                <div
                  className={`LibrarySegmentedControlItem ${
                    (searchType === "miners" &&
                      "LibrarySegmentedControlItem--active") ||
                    ""
                  }`}
                >
                  <div className="Typography LibrarySegmentedControlItem__inner LibraryLabel LibraryLabel--l-1 LibraryLabel--w-semi-bold">
                    {parsedData.league.miners}
                  </div>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate(`/league?assetLeague=${searchLeague}&type=squads`);
                }}
                className={`LibrarySegmentedControlItem__link ${
                  (searchType === "squads" &&
                    "LibrarySegmentedControlItem__link--active") ||
                  ""
                }`}
              >
                <div
                  className={`LibrarySegmentedControlItem ${
                    (searchType === "squads" &&
                      "LibrarySegmentedControlItem--active") ||
                    ""
                  }`}
                >
                  <div className="Typography LibrarySegmentedControlItem__inner LibraryLabel LibraryLabel--l-1 LibraryLabel--w-semi-bold">
                    {parsedData.league.squads}
                  </div>
                </div>
              </div>
              <div
                className="LibrarySegmentedControl__glider"
                style={{
                  width: "calc(50% - 6px)",
                  transform:
                    (searchType === "miners" && "translateX(calc(0% + 0px))") ||
                    (searchType === "squads" && "translateX(calc(100% + 4px))"),
                }}
              ></div>
            </div>
            {loading && result.assetLeague !== "bronze" ? (
              <UsersSkeleton />
            ) : leagueArray.length > 0 && result.assetLeague !== "bronze" ? (
              <ul className="league_skeleton">
                {leagueArray.map((user, index) => (
                  <li
                    onClick={() =>
                      searchType === "squads" &&
                      navigate(`/squad?asset=${user.user_id}`)
                    }
                    key={index}
                    className="league_skeleton__wrapper"
                  >
                    <LeaguePhoto
                      url_photo={user.url_photo}
                      first_name={user.first_name}
                      searchType={searchType}
                    />

                    <div className="league_element__box1">
                      {searchType === "squads" ? user.name : user.first_name}
                    </div>
                    <div className="league_element__box2">
                      <div
                        className="penny-icon vBwQ"
                        style={{
                          position: "absolute",
                          display: "inline-block",
                          marginTop: "4px",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "inline",
                          color: "#d4d4d4",
                          marginLeft: "20px",
                        }}
                      >
                        {addCommas(user.score)}
                      </div>
                    </div>
                    {index === 0 ? (
                      <img
                        src={require("../../assets/images/1st-place-medal.png")}
                        className="league_element__box3"
                      />
                    ) : index === 1 ? (
                      <img
                        src={require("../../assets/images/2nd-place-medal.png")}
                        className="league_element__box3"
                      />
                    ) : index === 2 ? (
                      <img
                        src={require("../../assets/images/3rd-place-medal.png")}
                        className="league_element__box3"
                      />
                    ) : (
                      <div className="league_element__box3">{index + 1}</div>
                    )}
                    {searchType === "squads" && (
                      <div className="league_element__box4">
                        <div
                          style={{
                            marginTop: "2px",
                            color: "rgb(112, 117, 121)",
                          }}
                        >
                          <svg
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="m1 1 5 5-5 5"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    )}
                    {user.user_id === userId && searchType === "miners" && (
                      <div className="league_element__box4">
                        <div
                          style={{
                            marginTop: "2px",
                            color: "rgb(112, 117, 121)",
                            marginLeft: "-16px",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >
                          {parsedData.league.you}
                        </div>
                      </div>
                    )}
                    {user.user_id === squad.user_id &&
                      searchType === "squads" && (
                        <div className="league_element__box4">
                          <div
                            style={{
                              marginTop: "1px",
                              color: "rgb(112, 117, 121)",
                              marginLeft: "-56px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            {parsedData.league.your}
                          </div>
                        </div>
                      )}
                  </li>
                ))}
              </ul>
            ) : result.assetLeague === "bronze" ? (
              <div>
                <div className="wDXF">
                  <img
                    src={require("../../assets/images/trophy.png")}
                    className="UQdA"
                  />
                </div>
                <div className="NHDE">
                  {(searchType === "miners" && (
                    <div className="lGlN">
                      {parsedData.league.you_will_join_leaderboard}
                      <br />
                      {parsedData.league.in_the_silver_league}
                    </div>
                  )) ||
                    (searchType === "squads" && (
                      <div className="lGlN">
                        {parsedData.league.leaderboard_is_under_construction}
                      </div>
                    ))}
                </div>
              </div>
            ) : (
              <div className="UIx1">
                <div className="lgqO">
                  <div onClick={handleButtonClick} className="JKpY Z5dC">
                    <Lottie
                      key={animationKey}
                      loop={false}
                      autoPlay={playAnimation}
                      animationData={Duck_x3_out}
                    />
                  </div>
                  <div className="OCW2">{parsedData.league.empty_list}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
