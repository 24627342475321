import "./style.scss";
import Lottie from "lottie-react";
import axios from "axios";
import Influencer from "../../assets/animation/Influencer.json";
import { BrowserRouter as Router, Route, useNavigate } from "react-router-dom";
import BlackTransparent from "../../components/BlackTransparent";
import { BackButton, MainButton } from "@twa-dev/sdk/react";
import WebApp from "@twa-dev/sdk";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
// import { gsap } from 'gsap';
import addCommas from "../../utils/addCommas";
import getLeague from "../../utils/getLeague";
import Pigeon from "../../assets/animation/Pigeon.json";
import abbreNumbers from "../../utils/abbreNumbers";
import getScoreFrensList from "../../utils/getScoreFrensList";
import getColor from "../../utils/getColor";
import UserPhoto from "../../components/UserPhoto";
import Counter from "../../components/Counter";

export default function Fren({ userId, squad }) {
  const navigate = useNavigate();

  const [showPage, setShowPage] = useState(false);

  const [frensBalance, setFrensBalance] = useState(0);

  useEffect(() => {
    const fetchFrensBalance = async () => {
      try {
        const response = await axios.get(
          `https://pixelcoin.site:433/users/${userId}/frens_balance`
        );
        setFrensBalance(response.data.frens_balance);
      } catch (error) {
        console.error("Failed to fetch frens balance:", error);
      }
    };

    fetchFrensBalance();

    // Очистка эффекта
    return () => {
      // Если нужно выполнить какие-то действия при размонтировании компонента
    };
  }, []);

  const [playAnimation, setPlayAnimation] = useState(false);
  const [animationKey, setAnimationKey] = useState(0);

  const handleButtonClick = () => {
    setPlayAnimation(true);
    setAnimationKey((prevKey) => prevKey + 1);
  };

  // setTimeout(() => {
  //   setShowPage(true);
  // }, 2000); // Задержка перед появлением страницы

  const savedData = sessionStorage.getItem("pixelcoinLangpack");
  const parsedData = JSON.parse(savedData);

  const [starFirst, setStarFirst] = useState(false);

  setTimeout(() => {
    setStarFirst(true);
  }, 1000);

  const [starSecond, setStarSecond] = useState(false);

  setTimeout(() => {
    setStarSecond(true);
  }, 800);

  const [starThird, setStarThird] = useState(false);

  setTimeout(() => {
    setStarThird(true);
  }, 100);

  const [starFourth, setStarFourth] = useState(false);

  setTimeout(() => {
    setStarFourth(true);
  }, 400);

  const [frens, setFrens] = useState([]);

  useEffect(() => {
    const fetchDataFrens = async () => {
      try {
        const response = await axios.get(
          `https://pixelcoin.site:433/users/${userId}/frens_list`
        );
        setFrens(response.data);
        setShowPage(true);
      } catch (error) {
        console.error("Error fetching frens:", error);
      }
    };

    fetchDataFrens();
  }, []);

  const checkTimeDifference = (targetTime) => {
    const targetTimestamp = new Date(targetTime).getTime();
    const currentTimestamp = new Date().getTime();
    const differenceInSeconds = (currentTimestamp - targetTimestamp) / 1000;
    return differenceInSeconds < 300; // 300 секунд = 5 минут
  };

  return (
    <>
      <BackButton onClick={() => navigate("/")} />
      <div className="page fren-page">
        <div className="background-yellow theme-yellow yhE4"></div>
        <div
          style={{
            position: "relative",
          }}
        >
          <CSSTransition
            in={starFirst}
            timeout={800}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_fren-1 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starSecond}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_fren-2 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starThird}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_fren-3 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
          <CSSTransition
            in={starFourth}
            timeout={1200}
            className="star-animation"
            unmountOnExit
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="11"
                viewBox="0 0 10 11"
                fill="none"
                className="stars_fren-4 star-glitter"
              >
                <path
                  d="M4.49419 0.881589C4.67056 0.383777 5.37562 0.386747 5.54778 0.886029L6.538 3.75766C6.59105 3.91149 6.70859 4.03451 6.85985 4.09449L9.65098 5.20141C10.1229 5.38856 10.1201 6.05747 9.6466 6.24063L6.84623 7.32399C6.69448 7.3827 6.5759 7.50472 6.52156 7.65809L5.50717 10.5213C5.3308 11.0191 4.62575 11.0161 4.45358 10.5168L3.46336 7.6452C3.41031 7.49137 3.29277 7.36836 3.14152 7.30837L0.350385 6.20145C-0.121519 6.0143 -0.1187 5.3454 0.354764 5.16223L3.15513 4.07888C3.30688 4.02017 3.42546 3.89815 3.4798 3.74477L4.49419 0.881589Z"
                  fill="white"
                />
              </svg>
            </div>
          </CSSTransition>
        </div>
        <div className={`container position-relative scrolling`}>
          <div className="page__content uY4S">
            <CSSTransition
              in={showPage}
              timeout={250}
              className="fren-animation"
              unmountOnExit
            >
              <div>
                <div className="Jh2w I7pd">
                  <div className="HeOi">
                    {frens.length > 0
                      ? `${frens.length} ${parsedData.fren.title_short}`
                      : parsedData.fren.title}
                  </div>
                </div>
                {/* <div
                  onClick={() => {
                    navigate("/referral");
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="hcoi">{parsedData.fren.how_to_make}</div>
                </div> */}

                <BlackTransparent
                  onClick={() => {
                    navigate("/party");
                  }}
                  classes={[
                    "black-transparent-btn_with-icon",
                    "fren-page__header-btn",
                  ]}
                >
                  <div className="fren-page__header-btn-content">
                    <div className="Ip3o">+{abbreNumbers(frensBalance)}</div>
                    <div className="coin-min-icon"></div>
                    <div className="vertical-line"></div>
                    <div className="crown-icon"></div>
                    <div className="iUeR">{parsedData.fren.top}</div>
                    <div className="OIe4">{parsedData.fren.leaders}</div>
                  </div>
                  <div
                    style={{
                      marginRight: "6px",
                      marginTop: "2px",
                    }}
                  >
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="m1 1 5 5-5 5"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                </BlackTransparent>
                <div className="UsL6">{parsedData.fren.title_invite}</div>
                <div className="black-transparent fGjD">
                  <div className="B0WY">
                    <div className="tjl5">
                      <div className="B0WY">
                        <div className="JfMo">
                          <div className="referral-icon referral-icon__coin-small-icon"></div>
                        </div>
                        <div className="UEiU">
                          <div className="nnot">
                            {parsedData.fren.invite_fren}
                            <br />
                            <div className="lDtF nnot">
                              <div className="Li2p">
                                <div className="penny-icon vBwQ bv0r"></div>
                                <div className="R35h JG3Z bv0r">
                                  50,000
                                </div>{" "}
                                {parsedData.fren.for_you_and_fren}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="B0WY">
                        <div className="rEQ9">
                          <div className="referral-icon referral-icon__prem-icon"></div>
                        </div>
                        <div className="IY3i">
                          <div className="nnot">
                            {parsedData.fren.fren_with}{" "}
                            <a
                              onClick={() => {
                                WebApp.openTelegramLink("https://t.me/premium");
                              }}
                              className="prem-link"
                            >
                              {parsedData.fren.telegram_premium}
                            </a>
                            <br />
                            <div className="lDtF nnot">
                              <div className="Li2p">
                                <div className="penny-icon vBwQ bv0r"></div>
                                <div className="R35h JG3Z bv0r">
                                  40,000
                                </div>{" "}
                                {parsedData.fren.for_you_and_fren}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    <div
                      onClick={() => {
                        navigate("/referral");
                      }}
                      className="open-details-btn"
                    >
                      <div className="Jh2w">
                        <div className="Li2p">
                          <div className="Iy4o">
                            {parsedData.fren.open_details}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    marginTop: "50px",
                    color: "#fff",
                  }}
                >
                  {parsedData.fren.title_frens_list}
                </div>
                {frens.length > 0 ? (
                  <ul className="fren_skeleton">
                    {frens.map((user, index) => (
                      <li
                        onClick={() =>
                          user.username !== ""
                            ? WebApp.openTelegramLink(
                                `https://t.me/${user.username}`
                              )
                            : console.log("Not found player")
                        }
                        key={index}
                        className="fren_skeleton__wrapper"
                      >
                        <UserPhoto
                          url_photo={user.url_photo}
                          first_name={user.first_name}
                        />

                        {checkTimeDifference(user.last_action) && (
                          <div className="fren_skeleton__pulse">
                            <div
                              className="pulse"
                              style={{
                                marginTop: "3px",
                                marginLeft: "3px",
                              }}
                            ></div>
                          </div>
                        )}
                        <div className="fren_element__box1">
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <div>{user.first_name}</div>
                          </div>
                        </div>
                        <div className="fren_element__box2">
                          <div className="Li2p">
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <div
                                  className={`fren-cup-position icon-cup__${getLeague(
                                    user.score
                                  )}`}
                                ></div>
                                <div
                                  style={{
                                    color: "#d4d4d4",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    marginLeft: "3px",
                                    marginTop: "4px",
                                  }}
                                >{`${
                                  getLeague(user.score) === "bronze"
                                    ? parsedData.main.bronze
                                    : getLeague(user.score) === "silver"
                                    ? parsedData.main.silver
                                    : getLeague(user.score) === "gold"
                                    ? parsedData.main.gold
                                    : getLeague(user.score) === "platinum"
                                    ? parsedData.main.platinum
                                    : parsedData.main.diamond
                                }`}</div>
                                <div
                                  style={{
                                    marginLeft: "6px",
                                    marginRight: "6px",
                                    marginTop: "4px",
                                  }}
                                >
                                  ·
                                </div>
                                <div
                                  className="penny-icon vBwQ"
                                  style={{
                                    marginTop: "8px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    color: "#fff",
                                    marginTop: "4px",
                                    marginLeft: "4px",
                                  }}
                                >
                                  {addCommas(user.score)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="fren_element__box4">
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "right",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "600",
                                color: "var(--pixelcoin-orange)",
                                marginTop: "1px",
                              }}
                            >
                              +
                              {abbreNumbers(
                                user.league_bonus === 1
                                  ? 50000
                                  : user.league_bonus === 2
                                  ? 150000
                                  : user.league_bonus === 3
                                  ? 350000
                                  : user.league_bonus === 4
                                  ? 650000
                                  : user.league_bonus === 5
                                  ? 1150000
                                  : 0
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="fren_element__box5">
                          <div
                            style={{
                              marginTop: "2px",
                              color: "rgb(112, 117, 121)",
                            }}
                          >
                            <svg
                              width="7"
                              height="12"
                              viewBox="0 0 7 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="m1 1 5 5-5 5"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="Sns9">
                    <div className="lgqO">
                      <div>
                        <img
                          src={require("../../assets/images/teddy-bear.png")}
                          className="Ihcq"
                        />
                      </div>
                      <div className="OCW2">
                        {parsedData.fren.empty_frens_list}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  onClick={() => {
                    squad.length !== 0
                      ? WebApp.openTelegramLink(
                          `https://t.me/share/url?text=${
                            parsedData.referral_system.play_and_earn
                          }${`50,000`}${
                            parsedData.referral_system.for_you_and_fren
                          }&url=https://t.me/pixelcoinbot?start=r_${
                            squad.user_id
                          }_${userId}`
                        )
                      : WebApp.openTelegramLink(
                          `https://t.me/share/url?text=${
                            parsedData.referral_system.play_and_earn
                          }${`50,000`}${
                            parsedData.referral_system.for_you_and_fren
                          }&url=https://t.me/pixelcoinbot?start=rp_${userId}`
                        );
                  }}
                  className="fren-footer-btn"
                >
                  <div className="Jh2w">
                    <div className="Li2p">
                      <div className="Iy4o">
                        {parsedData.fren.invite_a_fren}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </>
  );
}
