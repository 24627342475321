import getRocket from "./getRocket";
import getMultitap from "./getMultitap";

export default function changeClickSession(
  score,
  session,
  rocket,
  rocketNum,
  skins,
  multitap,
) {
  if (rocket) {
    session =  parseInt(session +  2 * getMultitap(multitap) * (getRocket(rocketNum) - 1));
  } else {
    session =  parseInt(session + getMultitap(multitap) * (getRocket(rocketNum) - 1));
  }
  return session;
}
