import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import Lottie from "lottie-react";
import Crystal from "../../assets/animation/Crystal.json";
import "./style.scss";

export default function Loader() {
  return (
    // <div className="_cooldownContainer_2szzu_82">
    //   <svg className="_cooldown_2szzu_77">
    //     <circle
    //       className="_cooldownCircle_2szzu_101"
    //       r="140"
    //       cx="150"
    //       cy="150"
    //     />
    //   </svg>
    //   <div className="_cooldownNumber_2szzu_89">not<br/>nothing</div>
    // </div>

    <div className="page">
      <div className="container position-relative">
        <div className="page__content">

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "85vh",
            }}
          >
            <div className="loader">
              <Lottie animationData={Crystal} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
