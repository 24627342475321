export default function getEnergyLimit(energy_limit) {
    if (energy_limit === 0) {
        return 0
    } else if (energy_limit === 1) {
        return 2
    } else if (energy_limit === 2) {
        return 4
    } else if (energy_limit === 3) {
        return 6
    } else if (energy_limit === 4) {
        return 8
    } else if (energy_limit === 5) {
        return 10
    } else if (energy_limit === 6) {
        return 12
    } else if (energy_limit === 7) {
        return 14
    } else if (energy_limit === 8) {
        return 16
    } else if (energy_limit === 9) {
        return 18
    } else if (energy_limit === 10) {
        return 20
    } else if (energy_limit === 11) {
        return 22
    } else if (energy_limit === 12) {
        return 24
    } else if (energy_limit === 13) {
        return 26
    } else if (energy_limit === 14) {
        return 28
    } else if (energy_limit === 15) {
        return 30
    } else if (energy_limit === 16) {
        return 32
    } else if (energy_limit === 17) {
        return 34
    } else if (energy_limit === 18) {
        return 36
    } else if (energy_limit === 19) {
        return 38
    } else if (energy_limit === 20) {
        return 40
    } else {
        return 42
    }
}